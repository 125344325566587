import stylesConfig from "theme/config";

const styles = {
  table: {
    tableContainer: {
      backgroundColor: stylesConfig.colors.background,
    },
  },
};

export default styles;
