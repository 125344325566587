import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import NewPasswordForm from "components/forms/auth/NewPassword/NewPasswordForm";
import LoginLayout from "components/layouts/login-layout/LoginLayout";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import qs from "qs";
import registrationService from "services/registration/registration-service";
import styles from "./CompleteRegistration.style";

export const CompleteRegistrationPage = () => {
  const [token, setToken] = useState("false");
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    async function validateAndSetToken() {
      // Redirect to invalid token page if no token in query params.
      const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (!qsToken.token) {
        history.push("/invalid-registration-token");
        // we return a clean up function to stop further state changes
        return () => {};
      }

      // Redirect to invalid token page if the token is not valid
      const { valid } = await registrationService.validateToken(qsToken.token);

      if (!valid) {
        history.push("/invalid-registration-token");
        return () => {};
      }

      // All appears OK - Set the token!
      setToken(qsToken.token);
    }

    validateAndSetToken();
  }, [setToken, location.search, history]);

  const setPasswordHandler = async (password) => {
    let regResponse;
    try {
      regResponse = await registrationService.completeRegistration(
        token,
        password
      );
    } catch (registrationError) {
      return registrationError;
    }

    // Password setting successful, redirect to registration completed.
    return history.push(
      `/registration-completed?signInLink=${regResponse.signInLink}`
    );
  };

  return (
    <LoginLayout
      content={
        <Box style={styles.container}>
          <HeadingSmall text="Create Password" sx={styles.heading} />
          <Paragraph>
            To create your password please enter and confirm the new one below.
          </Paragraph>
          <Paragraph>It will need:</Paragraph>
          <Paragraph>
            <li>At least 8 characters</li>
            <li>One capital letter</li>
            <li>One special character </li>
            <li>One number</li>
          </Paragraph>
          <NewPasswordForm
            token={token}
            onComplete={setPasswordHandler}
            submitButtonText={"Complete Registration"}
          />
        </Box>
      }
    />
  );
};
