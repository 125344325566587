import spacing from "theme/styles/spacing/spacing";
import colors from "../colors/colors";
import typography from "../typography/typography";
const button = {
  borderRadius: "4px",
  textAlign: "center",
  letterSpacing: "0px",
  height: "44px",
  minWidth: "161px",
  cursor: "pointer",
  paddingLeft: spacing.standard,
  paddingRight: spacing.standard,
  textTransform: "unset",
  font: typography.p.font,
  fontWeight: "bold",
  border: "1px solid transparent",
};

const font = {
  standard: {
    ...typography.buttons.standard,
  },
  "standard-bold": {
    ...typography.buttons["standard-bold"],
  },
};

const buttons = {
  default: button,
  size: font,
  primary: {
    ...button,
    background: colors.primary,
    border: `1px solid ${colors.primary}`,
    color: colors.white,
    "&:hover": {
      background: colors.white,
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
    },
  },
  secondary: {
    ...button,
    background: colors.secondary,
    border: `1px solid ${colors.secondary}`,
    color: colors.white,
    "&:hover": {
      background: colors.secondary,
      border: `1px solid ${colors.secondary}`,
    },
  },
  "secondary-inverse": {
    ...button,
    background: colors.white,
    border: `1px solid ${colors.secondary}`,
    color: colors.secondary,
    "&:hover": {
      background: colors.white,
      border: `1px solid ${colors.secondary}`,
    },
  },
  naked: {
    color: colors.primary,
    background: 0,
    border: 0,
    minWidth: "unset",
    "&:hover": {
      background: 0,
      border: 0,
    },
  },
  outline: {
    color: colors.primary,
    background: 0,
    border: `1px solid ${colors.primary}`,
    minWidth: "unset",
    "&:hover": {
      background: colors.primary,
      color: colors.white,
      border: 0,
    },
  },
  close: {},
  disabled: {
    cursor: "not-allowed",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "not-allowed",
  },
};

export default buttons;
