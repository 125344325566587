import React from "react";
import styles from "./ContentsTable.styles";
import DataTable from "components/data-display/Table/Table";

const ContentsTable = ({
  page,
  total,
  rowsPerPage,
  data,
  noResultsMessage,
  tableFormat,
  getDataHandler,
  optionActions,
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <>
      <>
        <DataTable
          sx={styles.table}
          data={data}
          page={page}
          total={total}
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          rowsPerPage={rowsPerPage}
          getDataHandler={getDataHandler}
          noDataComponent={noResultsMessage || "There are currently no content"}
          tableFormat={tableFormat}
          optionActions={optionActions}
        />
      </>
    </>
  );
};

export default ContentsTable;
