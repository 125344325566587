import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import CloseIcon from "@mui/icons-material/Close";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import DeleteAppointmentForm from "components/forms/appointment/delete-an-appointment/DeleteAppointment";
import styles from "../Options.style";

const AppointmentsOptions = ({ data, onComplete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <PrimaryButton
          style={styles.deleteButtonStyle}
          text="Remove"
          onClick={() => setShowDeleteModal(true)}
          cy="delete-user-button"
        />
        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <Box sx={styles.modal} data-cy="modal-content">
            <>
              <Box sx={styles.header}>
                <HeadingBold text={"Attention!"} />
                <CloseIcon
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}
                  style={styles.closeButton}
                />
              </Box>
              <div style={styles.divider} />
            </>
            <Box style={styles.margin}>
              <DeleteAppointmentForm
                closeModal={() => setShowDeleteModal(false)}
                onComplete={onComplete}
                id={data.id}
              />
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default AppointmentsOptions;
