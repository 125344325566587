import React from "react";
import PropTypes from "prop-types";
import errorHandler from "./DeleteAppointment.error.handler";
import appointmentsService from "services/appointments/appointments-service";
import GenericDeleteForm from "components/forms/generic/delete/Delete";
import Paragraph from "components/core/typography/paragraph/Paragraph";

const DeleteAppointment = ({ id, closeModal, onComplete, onCancel }) => {
  return (
    <GenericDeleteForm
      id={id}
      closeModal={closeModal}
      onComplete={onComplete}
      service={() => appointmentsService.deleteAppointmentById(id)}
      errorHandler={errorHandler}
      primaryActionText="Yes, remove it"
      cancelActionText="No, keep it"
      description={
        <Paragraph>
          Are you sure you wish to remove this appointment from your list? This
          will not cancel the actual appointment.
        </Paragraph>
      }
    />
  );
};
DeleteAppointment.defaultProps = {
  onClick: () => {},
  onComplete: () => {},
  onCancel: () => {},
  id: undefined,
};
DeleteAppointment.propTypes = {
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
};
export default DeleteAppointment;
