import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import StaffTable from "components/staff/staffTable/StaffTable";
import AddStaffForm from "components/forms/staff/add-a-staff-member/AddStaff";
import Loader from "components/core/loaders/circular/Circular";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import userService from "services/user/user-service";
import StaffOptions from "components/data-display/Table/Options/StaffOptions/StaffOptions";

import styles from "./staff.styles";

const tableFormat = [
  { id: "firstName", label: "First Name", allowSort: true },
  { id: "lastName", label: "Last Name", allowSort: true },
  { id: "email", label: "Email", allowSort: false },
  { id: "role", label: "Role", allowSort: false },
  { id: "inviteStatus", label: "Invite Status", allowSort: false },
  { id: "action", label: "", allowSort: false },
];

const ROWS_PER_PAGE = 10;

const StaffManagement = () => {
  const [staff, updateStaff] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [isActve, updateIsActive] = useState(true);
  const [isDisabled, updateIsDisabled] = useState(false);
  const [isModalClosed, updateCloseModal] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState(
    "There are currently no staff."
  );
  // Pagination state
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  // Sorting
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");

  const closeModal = () => updateCloseModal(true);

  const handleFilterButtonSelection = async () => {
    updateIsActive(isDisabled);
    updateIsDisabled(isActve);

    const newOrder = "asc";
    const newOrderBy = "firstName";

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);

    getStaff(0, newOrder, newOrderBy, !isActve);
  };

  const getStaff = useCallback(
    async (
      nextPage,
      sOrder = order,
      sOrderBy = orderBy,
      sIsActve = isActve
    ) => {
      const pageToSearch = nextPage || 0;

      const response = await userService.getUsers(
        pageToSearch,
        rowsPerPage,
        null,
        sOrder,
        sOrderBy,
        sIsActve,
        "super-admin,admin"
      );

      updateLoading(false);

      if (!response || response.message) {
        setNoResultsMessage("No Results from API");
        return;
      }

      setPage(pageToSearch);
      setTotal(response.total);
      setRowsPerPage(ROWS_PER_PAGE);

      const staffArray = response.results.map((staff) => {
        return {
          id: staff.id,
          firstName: staff.firstName,
          lastName: staff.lastName,
          fields: [
            staff.firstName,
            staff.lastName,
            staff.email,
            staff.role || "-",
            staff.inviteStatus,
            {
              Component: () => (
                <StaffOptions
                  data={staff}
                  onComplete={() => {
                    getStaff(page);
                    updateIsActive(true);
                    updateIsDisabled(false);
                  }}
                />
              ),
            },
          ],
        };
      });

      updateStaff(staffArray);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getStaff(page);
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);

    await getStaff(page, newOrder, property);
  };

  const handleAddStaff = () => {
    const newOrder = "asc";
    const newOrderBy = "firstName";

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    getStaff(0, newOrder, newOrderBy);
  };

  let loadingStyles = {};
  if (loading) {
    loadingStyles = styles.loading;
  }

  let isFilterActiveSelectedStyles = {};
  if (isActve) {
    isFilterActiveSelectedStyles = styles.bold;
  }

  let isFilterDisbledSelectedStyles = {};
  if (isDisabled) {
    isFilterDisbledSelectedStyles = styles.bold;
  }

  return (
    <>
      <LoggedIn
        smallHeading="Staff Management"
        buttonModal={{
          title: "Add Member",
          modalContent: (
            <AddStaffForm clodeModal={closeModal} onComplete={handleAddStaff} />
          ),
          isModalClosed: isModalClosed,
        }}
      >
        <Box sx={{ ...styles.panel, ...loadingStyles }}>
          {loading ? (
            <Loader />
          ) : (
            <Box>
              <Box sx={{ flexGrow: 1 }}>
                <Box style={styles.tableFilter}>
                  <div style={styles.filterButtonContainer}>
                    <PrimaryButton
                      sx={{
                        ...styles.filterButton,
                        ...isFilterActiveSelectedStyles,
                      }}
                      text="Active"
                      naked
                      onClick={handleFilterButtonSelection}
                    />
                    {isActve && <div style={styles.filterButtonUnderLine} />}
                  </div>
                  <div style={styles.filterButtonContainer}>
                    <PrimaryButton
                      sx={{
                        ...styles.filterButton,
                        ...isFilterDisbledSelectedStyles,
                      }}
                      text="Disabled"
                      naked
                      onClick={handleFilterButtonSelection}
                    />
                    {isDisabled && <div style={styles.filterButtonUnderLine} />}
                  </div>
                </Box>
                <StaffTable
                  key={isActve}
                  page={page}
                  total={total}
                  rowsPerPage={rowsPerPage}
                  data={staff}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  noResultsMessage={noResultsMessage}
                  tableFormat={tableFormat}
                  getDataHandler={getStaff}
                  hideOptions
                />
              </Box>
            </Box>
          )}
        </Box>
      </LoggedIn>
    </>
  );
};

export default StaffManagement;
