import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import ContentsTable from "components/sessions/SessionsTable/SessionsTable";
import Options from "components/sessions/SessionsTable/Options/TableOptions";
import Loader from "components/core/loaders/circular/Circular";
import AlertModal from "components/core/modal/alert-modal/AlertModal";
import sessionsService from "services/sessions/sessions-service";
import styles from "./Sessions.styles";

const tableFormat = [
  { id: "session", label: "Session", allowSort: false },
  { id: "sessionTitle", label: "Title", allowSort: false },
];

const MODAL_DATA = {
  edited: {
    title: "Session Edited",
    description:
      "Your session has been successfully updated. You can edit it from Sessions page.",
  },
  deleted: {
    title: "Session Deleted",
    description: "Your session has been successfully deleted.",
  },
};

const ROWS_PER_PAGE = 10;

const Sessions = () => {
  const [content, updateContent] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [isAlertOpen, updateIsAlertOpen] = useState(false);
  const [alertType, updateAlertType] = useState("edited");
  const [noResultsMessage, setNoResultsMessage] = useState(
    "There are currently no content."
  );
  // Pagination state
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  // Sorting
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("title");

  const getContent = useCallback(
    async (nextPage) => {
      const pageToSearch = nextPage || 0;
      const response = await sessionsService.getSessions(pageToSearch);

      updateLoading(false);

      if (!response || response.message) {
        setNoResultsMessage("No Results from API");
        return;
      }

      setPage(pageToSearch);
      setTotal(response.total);
      setRowsPerPage(ROWS_PER_PAGE);

      const sessionsArray = response.sessions.map((session, index) => {
        return {
          id: session.session,
          fields: [session.session, session.sessionTitle],
        };
      });

      updateContent(sessionsArray);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getContent(page);
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddContent = (alertType) => {
    const newOrder = "asc";
    const newOrderBy = "title";

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    getContent(0, newOrder, newOrderBy);
  };

  const openAlertModal = () => updateIsAlertOpen(true);

  const closeAlertModal = () => updateIsAlertOpen(!isAlertOpen);

  let loadingStyles = {};
  if (loading) {
    loadingStyles = styles.loading;
  }

  return (
    <>
      <LoggedIn smallHeading="Session Content">
        <Box sx={{ ...styles.panel, ...loadingStyles }}>
          {loading ? (
            <Loader />
          ) : (
            <Box>
              <Box sx={{ flexGrow: 1 }}>
                <ContentsTable
                  page={page}
                  total={total}
                  rowsPerPage={rowsPerPage}
                  data={content}
                  order={order}
                  orderBy={orderBy}
                  noResultsMessage={noResultsMessage}
                  tableFormat={tableFormat}
                  getDataHandler={getContent}
                  optionActions={(data) => (
                    <Options
                      data={data}
                      setAlertType={updateAlertType}
                      onComplete={() => {
                        openAlertModal();
                        handleAddContent();
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
        </Box>
        <AlertModal
          open={isAlertOpen}
          onClose={closeAlertModal}
          title={MODAL_DATA[alertType].title}
          description={MODAL_DATA[alertType].description}
          ctaText="Close"
        />
      </LoggedIn>
    </>
  );
};

export default Sessions;
