import React from "react";

const utils = {
  getUIDateString: (date) => {
    const dateAsObject = new Date(date);
    return dateAsObject.toLocaleDateString("en-GB");
  },
  uniqueString: () => Math.random().toString(36).slice(2),
  mapPropsToChilden: (children, props = {}) =>
    React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too

      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }
      return child;
    }),
};

export default utils;
