import stylesConfig from "theme/config";

const styles = {
  formFooter: {
    width: "100%",
    padding: stylesConfig.spacing.standard,
  },
  textFieldMargin: {
    marginBottom: "1rem",
  },
  footer: {
    paddingTop: stylesConfig.spacing.large,
    justifyContent: "flex-end",
    display: "flex",
  },
  gridRow: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    marginBottom: stylesConfig.spacing.small,
    paddingLeft: 2,
    paddingRight: 2,
    alignItems: "center",
  },
  closeButton: {
    marginLeft: "auto",
    color: stylesConfig.colors.primary,
    fontSize: "30px",
  },
  divider: {
    width: "100%",
    height: "2px",
    backgroundColor: "#DEE2E4",
  },
};

export default styles;
