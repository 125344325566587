import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    return reducers.createFormErrorsHandler(
      "general",
      "There was an issue when trying to save this session.",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
