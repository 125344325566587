import stylesConfig from "theme/config";

const styles = {
  button: stylesConfig.buttons.primary,
  primary: stylesConfig.buttons.primary,
  secondary: stylesConfig.buttons.secondary,
  "secondary-inverse": stylesConfig.buttons["secondary-inverse"],
  warning: {
    color: stylesConfig.colors.warning,
    "&::after": {
      content: "''",
      borderBottom: `2px solid ${stylesConfig.colors.warning}`,
      width: "calc(100% - 24px)",
      height: "2px",
      position: "absolute",
      bottom: "13px",
    },
  },
  warningLoading: {
    "&::after": {
      left: "4px",
      content: "''",
      borderBottom: `2px solid rgba(0, 0, 0, 0.26)`,
      width: "calc(100% - 33px)",
      height: "2px",
      position: "absolute",
      bottom: "13px",
    },
  },
  underline: {
    color: stylesConfig.colors.black,
    "&::after": {
      content: "''",
      borderBottom: `2px solid ${stylesConfig.colors.black}`,
      width: "calc(100% - 24px)",
      height: "2px",
      position: "absolute",
      bottom: "13px",
    },
  },
  underlineLoading: {
    color: stylesConfig.colors.black,
    "&::after": {
      content: "''",
      borderBottom: `2px solid ${stylesConfig.colors.black}`,
      width: "calc(100% - 24px)",
      height: "2px",
      position: "absolute",
      bottom: "13px",
    },
  },
  naked: stylesConfig.buttons.naked,
  outline: stylesConfig.buttons.outline,
  size: stylesConfig.buttons.size,
  loading: {
    ...stylesConfig.buttons.loading,
    paddingLeft: stylesConfig.spacing.small,
    paddingRight: stylesConfig.spacing.small,
  },
  disabled: {
    ...stylesConfig.buttons.disabled,
  },
  loader: {
    color: "inherit",
    marginLeft: stylesConfig.spacing.small,
    height: "20px !important",
    width: "20px !important",
  },
};

export default styles;
