import React, { useState, useReducer, useContext } from "react";
import { Grid, Box, Modal } from "@mui/material";
import reducers from "reducers/form-errors-reducer";
import { GlobalContext } from "context/GlobalContext";
import InputField from "components/core/form/Input/Input";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import NewPasswordForm from "components/forms/auth/NewPassword/NewPasswordForm";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import Button from "components/core/buttons/Primary/Primary";
import CloseIcon from "@mui/icons-material/Close";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import patientService from "services/patient/patient-service";
import userService from "services/user/user-service";
import HTTPError from "lib/errors/http-error";
import errorHandler from "./MyAccount.error.handler";
import styles from "./MyAccount.styles";

const intialErrorState = {
  firstName: null,
  lastName: null,
  email: null,
  general: null,
};

const MyAccountForm = ({ closeModal, onComplete, openChangePasswordModal }) => {
  const { user, updateUser } = useContext(GlobalContext);
  const [isSaving, updateIsSaving] = useState(false);
  const [isEditingPassword, updateIsEditingPassword] = useState(false);

  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const characterLimit = 246;

  const handlePasswordEditing = () => {
    updateIsEditingPassword(!isEditingPassword);
  };

  const handleEmailEditing = () => {
    closeModal();
    openChangePasswordModal();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    updateIsSaving(true);
    const payload = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
    };

    try {
      await patientService.patchPatient(user.id, payload);
      updateIsSaving(false);

      updateUser({
        ...user,
        ...payload,
      });
      await onComplete();
      closeModal();
    } catch (err) {
      updateIsSaving(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };

  const handlePasswordChange = async (password) => {
    try {
      await userService.changePassword(user.id, password);
      await onComplete();
      updateIsEditingPassword(!isEditingPassword);
      closeModal();
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);

        return err;
      } else {
        throw err;
      }
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} style={styles.formFooter}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={styles.gridRow}>
            <InputField
              id="firstName"
              cy="firstName-field"
              label="First Name"
              theme="inputWhite"
              required
              sx={styles.textFieldMargin}
              placeholder="Enter patients first name"
              defaultValue={user.firstName}
              error={errorState.firstName ? true : false}
              helperText={errorState.firstName}
              inputProps={{
                inputProps: {
                  maxLength: characterLimit,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={styles.gridRow}>
            <InputField
              id="lastName"
              cy="lastName-field"
              label="Last Name"
              theme="inputWhite"
              required
              sx={styles.textFieldMargin}
              placeholder="Enter patients last name"
              defaultValue={user.lastName}
              error={errorState.lastName ? true : false}
              helperText={errorState.lastName}
              inputProps={{
                inputProps: {
                  maxLength: characterLimit,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} style={styles.gridRow}>
            <InputField
              id="email"
              cy="email-field"
              label="Email Address"
              type="email"
              sx={{ marginBottom: 0 }}
              required
              autoComplete="email"
              placeholder="Enter your email address"
              defaultValue={user.email}
              error={errorState.email ? true : false}
              helperText={errorState.email}
              disabled={true}
              inputProps={{
                inputProps: {
                  maxLength: characterLimit,
                  autoComplete: "email",
                },
              }}
            />
            <Box style={styles.actionContainer}>
              <Button
                sx={styles.actionButton}
                naked
                text="Change Email Address"
                onClick={handleEmailEditing}
              />
            </Box>
          </Grid>
          <Grid item xs={12} style={styles.gridRow}>
            <InputField
              id="password"
              cy="password-field"
              type="password"
              label="Password"
              placeholder="Enter your password"
              defaultValue="********"
              sx={{ marginBottom: 0 }}
              disabled={false}
            />
            <Box style={styles.actionContainer}>
              <Button
                sx={styles.actionButton}
                naked
                text="Change Password"
                onClick={handlePasswordEditing}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} style={styles.gridRow} />
        </Grid>

        {errorState.general && <ErrorHelperText text={errorState.general} />}

        <Box style={styles.footer}>
          <Button
            type="submit"
            text="Save"
            variant="contained"
            style={styles.button}
            loading={isSaving}
          />
        </Box>
      </form>
      <Modal
        open={isEditingPassword}
        onClose={() => updateIsEditingPassword(!isEditingPassword)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal} data-cy="modal-content">
          <Box style={styles.modalContainer}>
            <div style={{ display: "flex" }}>
              <HeadingSmall text="Change Password" sx={styles.heading} />
              <CloseIcon
                onClick={() => {
                  updateIsEditingPassword(false);
                }}
                style={styles.closeButton}
              />
            </div>
            <Paragraph>
              To change your password please enter and confirm the new one
              below.
            </Paragraph>
            <Paragraph>It will need:</Paragraph>
            <Paragraph>
              <li>At least 8 characters</li>
              <li>One capital letter</li>
              <li>One special character </li>
              <li>One number</li>
            </Paragraph>
            <NewPasswordForm onComplete={handlePasswordChange} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MyAccountForm;
