import typography from "theme/styles/typography/typography";
import colors from "theme/styles/colors/colors";
import spacing from "theme/styles/spacing/spacing";
import buttons from "theme/styles/buttons/buttons";
import links from "theme/styles/links/links";

const stylesConfig = {
  typography: {
    headingVeryLarge: { ...typography.h1 },
    headingSmall: { ...typography.h5 },
    headingVerySmall: { ...typography.h6 },
    headingNotes: { ...typography.h7 },
    veryBoldHeading: { ...typography.veryBoldHeading },
    boldHeading: { ...typography.boldHeading },
    paragraph: { ...typography.p },
    paragraphDescription: { ...typography.pDescription },
    label: { ...typography.label },
    navlink: { ...typography.navlink },
    newLink: { ...typography.newLink },
    buttons: { ...typography.buttons },
  },
  spacing: {
    ...spacing,
  },
  colors: colors,
  sizes: {
    distanceFromTopToContent: "70px",
    sizeOfLayoutHeader: "96px",
    bottomMarginOfLayoutHeader: "80px",
    sidebarDistanceFromTop: "200px",
  },
  links: links,
  shadows: {
    standard: "0px 3px 6px #00000029",
  },
  buttons: buttons,
  navigationActive: {
    "& .navigation-link-text": {
      fontWeight: "bold",
    },
    "& .navigation-link-text::after": {
      content: "' '",
      position: "absolute",
      left: "0",
      bottom: "10px",
      height: "3px",
      width: "100%",
      background: colors.primary,
      borderRadius: "4px",
      cursor: "pointer",
    },
  },
};

export default stylesConfig;
