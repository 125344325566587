import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import styles from "./UploadImages.style";
import reducers from "reducers/form-errors-reducer";
import Dropzone from "components/core/dropzone/Dropzone";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import DroppedImageThumbnails from "components/core/dropzone/DroppedImageThumbnails/DroppedImageThumbnails";
import Paragraph from "components/core/typography/paragraph/Paragraph";
const intialErrorState = {
  files: null,
  mediaType: null,
};

const UploadImages = ({ updateCoverImage }) => {
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const [uploadingFiles] = useState([]);
  const [files, updateFiles] = useState([]);
  const [fileNumber, updateFileNumber] = useState(0);

  const clearError = (fieldName) => {
    reducers.clearFieldError(fieldName, errorState, errorDispatch);
    reducers.clearFieldError("general", errorState, errorDispatch);
  };
  const clearFilesError = () => clearError("files");
  const dropHandler = (acceptedFiles, errors) => {
    clearFilesError();
    updateFiles([...files, ...acceptedFiles]);

    updateCoverImage([...files, ...acceptedFiles]);
    updateFileNumber(acceptedFiles.length);
    if (errors.length) {
      const fileNameString = errors.map((error) => error.file.name).join(", ");
      reducers.createFormErrorsHandler(
        "files",
        `The following are not images and so have not been added: ${fileNameString}.`,
        errorDispatch
      );
    }
  };

  const removeItem = (index) => {
    const updatedItems = [...files];
    updatedItems.splice(index, 1);
    updateFiles(updatedItems);
    updateCoverImage(updatedItems);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <form style={styles.container}>
        {uploadingFiles.length > 0 ? (
          <></>
        ) : (
          <>
            <Box sx={styles.formFields}>
              <Box sx={styles.dropWrapper}>
                <Paragraph sx={styles.label}>Cover Image</Paragraph>
                {files.length ? (
                  <DroppedImageThumbnails
                    files={files}
                    onClick={(index) => {
                      updateFileNumber(fileNumber - 1);
                      clearFilesError();
                      removeItem(index);
                    }}
                  />
                ) : (
                  <Dropzone
                    onDrop={dropHandler}
                    accept={{ "image/jpeg": [".jpeg", ".png"] }}
                    fileNumber={fileNumber}
                    noClick={false}
                  />
                )}
                {errorState.files && (
                  <ErrorHelperText text={errorState.files} />
                )}
              </Box>
            </Box>
          </>
        )}
      </form>
    </Box>
  );
};

UploadImages.defaultProps = {
  actions: undefined,
  onClick: () => {},
  onComplete: () => {},
  id: null,
  onUpload: () => {},
  onClose: () => {},
  closeMenu: () => {},
};
UploadImages.propTypes = {
  actions: PropTypes.element,
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onUpload: PropTypes.func,
  onClose: PropTypes.func,
  closeMenu: PropTypes.func,
};

export default UploadImages;
