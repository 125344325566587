import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import styles from "./DroppedImageThumnails.style";
import BasicImageList from "components/core/dropzone/DroppedImageThumbnails/image-list/BasicImageList";
const DroppedImageThumbnails = ({ files, onClick }) => {
  const [filePreview, updateFilePreview] = useState([]);

  useEffect(() => {
    let newstate = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let src = URL.createObjectURL(file);
      newstate = [...newstate, { src, alt: file.name }];
    }
    updateFilePreview(newstate);
  }, [files]);

  return (
    <Box sx={styles.continer}>
      <BasicImageList
        items={filePreview}
        onClick={onClick}
        errorStyle={styles.imgError}
      />
    </Box>
  );
};

DroppedImageThumbnails.defaultProps = {
  files: [],
};

DroppedImageThumbnails.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
};
export default DroppedImageThumbnails;
