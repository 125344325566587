import React from "react";
import PropTypes from "prop-types";
import PopoverModal from "components/core/modal/popover-modal/PopoverModal";
import DeleteContentForm from "components/forms/content-management/delete-content/DeleteContent";
import ArchiveContentForm from "components/forms/content-management/archive-content/ArchiveContent";
import UnArchiveContentForm from "components/forms/content-management/un-archive-content/UnArchiveContent";
import EditContentForm from "components/forms/content-management/edit-content/EditContent";
import stylesConfig from "theme/config";
import CloseButton from "../../../../components/core/buttons/Close/Close";

const TableOptions = ({ data, onComplete, close, setAlertType }) => {
  return (
    <>
      {!data.isArchived ? (
        <PopoverModal
          buttonTheme="primary"
          popover
          hideHeader={true}
          hideCloseButton={true}
          buttonCy="edit-content-button"
          buttonText="Edit Content"
          headingText="Edit Content"
          onClose={close}
        >
          <EditContentForm
            id={data.id}
            onCancel={close}
            onComplete={() => {
              setAlertType("edited");
              onComplete();
            }}
            actions={
              <CloseButton
                style={{
                  marginLeft: "auto",
                  fontSize: "30px",
                  color: stylesConfig.colors.primary,
                  backgroundColor: stylesConfig.colors.white,
                  borderWidth: "0px",
                }}
              />
            }
          />
        </PopoverModal>
      ) : null}

      {!data.isArchived ? (
        <PopoverModal
          buttonTheme="primary"
          popover
          hideHeader={true}
          hideCloseButton={true}
          buttonCy="archive-content-button"
          buttonText="Archive Content"
          headingText="Attention!"
        >
          <ArchiveContentForm
            id={data.id}
            onCancel={close}
            onComplete={() => {
              setAlertType("archived");
              onComplete();
            }}
            closeModal={close}
            actions={
              <CloseButton
                style={{
                  marginLeft: "auto",
                  fontSize: "30px",
                  color: stylesConfig.colors.primary,
                  backgroundColor: stylesConfig.colors.white,
                  borderWidth: "0px",
                }}
              />
            }
          />
        </PopoverModal>
      ) : (
        <PopoverModal
          buttonTheme="primary"
          popover
          hideHeader={true}
          hideCloseButton={true}
          buttonCy="un-archive-content-button"
          buttonText="Un-archive Content"
          headingText="Attention!"
        >
          <UnArchiveContentForm
            id={data.id}
            onCancel={close}
            onComplete={() => {
              setAlertType("unArchived");
              onComplete();
            }}
            closeModal={close}
            actions={
              <CloseButton
                style={{
                  marginLeft: "auto",
                  fontSize: "30px",
                  color: stylesConfig.colors.primary,
                  backgroundColor: stylesConfig.colors.white,
                  borderWidth: "0px",
                }}
              />
            }
          />
        </PopoverModal>
      )}
      <PopoverModal
        buttonTheme="primary"
        popover
        hideHeader={true}
        hideCloseButton={true}
        buttonCy="delete-content-button"
        buttonText="Delete Content"
        headingText="Attention!"
      >
        <DeleteContentForm
          id={data.id}
          onCancel={close}
          onComplete={() => {
            setAlertType("deleted");
            onComplete();
          }}
          closeModal={close}
          actions={
            <CloseButton
              style={{
                marginLeft: "auto",
                fontSize: "30px",
                color: stylesConfig.colors.primary,
                backgroundColor: stylesConfig.colors.white,
                borderWidth: "0px",
              }}
            />
          }
        />
      </PopoverModal>
    </>
  );
};

TableOptions.defaultProps = {
  data: undefined,
  onComplete: () => {},
  close: () => {},
};

TableOptions.propTypes = {
  data: PropTypes.shape({}),
  onComplete: PropTypes.func,
  close: PropTypes.func,
};

export default TableOptions;
