import stylesConfig from "theme/config";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: { xs: "550px" },
  },
  heading: {
    marginBottom: stylesConfig.spacing.standard,
  },
  buttonWrapper: {
    display: "flex",
  },
  button: {
    width: "100%",
    marginTop: stylesConfig.spacing.standard,
  },
  logo: {
    maxWidth: "100%",
    alignSelf: "flex-start",
    margin: `${stylesConfig.spacing.veryLarge} 0`,
    paddingTop: stylesConfig.spacing.veryLarge,
  },
};

export default styles;
