import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import errorHandler from "./UnArchiveContent.error.handler";
import contentService from "services/content/content-service";
import GenericDeleteForm from "components/forms/generic/delete/Delete";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import utils from "lib/utils";
import styles from "./UnArchiveContent.styles";

const UnArchiveContent = ({
  id,
  closeModal,
  onComplete,
  onCancel,
  actions,
}) => {
  const handleCloseModal = (e) => {
    closeModal();
    onCancel();
  };

  const actionsWithProps = utils.mapPropsToChilden(actions, {
    onClick: handleCloseModal,
  });

  return (
    <Box sx={styles.panel}>
      <>
        <Box sx={styles.header}>
          <HeadingBold text="Attention!" />
          {actionsWithProps}
        </Box>
        <div style={styles.divider} />
      </>
      <GenericDeleteForm
        id={id}
        closeModal={closeModal}
        onComplete={onComplete}
        onCancel={onCancel}
        service={() => contentService.unArchiveContentById(id)}
        errorHandler={errorHandler}
        primaryActionText="Yes, un-archive it"
        cancelActionText="No, keep it"
        description={
          <Paragraph>
            Are you sure you wish to un-archive this content? This will make the
            content available in the app.
          </Paragraph>
        }
      />
    </Box>
  );
};
UnArchiveContent.defaultProps = {
  onClick: () => {},
  onComplete: () => {},
  onCancel: () => {},
  id: undefined,
};
UnArchiveContent.propTypes = {
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
};
export default UnArchiveContent;
