import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    return reducers.createFormErrorsHandler(
      "general",
      "There was a problem removing this patient.",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
