import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.status === 422) {
    const errorData = err.data;

    if (errorData.field === "date") {
      return reducers.createFormErrorsHandler(
        "date",
        errorData.message,
        errorDispatch
      );
    }
    if (errorData.field === "time") {
      return reducers.createFormErrorsHandler(
        "time",
        errorData.message,
        errorDispatch
      );
    }
    if (errorData.field === "sessionNumber") {
      return reducers.createFormErrorsHandler(
        "sessionNumber",
        errorData.message,
        errorDispatch
      );
    }

    if (errorData.field === "attendees") {
      return reducers.createFormErrorsHandler(
        "attendees",
        errorData.message,
        errorDispatch
      );
    }

    return reducers.createFormErrorsHandler(
      "general",
      "There was an issue when trying to save this patiente.",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
