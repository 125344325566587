import React from "react";
import PropTypes from "prop-types";
import styles from "./PopoverModal.style";
import Modal from "components/core/modal/basic-modal/Modal";

const BasicModal = ({
  buttonCy,
  buttonText,
  buttonIcon,
  style,
  children,
  headingText,
  closeModal,
  updateModalState,
  buttonStyle,
  buttonTheme,
  buttonSize,
  modalStyle,
  buttonChildren,
  hideCloseButton,
  hideHeader,
  nakedButton,
  modalElementStyles,
  onClose,
}) => {
  return (
    <Modal
      buttonCy={buttonCy}
      buttonText={buttonText}
      buttonIcon={buttonIcon}
      style={style}
      children={children}
      headingText={headingText}
      hideHeader={hideHeader}
      closeModal={closeModal}
      onClose={onClose}
      updateModalState={updateModalState}
      buttonStyle={{ ...styles.button, ...buttonStyle }}
      buttonTheme={buttonTheme}
      buttonSize={buttonSize}
      modalStyle={modalStyle}
      buttonChildren={buttonChildren}
      hideCloseButton={hideCloseButton}
      nakedButton={nakedButton}
      modalElementStyles={modalElementStyles}
    />
  );
};

BasicModal.defaultProps = {
  closeModal: undefined,
  updateModalState: () => {},
  onClose: () => {},
  buttonStyle: undefined,
  modalStyle: undefined,
  buttonTheme: undefined,
  buttonSize: undefined,
  hideCloseButton: true,
  hideHeader: false,
  nakedButton: false,
  modalElementStyles: undefined,
};

BasicModal.propTypes = {
  closeModal: PropTypes.bool,
  updateModalState: PropTypes.func,
  onClose: PropTypes.func,
  buttonStyle: PropTypes.shape({}),
  modalStyle: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonTheme: PropTypes.oneOf(["primary", "secondary"]),
  buttonSize: PropTypes.oneOf(["standard"]),
  hideCloseButton: PropTypes.bool,
  hideHeader: PropTypes.bool,
  nakedButton: PropTypes.bool,
  modalElementStyles: PropTypes.shape({}),
};

export default BasicModal;
