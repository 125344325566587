import stylesConfig from "theme/config";

const styles = {
  container: { display: "flex" },
  main: {
    flexGrow: 1,
    pl: 4,
    pr: 4,
    pb: 4,
    pt: 0,
    backgroundColor: stylesConfig.colors.background,
    minHeight: "100vh",
  },
  pageSepcificNavigation: {
    height: stylesConfig.sizes.distanceFromTopToContent,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  headingsContainer: {
    marginBottom: stylesConfig.spacing.veryLarge,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    margin: 0,
  },
  smallHeading: {
    textTransform: "capitalize",
    marginLeft: stylesConfig.spacing.standard,
  },
};

export default styles;
