import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import styles from "./DroppedFilePreview.style";
import BasicFileList from "components/core/dropzone/drop-file-zone/file-list/BasicFileList";

const DroppedFilePreview = ({ files, onClick }) => {
  const [filePreview, updateFilePreview] = useState([]);

  useEffect(() => {
    let newstate = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let src = URL.createObjectURL(file);
      newstate = [...newstate, { src, alt: file.name }];
    }
    updateFilePreview(newstate);
  }, [files]);

  return (
    <Box sx={styles.continer}>
      <BasicFileList
        items={filePreview}
        onClick={onClick}
        errorStyle={styles.imgError}
      />
    </Box>
  );
};

DroppedFilePreview.defaultProps = {
  files: [],
};

DroppedFilePreview.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
};
export default DroppedFilePreview;
