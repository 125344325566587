import { createTheme } from "@mui/material";
import fonts from "theme/styles/fonts/fonts";
import spacing from "theme/styles/spacing/spacing";
import colors from "theme/styles/colors/colors";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFace: fonts.fontFace,
        body: {
          "*::-webkit-scrollbar": {
            width: "0.4em",
            height: "calc(100% - 20px)",
          },
          "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: colors.primary,
            borderRadius: 8,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "45px",
          paddingLeft: spacing.small,
          backgroundColor: colors.standardGrey,
        },
        input: {
          "&:-webkit-autofill": {
            "-webkit-transition-delay": "9999s",
            "transition-delay": "9999s",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: fonts.fontFamily,
  },
});

export default theme;
