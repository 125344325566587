import React from "react";
import PropTypes from "prop-types";
import errorHandler from "./DeleteStaff.error.handler";
import userService from "services/user/user-service";
import GenericDeleteForm from "components/forms/generic/delete/Delete";
import Paragraph from "components/core/typography/paragraph/Paragraph";
const DeleteStaffForm = ({ id, closeModal, onComplete, onCancel }) => {
  return (
    <GenericDeleteForm
      id={id}
      closeModal={closeModal}
      onComplete={onComplete}
      service={() => userService.deleteUserById(id)}
      errorHandler={errorHandler}
      primaryActionText="Yes, remove it"
      cancelActionText="No, keep it"
      description={
        <Paragraph>
          Are you sure you want to remove this staff member? Once removed, this
          cannot be undone.
        </Paragraph>
      }
    />
  );
};
DeleteStaffForm.defaultProps = {
  onClick: () => {},
  onComplete: () => {},
  onCancel: () => {},
  id: undefined,
};
DeleteStaffForm.propTypes = {
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
};
export default DeleteStaffForm;
