import React from "react";
import { Box } from "@mui/material";
import LoginLayout from "components/layouts/login-layout/LoginLayout";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import InternalLink from "components/core/links/Internal/InternalLink";

import styles from "./NewPasswordPage.styles.js";

export const InvalidPasswordResetTokenPage = () => {
  return (
    <LoginLayout
      content={
        <Box style={styles.box} sx={{ minWidth: { lg: "400px" } }}>
          <HighlightOffIcon sx={styles.icon} />
          <HeadingSmall
            text="Password Reset Not Successful"
            sx={styles.heading}
          />
          <Paragraph
            text="Your password has not been updated."
            sx={styles.text}
          />
          <InternalLink
            to="/login"
            text="Login"
            theme="primaryButton"
            style={styles.retry}
          />
        </Box>
      }
    />
  );
};
