import React, { useState } from "react";
import PasswordField from "components/core/form/PasswordInput/PasswordInput";
import Button from "components/core/buttons/Primary/Primary";

import styles from "./NewPasswordForm.styles";

const NewPasswordForm = ({ onComplete }) => {
  const [error, setError] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    const password = e.target.password.value;
    const passwordConfirm = e.target["password-confirm"].value;

    if (password !== passwordConfirm) {
      return setError("Passwords do not match");
    }

    const onCompleteError = await onComplete(password);

    if (onCompleteError) {
      setError(onCompleteError.data.message);
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PasswordField
        id="password"
        cy="password-field"
        label="New Password"
        autoComplete="new-password"
        required
        error={error ? true : false}
        onChange={() => setError("")}
        inputProps={{ maxLength: 246 }}
        placeholder={"Enter a new password"}
        type="password"
      />
      <PasswordField
        id="password-confirm"
        cy="password-confirm-field"
        label="Confirm New Password"
        autoComplete="new-password"
        placeholder={"Re-enter new password"}
        required
        inputProps={{ maxLength: 500 }}
        error={error ? true : false}
        onChange={() => setError("")}
        helperText={error}
        type="password"
      />

      <Button type="submit" text="Submit" style={styles.button} />
    </form>
  );
};

export default NewPasswordForm;
