import React from "react";
import PropTypes from "prop-types";
import styles from "./Primary.style";
import Button from "@mui/material/Button";
import CircularLoader from "components/core/loaders/circular/Circular";
const PrimaryButton = ({
  type,
  text,
  buttonIcon,
  style,
  cy,
  onClick,
  loading,
  disabled,
  children,
  theme,
  size,
  sx,
  naked,
}) => {
  let loadingStyle = {};
  if (loading) {
    loadingStyle = styles.loading;
    if (theme === "warning") {
      loadingStyle = {
        ...loadingStyle,
        ...styles.warningLoading,
      };
      if (theme === "underlined") {
        loadingStyle = {
          ...loadingStyle,
          ...styles.underlineLoading,
        };
      }
    }
  }

  let disabledStyle = {};
  if (disabled) {
    disabledStyle = styles.disabled;
  }

  let themeStyles = "";
  if (theme) {
    themeStyles = styles[theme];
  }

  let sizeStyles = "";
  if (size) {
    sizeStyles = styles.size[size];
  }

  let nakedStyles = "";
  if (naked) {
    nakedStyles = styles.naked;
  }

  return (
    <Button
      type={type}
      data-cy={cy}
      startIcon={buttonIcon}
      disabled={loading || disabled}
      sx={{
        ...styles.button,
        ...themeStyles,
        ...sizeStyles,
        ...style,
        ...nakedStyles,
        ...sx,
        ...loadingStyle,
        ...disabledStyle,
      }}
      onClick={onClick}
    >
      {children}
      {text}
      {loading && (
        <CircularLoader sx={styles.loader} cy="primary-button-loader" />
      )}
    </Button>
  );
};

PrimaryButton.defaultProps = {
  type: "button",
  style: undefined,
  cy: "submit-button",
  onClick: undefined,
  loading: false,
  disabled: false,
  theme: undefined,
  size: undefined,
  sx: undefined,
  naked: false,
};

PrimaryButton.propTypes = {
  naked: PropTypes.bool,
  type: PropTypes.string,
  theme: PropTypes.oneOf([
    "primary",
    "primary-inverse",
    "secondary",
    "secondary-inverse",
    "underline",
    "warning",
    "outline",
  ]),
  size: PropTypes.oneOf(["standard", "standard-bold"]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  style: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  cy: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default PrimaryButton;
