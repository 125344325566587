import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import AppointmentsTable from "components/appointments/appointmentsTable/AppointmentsTable";
import AlertModal from "components/core/modal/alert-modal/AlertModal";
import Bold from "components/core/typography/headings/bold/HeadingBold";
import Loader from "components/core/loaders/circular/Circular";
import RaisedBox from "components/core/raised-box/RaisedBox";
import appoitmentsService from "services/appointments/appointments-service";
import dashboardService from "services/dashboard/dashboard-service";
import ViewAppointmentOptions from "components/data-display/Table/Options/ViewAppointmentsOptions/ViewAppointmentsOptions";
import styles from "./Home.styles";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";

const tableFormat = [
  { id: "date", label: "Date", allowSort: false },
  { id: "time", label: "Time", allowSort: false },
  { id: "session", label: "Session", allowSort: false },
  { id: "attendees", label: "Attendees", allowSort: false },
];

const Home = () => {
  const [appointments, updateAppointments] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [loadingMetrics, updateLoadingMetrics] = useState(true);
  const [openAlertModal, updateOpenAlertModal] = useState(false);
  const [metrics, updateMetrics] = useState({});
  const [noResultsMessage, setNoResultsMessage] = useState(
    "There are currently no appointments."
  );

  const getAppointments = useCallback(
    async (nextPage) => {
      const response = await appoitmentsService.getTodayAppointments();

      updateLoading(false);

      if (!response || response.message) {
        setNoResultsMessage("No Results from API");
        return;
      }

      const appointmentsArray = response.results.map((appointment) => {
        return {
          id: appointment.id,
          fields: [
            appointment.date,
            appointment.time,
            appointment.sessionTitle || appointment.sessionNumber,
            appointment.attendees,
            {
              Component: () => (
                <ViewAppointmentOptions
                  data={appointment}
                  onComplete={handleViewAppointment}
                />
              ),
            },
          ],
        };
      });

      updateAppointments(appointmentsArray);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getMetrics = useCallback(
    async (nextPage) => {
      const response = await dashboardService.getDashboardMetrics();

      updateLoadingMetrics(false);
      updateMetrics(response);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getAppointments();
    const getDasboardMetrics = async () => await getMetrics();
    get();
    getDasboardMetrics();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewAppointment = () => {
    updateOpenAlertModal(true);
  };

  let loadingStyles = {};
  if (loading) {
    loadingStyles = styles.loading;
  }

  return (
    <>
      <LoggedIn>
        <Box sx={{ ...styles.panel, ...loadingStyles }}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <RaisedBox sx={styles.boxPadding}>
                <Bold cy="heading-large">Todays Appointments</Bold>
                <Box sx={{ flexGrow: 1 }}>
                  <AppointmentsTable
                    data={appointments}
                    noResultsMessage={noResultsMessage}
                    tableFormat={tableFormat}
                    getDataHandler={getAppointments}
                    hideOptions
                    hidePageing
                    style={styles.table}
                  />
                </Box>
              </RaisedBox>

              {loadingMetrics ? (
                <RaisedBox
                  sx={{
                    flexGrow: 1,
                    marginTop: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItens: "center",
                  }}
                >
                  <Loader />
                </RaisedBox>
              ) : (
                <Box sx={{ flexGrow: 1, paddingTop: "2rem" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box sx={styles.itemContainer}>
                        <div>
                          <Paragraph sx={styles.counterText}>
                            {metrics.activeUsers}
                          </Paragraph>
                          <HeadingBold>Active Participants</HeadingBold>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={styles.itemContainer}>
                        <div>
                          <Paragraph sx={styles.counterText}>
                            {`${metrics.attendance}%`}
                          </Paragraph>
                          <HeadingBold>Overall Attendance Rate</HeadingBold>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Box>
        <AlertModal
          open={openAlertModal}
          onClose={() => updateOpenAlertModal(!openAlertModal)}
          title="Attendance Recorded"
          description="Your patients attendance for this meeting has been
          successfully recorded."
          ctaText="Close"
        />
      </LoggedIn>
    </>
  );
};

export default Home;
