import React, { useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import CloseIcon from "@mui/icons-material/Close";

import registrationService from "services/registration/registration-service";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import DeleteStaffForm from "components/forms/staff/delete-a-staff/DeleteStaff";
import ChangeStaffStatusForm from "components/forms/staff/change-staff-status/ChangeStaffStatus";
import { GlobalContext } from "context/GlobalContext";
import styles from "../Options.style";
import stylesConfig from "theme/config";

const isCurrent = (current, id) => {
  if (current === id) {
    return true;
  }
  return false;
};

const StaffOptions = ({ data, onComplete }) => {
  const { user } = useContext(GlobalContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmailSendModal, setShowEmailSendModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const resendEmailNotification = async () => {
    await registrationService.resendRegistrationEmail(data.id);
    setShowEmailSendModal(true);
  };

  return (
    <>
      {!isCurrent(user.id, data.id) && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {data.inviteStatus === "Pending" && (
            <>
              <PrimaryButton
                style={styles.primaryButtonStyle}
                text="Re-send Invite"
                onClick={resendEmailNotification}
                cy="resend-registration-email-button"
              />
              <Modal
                open={showEmailSendModal}
                onClose={() => setShowEmailSendModal(false)}
              >
                <Box sx={styles.modal}>
                  <>
                    <Box sx={styles.header}>
                      <HeadingBold text={"Attention!"} />
                      <CloseIcon
                        onClick={() => {
                          setShowEmailSendModal(false);
                        }}
                        style={styles.closeButton}
                      />
                    </Box>
                    <div style={styles.divider} />
                  </>
                  <Box style={styles.padding}>
                    <Paragraph>
                      A new email invitation has been successfully sent to{" "}
                      {data.firstName}.
                    </Paragraph>
                  </Box>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <PrimaryButton
                      text="Close"
                      onClick={() => setShowEmailSendModal(false)}
                      style={{ border: "none" }}
                    />
                  </div>
                </Box>
              </Modal>
            </>
          )}
          {data.inviteStatus === "Accepted" && (
            <>
              <PrimaryButton
                style={styles.primaryButtonStyle}
                text={data.active ? "Disable" : "Activate"}
                onClick={() => setShowStatusModal(true)}
                cy="resend-registration-email-button"
              />

              <Modal
                open={showStatusModal}
                onClose={() => setShowStatusModal(false)}
              >
                <Box sx={styles.modal}>
                  <>
                    <Box sx={styles.header}>
                      <HeadingBold text={"Attention!"} />
                      <CloseIcon
                        onClick={() => {
                          setShowStatusModal(false);
                        }}
                        style={styles.closeButton}
                      />
                    </Box>
                    <div style={styles.divider} />
                  </>
                  <Box style={styles.margin}>
                    <ChangeStaffStatusForm
                      closeModal={() => setShowStatusModal(false)}
                      onComplete={onComplete}
                      id={data.id}
                      userCurrentStatus={data.active}
                    />
                  </Box>
                </Box>
              </Modal>
            </>
          )}
          <div
            style={{
              height: 18,
              width: 2,
              backgroundColor: stylesConfig.colors.dividerGrey,
            }}
          />
          <PrimaryButton
            style={styles.deleteButtonStyle}
            text="Remove"
            onClick={() => setShowDeleteModal(true)}
            cy="delete-user-button"
          />
          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <Box sx={styles.modal} data-cy="modal-content">
              <>
                <Box sx={styles.header}>
                  <HeadingBold text={"Attention!"} />
                  <CloseIcon
                    onClick={() => {
                      setShowDeleteModal(false);
                    }}
                    style={styles.closeButton}
                  />
                </Box>
                <div style={styles.divider} />
              </>
              <Box style={styles.margin}>
                <DeleteStaffForm
                  closeModal={() => setShowDeleteModal(false)}
                  onComplete={onComplete}
                  id={data.id}
                />
              </Box>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default StaffOptions;
