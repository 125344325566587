import React from "react";
import PropTypes from "prop-types";
import { Container, Box } from "@mui/material";
import ExternalLink from "components/core/links/External/ExternalLink";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import styles from "./RegistrationResponseWidget.style";
export const RegistrationResponseWidget = ({
  href,
  heading,
  bodyText,
  Icon,
}) => {
  return (
    <Container sx={styles.container}>
      <Box>
        {Icon && <Icon />}
        <HeadingSmall cy="header" sx={styles.heading}>
          {" "}
          {heading}{" "}
        </HeadingSmall>
        <Paragraph cy="body-text"> {bodyText} </Paragraph>
      </Box>
      <Box sx={styles.buttonWrapper}>
        <ExternalLink
          cy="return-to-sign-in-button"
          href={href}
          theme="primaryButton"
          style={styles.button}
        >
          Login
        </ExternalLink>
      </Box>
    </Container>
  );
};

RegistrationResponseWidget.defaultProps = {
  Icon: "",
};

RegistrationResponseWidget.propTypes = {
  href: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  Icon: PropTypes.string,
};
