import stylesConfig from "theme/config";

const styles = {
  formFooter: {
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column-reverse", sm: "row" },
    justifyContent: { xs: "", sm: "flex-end" },
  },
  internalLink: {
    paddingBottom: stylesConfig.spacing.standard,
    marginBottom: stylesConfig.spacing.veryLarge,
    font: "normal normal bold 14px/19px Poppins",
    textAlign: "right",
  },
  button: {
    ...stylesConfig.buttons.primary,
  },
  margin: {
    marginBottom: stylesConfig.spacing.small,
    paddingBottom: "0px",
  },
};

export default styles;
