import stylesConfig from "theme/config";

const styles = {
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
  },
  table: {
    tableContainer: {
      background: stylesConfig.colors.background,
    },
  },
  item: {
    padding: stylesConfig.spacing.standard,
    background: "red",
    minHeight: 356,
  },
  counterText: {
    font: "normal normal bold 80px/45px Poppins",
    color: stylesConfig.colors.primary,
  },
  itemContainer: {
    padding: stylesConfig.spacing.standard,
    textAlign: "center",
    minHeight: 336,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: stylesConfig.colors.white,
    boxShadow: stylesConfig.shadows.standard,
    borderRadius: "10px",
  },
};

export default styles;
