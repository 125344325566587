import React, { useState } from "react";
import PropTypes from "prop-types";
import Cookie from "universal-cookie";
import HTTPError from "../lib/errors/http-error";
import errorCodes from "lib/error-codes";
import _ from "lodash";
import authenticationService from "../services/authentication/authentication-service";
import userTypes from "../lib/user-types";
const AUTH_COOKIE_NAME = "rf-token";
const cookies = new Cookie();

export const GlobalContext = React.createContext({
  user: null,
  loginHandler: () => {},
  getUserFromLocalStorage: () => {},
});

const storeUserInLocalStorage = (userData) => {
  if (!userData) {
    localStorage.removeItem("user");
    return;
  }

  // Only storing what we actually need just now.
  const redactedUserData = {
    id: userData.id,
    email: userData.email,
    activities: userData.activities,
    type: userData.type,
    firstName: userData.firstName,
    lastName: userData.lastName,
  };

  if (_.has(userData, "organisation")) {
    redactedUserData.organisation = userData.organisation;
  }

  localStorage.setItem("user", JSON.stringify(redactedUserData));
};

const getUserFromLocalStorage = () => {
  const user = localStorage.getItem("user");

  if (user) {
    return JSON.parse(user);
  }

  return undefined;
};

const GlobalContextProvider = ({ children }) => {
  const [user, setUserData] = useState(getUserFromLocalStorage());

  const logoutHandler = async () => {
    storeUserInLocalStorage(undefined);
    await cookies.remove(AUTH_COOKIE_NAME);

    setUserData(null);

    window.location.replace("/login");
  };

  // login handler, utilised by the login screen.
  const loginHandler = async (email, password) => {
    // Clear up any existing sessions before attempting to login
    storeUserInLocalStorage(undefined);
    await cookies.remove(AUTH_COOKIE_NAME);
    setUserData(null);

    const response = await authenticationService.login(email, password);

    if (response.data.type === userTypes.PATIENT) {
      response.ok = false;
      response.status = 422;
      response.data = {
        statusCode: errorCodes.FORBIDDEN_RESOURCE,
        messages: "User not allowed to access the admim portal",
      };
      throw new HTTPError(response.status, response.data);
    }

    await cookies.set(AUTH_COOKIE_NAME, response.data.token, { path: "/" });

    const userData = {
      id: response.data.id,
      email: response.data.email,
      type: response.data.type,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      activities: response.data.activities,
    };

    // super admins will not have orgs so we'll check for the key before we add
    if (_.has(response.data, "organisation")) {
      userData.organisation = response.data.organisation.id;
    }

    setUserData(userData);
    storeUserInLocalStorage(userData);

    return userData;
  };

  const updateUser = (userData) => {
    setUserData(userData);
    storeUserInLocalStorage(userData);
  };

  return (
    <GlobalContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        user,
        updateUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node,
};

export default GlobalContextProvider;
