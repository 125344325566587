import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PatientDataBaseIcon from "../../../../../assets/patient_black_24.svg";
import PatientDataBaseActiveIcon from "../../../../../assets/patient_blue_24.svg";
import ContentActiveIcon from "../../../../../assets/content_blue_24.svg";
import ContentIcon from "../../../../../assets/content_black_24.svg";
import AppointmentsActiveIcon from "../../../../../assets/appointments_blue_24.svg";
import AppointmentsIcon from "../../../../../assets/appointments_black_24.svg";
import StaffActiveIcon from "../../../../../assets/staff_blue_24.svg";
import SessionsIcon from "../../../../../assets/sessions_black_24.svg";
import SessionsActiveIcon from "../../../../../assets/sessions_blue_24.svg";
import StaffIcon from "../../../../../assets/staff_black_24.svg";
import SidebarLink from "components/core/links/SidebarLink/SidebarLink";
import Img from "components/core/img/Img";

const AdminLinks = () => {
  return (
    <>
      <SidebarLink
        to="/"
        text="Home"
        Icon={HomeOutlinedIcon}
        cy="super-admin-home"
      />
      <SidebarLink
        to="/content-management"
        text="Content Management"
        cy="super-admin-content"
        Icon={() => <Img alt="content icon" src={ContentIcon} />}
        IconActive={() => (
          <Img alt="content icon active" src={ContentActiveIcon} />
        )}
      />
      <SidebarLink
        to="/patient-database"
        text="Patient Database"
        cy="super-admin-patient-database"
        Icon={() => <Img alt="patient icon" src={PatientDataBaseIcon} />}
        IconActive={() => (
          <Img alt="patient icon active" src={PatientDataBaseActiveIcon} />
        )}
      />
      <SidebarLink
        to="/appointments"
        text="Appointments"
        cy="super-admin-appointments"
        Icon={() => <Img alt="appointment icon" src={AppointmentsIcon} />}
        IconActive={() => (
          <Img alt="appointment icon active" src={AppointmentsActiveIcon} />
        )}
      />
      <SidebarLink
        to="/staff-management"
        text="Staff Management"
        cy="super-admin-staff"
        Icon={() => <Img alt="staff icon" src={StaffIcon} />}
        IconActive={() => <Img alt="staff icon active" src={StaffActiveIcon} />}
      />
      <SidebarLink
        to="/sessions"
        text="Sessions"
        cy="super-admin-sessions"
        Icon={() => <Img alt="sessions icon" src={SessionsIcon} />}
        IconActive={() => (
          <Img alt="sessions icon active" src={SessionsActiveIcon} />
        )}
      />
    </>
  );
};

AdminLinks.propTypes = {};
export default AdminLinks;
