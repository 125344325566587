import React from "react";
import PropTypes from "prop-types";
import errorHandler from "./ChangeStaffStatus.error.handler";
import userService from "services/user/user-service";
import GenericDeleteForm from "components/forms/generic/delete/Delete";
import Paragraph from "components/core/typography/paragraph/Paragraph";

const ChangeStaffStatusForm = ({
  id,
  userCurrentStatus,
  closeModal,
  onComplete,
  onCancel,
}) => {
  return (
    <GenericDeleteForm
      id={id}
      closeModal={closeModal}
      onComplete={onComplete}
      service={() => userService.changeUserStatus(id)}
      errorHandler={errorHandler}
      primaryActionText="Yes, change it"
      cancelActionText="No, keep it"
      description={
        <Paragraph>
          {`Are you sure you want to ${
            userCurrentStatus ? "disable" : "activate"
          } this staff member?`}
        </Paragraph>
      }
    />
  );
};
ChangeStaffStatusForm.defaultProps = {
  onClick: () => {},
  onComplete: () => {},
  onCancel: () => {},
  id: undefined,
  userCurrentStatus: false,
};
ChangeStaffStatusForm.propTypes = {
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
  userCurrentStatus: PropTypes.bool,
};
export default ChangeStaffStatusForm;
