import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import LoginLayout from "components/layouts/login-layout/LoginLayout";
import InternalLink from "components/core/links/Internal/InternalLink";
import Button from "components/core/buttons/Primary/Primary";
import ResendPasswordWidget from "./ResendPasswordWidget.jsx";
import styles from "./ForgottenPasswordPage.styles.js";

export const ForgottenPasswordLinkSentPage = () => {
  const [email, updateEmail] = useState("");
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const parsedQs = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    updateEmail(parsedQs.email);
  }, [location.search]);

  const goBack = async () => {
    return history.push(`/forgotten-password`);
  };

  return (
    <LoginLayout
      content={
        <Box style={styles.box}>
          <CheckCircleOutlineOutlinedIcon style={styles.check} />
          <HeadingSmall text="Email Sent" sx={styles.heading} />
          <Paragraph>
            An email to{" "}
            <Box component="span" sx={styles.bold}>
              {email}
            </Box>
            {". "}
          </Paragraph>
          <Paragraph
            sx={styles.margin}
            text="Please follow the instructions on the email and you’ll be ready to go. Check your junk/spam folder. Wrong email address entered? Edit Email Address"
          />
          <Box style={styles.editBox}>
            <Paragraph text="Wrong email address entered?" />
            <Button
              text={"Edit Email Address"}
              sx={styles.sendAgain}
              onClick={goBack}
              naked
            />
          </Box>

          <ResendPasswordWidget email={email} />

          <Box style={{ display: "flex" }}>
            <InternalLink
              to="/login"
              text="Return to Sign In"
              theme="primaryButton"
              style={styles.editButtonStyle}
            />
          </Box>
        </Box>
      }
    />
  );
};
