import api from "../../lib/http";

const getPatientById = async (id) => {
  const result = await api("get", `user/${id}`, null, true, true, true);
  return result.data;
};

const patchPatient = async (id, payload) => {
  const result = await api("patch", `user/${id}`, payload, true, true, true);
  return result.data;
};

const service = {
  getPatientById,
  patchPatient,
};
export default service;
