import colors from "theme/styles/colors/colors";
import buttons from "theme/styles/buttons/buttons";

const links = {
  default: {
    color: colors.black,
  },
  primaryButton: {
    ...buttons.primary,
    height: "unset",
    padding: "6px 28px",
    textDecoration: "none",
  },
  secondaryButton: {
    ...buttons.secondary,
    borderWidth: "2px",
    textDecoration: "none",
    color: colors.white,
    paddingTop: "16px",
    paddingBottom: "16px",
  },
};

export default links;
