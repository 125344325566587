import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import styles from "./UploadFile.style";
import reducers from "reducers/form-errors-reducer";
import DropFileZone from "components/core/dropzone/drop-file-zone/DropFileZone";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import DroppedFilePreview from "components/core/dropzone/drop-file-zone/drop-file-preview/DroppedFilePreview";
const intialErrorState = {
  files: null,
  mediaType: null,
};

const UploadFile = ({ saveFiles }) => {
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );
  const [uploadingFiles] = useState([]);
  const [files, updateFiles] = useState([]);
  const [fileNumber, updateFileNumber] = useState(0);

  const ErrorCode = {
    FileInvalidType: "file-invalid-type",
    FileTooLarge: "file-too-large",
    FileTooSmall: "file-too-small",
    TooManyFiles: "too-many-files",
  };
  const clearError = (fieldName) => {
    reducers.clearFieldError(fieldName, errorState, errorDispatch);
    reducers.clearFieldError("general", errorState, errorDispatch);
  };
  const clearFilesError = () => clearError("files");

  const dropHandler = (acceptedFiles, errors) => {
    clearFilesError();
    if (errors.length !== 0) {
      const { errors: errorObj } = errors[0];
      const { code } = errorObj[0];

      if (code === ErrorCode.FileTooLarge) {
        const fileNameString = errors
          .map((error) => error.file.name)
          .join(", ");
        reducers.createFormErrorsHandler(
          "files",
          `The following file is too large so have not been added: ${fileNameString}. Max size is 1mb`,
          errorDispatch
        );
      }
    }
    updateFiles([...files, ...acceptedFiles]);
    saveFiles([...files, ...acceptedFiles]);
    updateFileNumber(acceptedFiles.length);
  };

  const removeItem = (index) => {
    const updatedItems = [...files];
    updatedItems.splice(index, 1);
    updateFiles(updatedItems);
    saveFiles(updatedItems);
  };
  const maxSize = 1048576 * 50; //1m

  return (
    <Box sx={{ position: "relative" }}>
      <form style={styles.container}>
        {uploadingFiles.length > 0 ? (
          <></>
        ) : (
          <>
            <Box
              sx={{
                ...styles.formFields,
                alignItems: "center",
              }}
            >
              <Box sx={styles.dropWrapper}>
                <DropFileZone
                  onDrop={dropHandler}
                  minSize={0}
                  accept={{ "application/pdf": [".pdf"] }}
                  maxSize={maxSize}
                  fileNumber={fileNumber}
                  noClick={false}
                />
                {files.length ? (
                  <DroppedFilePreview
                    files={files}
                    onClick={(index) => {
                      updateFileNumber(fileNumber - 1);
                      clearFilesError();
                      removeItem(index);
                    }}
                  />
                ) : undefined}
                {errorState.files && (
                  <ErrorHelperText text={errorState.files} />
                )}
              </Box>
            </Box>
          </>
        )}
      </form>
    </Box>
  );
};

UploadFile.defaultProps = {
  actions: undefined,
  onClick: () => {},
  onComplete: () => {},
  id: null,
  onUpload: () => {},
  onClose: () => {},
  closeMenu: () => {},
  values: [],
};
UploadFile.propTypes = {
  actions: PropTypes.element,
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onUpload: PropTypes.func,
  onClose: PropTypes.func,
  closeMenu: PropTypes.func,
  values: PropTypes.array,
};

export default UploadFile;
