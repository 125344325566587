import React from "react";
import PropTypes from "prop-types";
import Sidebar from "components/core/navigation/sidebar/Sidebar";
import HeadingLarge from "components/core/typography/headings/large/HeadingLarge";
import Bold from "components/core/typography/headings/bold/HeadingBold";
import { Box } from "@mui/material";
import styles from "./LoggedIn.style";
import Loader from "components/core/loaders/bouncies/Bouncies";
import Modal from "components/core/modal/basic-modal/Modal";

const LoggedIn = ({
  children,
  heading,
  loading,
  header,
  smallHeading,
  smallHeadingStyles,
  buttonModal,
}) => {
  return (
    <>
      <Box sx={styles.container}>
        <Sidebar />
        <Box component="main" sx={styles.main}>
          <Box sx={styles.pageSepcificNavigation}>{header}</Box>
          <Box sx={styles.headingsContainer}>
            {heading && <HeadingLarge text={heading} sx={styles.heading} />}
            {smallHeading && (
              <Bold sx={{ ...styles.smallHeading, ...smallHeadingStyles }}>
                {" "}
                {smallHeading}
              </Bold>
            )}

            {buttonModal && buttonModal.title && buttonModal.modalContent ? (
              <Modal
                buttonTheme="primary"
                popover
                hideCloseButton={false}
                buttonCy={buttonModal.title}
                buttonText={buttonModal.title}
                headingText={buttonModal.title}
                closeModal={buttonModal.isModalClosed}
              >
                {buttonModal.modalContent}
              </Modal>
            ) : null}
          </Box>
          {children}
        </Box>
      </Box>
      {loading && <Loader />}
    </>
  );
};

LoggedIn.defaultProps = {
  loading: false,
  header: undefined,
  smallHeading: undefined,
  smallHeadingStyles: undefined,
  buttonModal: {
    modalContent: undefined,
    title: undefined,
    isModalClosed: undefined,
  },
};

LoggedIn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  heading: PropTypes.string,
  loading: PropTypes.bool,
  header: PropTypes.element,
  smallHeading: PropTypes.string,
  smallHeadingStyles: PropTypes.shape({}),
  buttonModal: PropTypes.shape({
    modalContent: PropTypes.node,
    title: PropTypes.string,
    isModalClosed: PropTypes.bool,
  }),
};
export default LoggedIn;
