import React, {
  useRef,
  useState,
  useReducer,
  useCallback,
  useEffect,
} from "react";
import { Grid, Box, Modal } from "@mui/material";
import moment from "moment";
import _ from "lodash";
import reducers from "reducers/form-errors-reducer";
import InputField from "components/core/form/Input/Input";
import PasswordField from "components/core/form/PasswordInput/PasswordInput";
import Button from "components/core/buttons/Primary/Primary";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import CloseIcon from "@mui/icons-material/Close";
import patientService from "../../../../services/patient/patient-service";
import userService from "../../../../services/user/user-service";
import Loader from "components/core/loaders/circular/Circular";
import errorHandler from "./PatchPatient.error.handler";
import HTTPError from "lib/errors/http-error";
import styles from "./PatchPatient.styles";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import PatientBiometricsTable from "components/patients/PaitentBiometricsTable/PatientsBiometricsTable";
import utils from "lib/utils";
import PropTypes from "prop-types";

const intialErrorState = {
  firstName: null,
  lastName: null,
  guardianName: null,
  relationshipToPatient: null,
  contactNumber: null,
  general: null,
};

const formDefaultValues = {
  firstName: null,
  lastName: null,
  guardianName: null,
  relationshipToPatient: null,
  contactNumber: null,
};

const tableFormat = [
  { id: "date", label: "Date", allowSort: false },
  { id: "height", label: "Height", allowSort: false },
  { id: "weight", label: "Weight", allowSort: false },
];

const PatchPatientForm = ({
  onCancel,
  actions,
  closeModal,
  onComplete,
  id,
}) => {
  const formRef = useRef(null);
  const [patient, updatePatient] = useState({
    nextAppointment: {},
    reportedHeight: {
      fields: [],
    },
  });
  const [isEditing, updateIsEditing] = useState(false);
  const [isSavingDetails, updateIsSavingDetails] = useState(false);
  const [isLoading, updateIsLoading] = useState(false);
  const [openAlertModal, updateOpenAlertModal] = useState(false);

  const [noResultsMessage, setNoResultsMessage] = useState(undefined);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const handleCloseModal = (e) => {
    const payload = {
      firstName: formRef.current.firstName.value,
      lastName: formRef.current.lastName.value,
      contactNumber: formRef.current.contactNumber.value,
      guardianName: formRef.current.guardianName.value,
      relationshipToPatient: formRef.current.relationshipToPatient.value,
    };

    if (!_.isEqual(formDefaultValues, payload)) {
      updateOpenAlertModal(true);
    } else {
      closeModal();
      onCancel();
    }
  };

  const actionsWithProps = utils.mapPropsToChilden(actions, {
    onClick: handleCloseModal,
    isLoading,
  });

  const getPatient = useCallback(
    async (id) => {
      updateIsLoading(true);

      try {
        const response = await patientService.getPatientById(id);

        // Save initial values to use on modal close
        formDefaultValues.firstName = response.firstName;
        formDefaultValues.lastName = response.lastName;
        formDefaultValues.guardianName = response.guardianName;
        formDefaultValues.relationshipToPatient =
          response.relationshipToPatient;
        formDefaultValues.contactNumber = response.contactNumber;

        response.reportedHeight = response.reportedHeight.map((item) => {
          return {
            id: item.id,
            fields: [
              moment(item.dateCreated).format("DD/MM/YYYY"),
              `${item.height} cm`,
              `${item.weight} kg`,
            ],
          };
        });

        updatePatient(response);
        updateIsLoading(false);
      } catch (err) {
        updateIsLoading(false);
        setNoResultsMessage(
          "Unable to load patient details. Please try again later."
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getPatient(id);
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const characterLimit = 246;

  const savePatient = async (payload) => {
    try {
      await patientService.patchPatient(id, payload);
      updateIsSavingDetails(false);
      updateIsEditing(false);
      await onComplete();
    } catch (err) {
      updateIsSavingDetails(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };

  const exportBiometrics = async () => {
    try {
      const url = await userService.downloadBiometrics(id);
      const link = document.createElement("a");

      if (typeof link.download === "undefined") {
        window.location.href = url;
      } else {
        link.href = url;
        link.setAttribute("download", patient.chiNumber);
        document.body.appendChild(link);
        link.click();
      }

      setTimeout(function () {
        URL.revokeObjectURL(url);
      }, 100); // cleanup
    } catch (err) {
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };

  const onSavePatient = async () => {
    updateOpenAlertModal(false);

    const payload = {
      firstName: formRef.current.firstName.value,
      lastName: formRef.current.lastName.value,
      contactNumber: formRef.current.contactNumber.value,
      guardianName: formRef.current.guardianName.value,
      relationshipToPatient: formRef.current.relationshipToPatient.value,
    };

    await savePatient(payload);

    closeModal();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!isEditing) {
      updateIsEditing(true);
      return null;
    }

    const payload = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      contactNumber: e.target.contactNumber.value,
      guardianName: e.target.guardianName.value,
      relationshipToPatient: e.target.relationshipToPatient.value,
    };

    await savePatient(payload);
  };

  let loadingStyles = {};
  if (isLoading) {
    loadingStyles = styles.loading;
  }

  let editingClasse = "";
  if (isEditing) {
    editingClasse = "inputWhite";
  }

  return (
    <Box sx={styles.panel}>
      <>
        <Box sx={styles.header}>
          <HeadingBold text="Patient Details" />
          {actionsWithProps}
        </Box>
        <div style={styles.divider} />
      </>
      {isLoading ? (
        <Box style={loadingStyles}>
          <Loader />
        </Box>
      ) : noResultsMessage ? (
        <Box style={styles.container}>
          <Paragraph>{noResultsMessage}</Paragraph>
        </Box>
      ) : (
        <form ref={formRef} onSubmit={onSubmit} style={styles.formFooter}>
          <Box style={styles.headerContainer}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Paragraph sx={styles.label}>CHI Number:</Paragraph>
              <Paragraph sx={styles.noMargin}>{patient.chiNumber}</Paragraph>
            </Box>
            <Button
              text={`${isEditing ? "Save Details" : "Edit Details"}`}
              naked
              type="submit"
              sx={{ paddingRight: 0 }}
              loading={isSavingDetails}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={styles.gridRow}>
              <InputField
                id="firstName"
                cy="firstName-field"
                label="First Name"
                disabled={!isEditing}
                required
                theme={editingClasse}
                defaultValue={patient.firstName}
                sx={{ ...styles.textFieldMargin }}
                placeholder="Enter patients first name"
                error={errorState.firstName ? true : false}
                helperText={errorState.firstName}
                inputProps={{
                  inputProps: {
                    maxLength: characterLimit,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={styles.gridRow}>
              <InputField
                id="lastName"
                cy="lastName-field"
                label="Last Name"
                defaultValue={patient.lastName}
                disabled={!isEditing}
                required
                theme={editingClasse}
                sx={{ ...styles.textFieldMargin }}
                placeholder="Enter patients last name"
                error={errorState.lastName ? true : false}
                helperText={errorState.lastName}
                inputProps={{
                  inputProps: {
                    maxLength: characterLimit,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={styles.gridRow}>
              <InputField
                id="guardianName"
                cy="guardian-field"
                label="Guardian Name"
                defaultValue={patient.guardianName}
                disabled={!isEditing}
                required
                theme={editingClasse}
                sx={{ ...styles.textFieldMargin }}
                placeholder="Enter name of patients guardian"
                error={errorState.guardianName ? true : false}
                helperText={errorState.guardianName}
                inputProps={{ maxLength: 246 }}
              />
            </Grid>

            <Grid item xs={12} md={6} style={styles.gridRow}>
              <InputField
                id="relationshipToPatient"
                cy="relationship-field"
                label="Relationship to Patient"
                defaultValue={patient.relationshipToPatient}
                disabled={!isEditing}
                required
                theme={editingClasse}
                sx={{ ...styles.textFieldMargin }}
                placeholder="e.g. mother"
                error={errorState.relationshipToPatient ? true : false}
                helperText={errorState.relationshipToPatient}
                inputProps={{
                  inputProps: {
                    maxLength: characterLimit,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} style={styles.gridRow}>
              <InputField
                id="contactNumber"
                cy="contact-field"
                label="Contact Number"
                defaultValue={patient.contactNumber}
                type="number"
                disabled={!isEditing}
                required
                theme={editingClasse}
                sx={{ ...styles.textFieldMargin }}
                placeholder="Enter preferred contact number"
                error={errorState.contactNumber ? true : false}
                helperText={errorState.contactNumber}
                inputProps={{
                  inputProps: {
                    min: 999999,
                    max: 999999999999999,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} style={styles.gridRow}>
              <Paragraph sx={styles.label}>Contact Email</Paragraph>
              <Paragraph sx={{ marginTop: "1.3rem" }}>
                {patient.email}
              </Paragraph>
            </Grid>

            {errorState.general && (
              <ErrorHelperText text={errorState.general} />
            )}

            {patient.oneTimePassword ? (
              <>
                <Grid item xs={12} style={styles.gridRow}>
                  <Paragraph sx={styles.label}>First Time Password</Paragraph>
                  <Paragraph sx={styles.subLabel}>
                    The patient can use this password during their induction
                    session to access the app.
                  </Paragraph>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ ...styles.gridRow, paddingTop: 0 }}
                >
                  <PasswordField
                    id="password"
                    cy="password-field"
                    placeholder="Enter your password"
                    defaultValue={patient.oneTimePassword}
                    disabled={true}
                  />
                </Grid>
              </>
            ) : null}

            {patient.nextAppointment.date ? (
              <>
                <Grid item xs={12} style={styles.gridSeparator} />
                <Grid item xs={12} md={6} style={styles.gridRow}>
                  <Paragraph sx={styles.label}>Next Appointment:</Paragraph>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Paragraph>
                      {patient.nextAppointment.time.split(" -")[0]}
                    </Paragraph>
                    <div style={styles.verticalSeparator} />
                    <Paragraph>{patient.nextAppointment.date}</Paragraph>
                    <div style={styles.verticalSeparator} />
                    <Paragraph>{`Session ${patient.nextAppointment.sessionNumber}`}</Paragraph>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} style={styles.gridRow}>
                  <Paragraph sx={styles.label}>Attendance Rate:</Paragraph>
                  <Paragraph>{`${patient.attendanceRate}%`}</Paragraph>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} style={styles.gridSeparator} />

            <Grid item xs={12} style={styles.gridReportedHeight}>
              <Paragraph sx={styles.label}>
                Self-reported Height/Weight
              </Paragraph>
              {patient.reportedHeight.length > 0 ? (
                <Button
                  text="Export"
                  naked
                  sx={{ paddingRight: 0 }}
                  onClick={exportBiometrics}
                />
              ) : null}
            </Grid>
            <Box
              style={{
                width: "100%",
                paddingLeft: "1rem",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <PatientBiometricsTable
                  data={patient.reportedHeight || []}
                  noResultsMessage={noResultsMessage}
                  tableFormat={tableFormat}
                />
              </Box>
            </Box>
          </Grid>
        </form>
      )}
      <Modal
        open={openAlertModal}
        onClose={() => updateOpenAlertModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal} data-cy="modal-content">
          <Box sx={styles.header}>
            <HeadingBold text="Attention!" />
            <CloseIcon
              onClick={() => updateOpenAlertModal(false)}
              style={styles.closeButton}
            />
          </Box>
          <div style={styles.divider} />
          <Box style={styles.container}>
            <Box>
              <Paragraph>
                You have made changes to this patients details. Would you like
                to save them?
              </Paragraph>
            </Box>
            <Box sx={styles.actions}>
              <Button
                text="Exit without saving"
                size="standard-bold"
                theme="primary"
                loading={isSavingDetails}
                sx={styles.marginRight}
                onClick={() => {
                  updateOpenAlertModal(false);
                  closeModal();
                  onCancel();
                }}
              />

              <Button
                loading={isSavingDetails}
                theme="primary"
                text="Save and exit"
                size="standard-bold"
                onClick={onSavePatient}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

PatchPatientForm.defaultProps = {
  actions: undefined,
  onClick: () => {},
  onComplete: () => {},
  onCancel: () => {},
  id: undefined,
};
PatchPatientForm.propTypes = {
  actions: PropTypes.element,
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
};

export default PatchPatientForm;
