import React from "react";
import styles from "./ForgottenPasswordForm.style";
import InputField from "components/core/form/Input/Input";
import Button from "components/core/buttons/Primary/Primary";

const ForgottenPasswordForm = ({ onComplete }) => {
  const onFormSubmit = async (e) => {
    e.preventDefault();
    onComplete(e.target.email.value);
  };

  return (
    <form style={styles.form} onSubmit={onFormSubmit}>
      <InputField
        id="email"
        cy="email-field"
        label="Email Address"
        type="email"
        required
        autoComplete="email"
        placeholder="Enter your email address"
        inputProps={{ maxLength: 246, autoComplete: "email" }}
        inputLabelProps={{ shrink: true }}
      />
      <Button type="submit" text="Send" style={styles.button} />
    </form>
  );
};

export default ForgottenPasswordForm;
