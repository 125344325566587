import stylesConfig from "theme/config";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
  },
  actionsContainer: {
    position: "absolute",
    right: 0,
    display: "flex",
    flexDirection: "row",
  },
  counterContainer: {
    backgroundColor: stylesConfig.colors.nero,
    color: stylesConfig.colors.white,
    height: 32,
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  closeContainer: {
    backgroundColor: "transparent",
    height: 32,
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  counter: {
    font: "normal normal normal 16px/19px Poppins",
    color: stylesConfig.colors.white,
    textAlign: "left",
    margin: 0,
    padding: 0,
  },
  chips: {
    flexWrap: "wrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "90%",
  },
  chip: {
    marginRight: 2,
    backgroundColor: stylesConfig.colors.standardGrey,
  },
  inputWhite: {
    backgroundColor: stylesConfig.colors.white,
  },
};

export default styles;
