module.exports = {
  // 1xx: General errors applicable throughout the system
  REQUIRED_FIELD_MISSING: 101,
  NOT_UNIQUE: 102,
  INCORRECT_DATE_ORDER: 103,
  BELOW_MIN_VALUE: 104,
  ABOVE_MAX_VALUE: 105,
  DUPLICATED_VALUE: 106,
  NOT_ARRAY_VALUE: 107,
  JSON_PARSE_ERROR: 108,
  UNKNOWN_ENUM_VALUE: 109,
  FORBIDDEN_RESOURCE: 110,
  INVALID_RESOURCE: 111,
  INCORRECT_FORMAT: 112,
  INVALID_EMAIL: 113,
  INACTIVE_RESOURCE: 114,
  FILE_TOO_LARGE: 120,
  LIMIT_EXCEEDED: 121,
  REQUEST_BODY_VALIDATION_ERROR: 122,
  REQUIRED_FIELD: 123,
  ORGANISATION_NAME_ALREADY_EXISTS: 124,
  INCORRECT_DATA_TYPE: 125,
  PARAM_ERROR: 126,
  USERS_BELONG_TO_DIFFFRENT_ORG: 127,
  NOT_PRIMARY_ORG_USER: 128,
  INVALID_TYPE: 129,
  THEME_LIMIT_ALREADY_REACHED: 130,
  THEME_DOES_NOT_BELONG_TO_CATEGORY: 131,
  QUESTIONNAIRE_HAS_ALREADY_BEEN_MADE_ACTIVE: 132,
  ANSWER_SET_ALREADY_EXISTS: 133,
  NO_QUESTIONS_IN_QUESTIONNAIRE: 134,
  ANSWER_ID_NOT_FOUND_IN_QUESTIONNARE: 135,
  ANSWER_SET_IS_NOT_COMPLETE: 136,
  // 4xx: Authentication and registration specific errors
  INVALID_CREDENTIALS: 401,
  INVALID_TOKEN: 401,
  PASSWORD_DOESNT_MEET_REQUIREMENTS: 402,
  // 5xx: Internal Server Errors
  INTERNAL_SERVER_ERROR: 500,
};
