import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "firstName") {
      return reducers.createFormErrorsHandler(
        "firstName",
        "First Name is a required field.",
        errorDispatch
      );
    }
    if (errorData.field === "lastName") {
      return reducers.createFormErrorsHandler(
        "lastName",
        "Last Name is a required field.",
        errorDispatch
      );
    }
    if (errorData.field === "chiNumber") {
      return reducers.createFormErrorsHandler(
        "chiNumber",
        errorData.message,
        errorDispatch
      );
    }
    if (errorData.field === "guardianName") {
      return reducers.createFormErrorsHandler(
        "guardianName",
        "Guardian Name is a required field.",
        errorDispatch
      );
    }
    if (errorData.field === "relationshipToPatient") {
      return reducers.createFormErrorsHandler(
        "relationshipToPatient",
        "Relationship to Patient is a required field.",
        errorDispatch
      );
    }
    if (errorData.field === "contactNumber") {
      return reducers.createFormErrorsHandler(
        "contactNumber",
        errorData.message,
        errorDispatch
      );
    }

    return reducers.createFormErrorsHandler(
      "general",
      "There was an issue when trying to update this patiente.",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
