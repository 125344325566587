import React, { useState, useReducer } from "react";
import { Grid, Box } from "@mui/material";
import reducers from "reducers/form-errors-reducer";
import InputField from "components/core/form/Input/Input";
import Button from "components/core/buttons/Primary/Primary";
import userService from "services/user/user-service";
import registrationService from "services/registration/registration-service";
import ReferenceDataSelect from "components/core/form/ReferenceDataSelect/ReferenceDataSelect";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import errorHandler from "./AddStaff.error.handler";
import HTTPError from "lib/errors/http-error";
import styles from "./AddStaff.styles";
import stylesConfig from "theme/config";

const intialErrorState = {
  firstName: null,
  lastName: null,
  email: null,
  role: null,
  general: null,
};

const AddStaffForm = ({ clodeModal, onComplete }) => {
  const [isSaving, updateIsSaving] = useState(false);
  const [addStaffDisabled, updateAddStaffDisabled] = useState(true);
  const [form, updateForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const characterLimit = 246;

  const getRoles = async () => {
    try {
      const roles = await userService.getRoles();
      return roles;
    } catch (err) {
      return [];
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      role: e.target.role.value,
    };

    try {
      updateIsSaving(true);
      await registrationService.createStaff(payload);
      updateIsSaving(false);
      await onComplete();
      clodeModal();
    } catch (err) {
      updateIsSaving(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };

  const isFormValid = (form) => {
    const { firstName, lastName, email, role } = form;

    if (firstName && lastName && email && role) {
      updateAddStaffDisabled(false);
    } else {
      updateAddStaffDisabled(true);
    }
  };

  const handleOnFormChange = (e) => {
    let newForm = {
      ...form,
      [e.target.name || e.target.id]: e.target.value,
    };

    isFormValid(newForm);
    updateForm(newForm);
  };

  return (
    <form onSubmit={onSubmit} style={styles.formFooter}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} style={styles.gridRow}>
          <InputField
            id="firstName"
            cy="firstName-field"
            label="First Name"
            required
            sx={styles.textFieldMargin}
            placeholder="Enter first name"
            error={errorState.firstName ? true : false}
            helperText={errorState.firstName}
            onChange={(e) => handleOnFormChange(e)}
            inputProps={{
              inputProps: {
                maxLength: characterLimit,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={styles.gridRow}>
          <InputField
            id="lastName"
            cy="lastName-field"
            label="Last Name"
            required
            sx={styles.textFieldMargin}
            placeholder="Enter last name"
            error={errorState.lastName ? true : false}
            helperText={errorState.lastName}
            onChange={(e) => handleOnFormChange(e)}
            inputProps={{
              inputProps: {
                maxLength: characterLimit,
              },
            }}
          />
        </Grid>

        <Grid item xs={12} style={styles.gridRow}>
          <InputField
            id="email"
            cy="email-field"
            label="Email Address"
            type="email"
            sx={{ marginBottom: "1rem" }}
            required
            autoComplete="email"
            placeholder="Enter email address"
            error={errorState.email ? true : false}
            helperText={errorState.email}
            onChange={(e) => handleOnFormChange(e)}
            inputProps={{
              inputProps: {
                maxLength: characterLimit,
                autoComplete: "email",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} style={styles.gridRow}>
          <ReferenceDataSelect
            disabled={false}
            label="Role"
            id="role"
            placeholder="Please select a role"
            service={getRoles}
            helperText={errorState.role}
            error={errorState.role ? true : false}
            sx={{ backgroundColor: stylesConfig.colors.standardGrey }}
            onChange={(e) => handleOnFormChange(e)}
          />
        </Grid>
      </Grid>

      {errorState.general && <ErrorHelperText text={errorState.general} />}

      <Box style={styles.footer}>
        <Button
          type="submit"
          text="Invite"
          variant="contained"
          style={styles.button}
          loading={isSaving}
          disabled={addStaffDisabled}
        />
      </Box>
    </form>
  );
};

export default AddStaffForm;
