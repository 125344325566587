import api from "../../lib/http";
import mediaApi from "lib/mediaHttp";

const getRoles = async () => {
  const result = await api("get", `user/roles`, null, true, true, true);
  return result.data;
};

const getUsers = async (
  pageNo = 0,
  pageSize = 10,
  search,
  order,
  orderBy,
  isActve,
  userType
) => {
  let pageQuery = `user?page=${pageNo}&pageSize=${pageSize}`;

  if (isActve !== undefined) {
    pageQuery = `${pageQuery}&filter=${isActve}`;
  }

  if (userType) {
    pageQuery = `${pageQuery}&userType=${userType}`;
  }

  if (search && search.name) {
    pageQuery = `${pageQuery}&name=${search.name}`;
  }

  if (search && search.stage) {
    pageQuery = `${pageQuery}&stage=${search.stage}`;
  }

  if (search && search.state) {
    pageQuery = `${pageQuery}&state=${search.state}`;
  }

  if (order && orderBy) {
    pageQuery = `${pageQuery}&property=${orderBy}&orderBy=${order}`;
  }

  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const deleteUserById = async (userId) => {
  const result = await api("delete", `user/${userId}`, null, true, true, true);
  return result.data;
};

const changeUserStatus = async (userId) => {
  const result = await api(
    "patch",
    `user/${userId}/status`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const getAttendeesBySessionNumber = async (
  sessionIdToSearch,
  currentSession
) => {
  const result = await api(
    "get",
    `user/attendees/${sessionIdToSearch}/currentSession/${currentSession}`,
    null,
    true,
    true,
    true
  );
  return result.data;
};

const changePassword = async (userId, password) => {
  const result = await api(
    "post",
    `/user/${userId}/password`,
    { newPassword: password },
    true,
    true,
    true
  );

  return result.data;
};

const verifyEmail = async (userId, email) => {
  const result = await api(
    "post",
    `/user/${userId}/verify-email`,
    { email },
    true,
    true,
    true
  );

  return result.data;
};

const changeEmailByRequest = async (token) => {
  const result = await api(
    "post",
    `/user/change-email-by-request`,
    { token },
    true,
    true,
    true
  );

  return result.data;
};

const downloadBiometrics = async (userId) => {
  const src = await mediaApi(`user/${userId}/download-biometrics`);
  return src;
};

const service = {
  getRoles,
  getUsers,
  deleteUserById,
  changeUserStatus,
  getAttendeesBySessionNumber,
  changePassword,
  verifyEmail,
  changeEmailByRequest,
  downloadBiometrics,
};
export default service;
