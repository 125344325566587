import stylesConfig from "theme/config";

const styles = {
  formFooter: {
    width: "100%",
    padding: stylesConfig.spacing.standard,
  },
  textFieldMargin: {
    marginBottom: "1rem",
  },
  footer: {
    paddingTop: stylesConfig.spacing.large,
    justifyContent: "flex-end",
    display: "flex",
  },
  gridRow: {
    display: "flex",
    flexDirection: "column",
  },
  gridSeparator: {
    backgroundColor: stylesConfig.colors.dividerGrey,
    padding: "0.01rem",
    marginLeft: stylesConfig.spacing.standard,
    marginTop: stylesConfig.spacing.small,
    marginBottom: stylesConfig.spacing.small,
  },
  actions: {
    marginTop: "auto",
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "flex-end",
  },
};

export default styles;
