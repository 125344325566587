const spacing = {
  verySmall: "0.25rem",
  small: "0.5rem",
  standard: "1rem",
  large: "1.5rem",
  veryLarge: "2rem",
  veryVeryLarge: "4rem",
};

export default spacing;
