import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import Paragraph from "components/core/typography/description/Description";
import IconButton from "@mui/material/IconButton";
import stylesConfig from "theme/config";

const PasswordInput = ({
  id,
  cy,
  label,
  variant,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  autoComplete,
  style,
  placeholder,
  defaultValue,
  theme,
  disabled,
}) => {
  const [showPassword, handleClickShowPassword] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const useStyles = makeStyles(() => ({
    inputWhite: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: stylesConfig.colors.white,
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Paragraph
        text={label}
        sx={{
          font: "normal normal bold 16px/19px Poppins",
          color: error ? stylesConfig.colors.red : stylesConfig.colors.black,
        }}
      />
      <TextField
        className={classes[theme]}
        style={style}
        id={id}
        helperText={helperText}
        error={error}
        type={showPassword ? "text" : "password"}
        data-cy={cy}
        required={required}
        placeholder={placeholder}
        variant={variant}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        sx={{
          marginBottom: "1rem",
          ...sx,
        }}
        autoComplete={autoComplete}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          ...inputProps,
        }}
      />
    </>
  );
};

PasswordInput.defaultProps = {
  variant: "outlined",
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: undefined,
  autoComplete: undefined,
  style: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  theme: undefined,
  disabled: false,
};

PasswordInput.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  style: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
};
export default PasswordInput;
