import stylesConfig from "theme/config";

const styles = {
  link: {
    textDecoration: "none",
    display: "inline-flex",
  },
  container: {
    display: "flex",
    alignItems: "center",
    color: stylesConfig.colors.black,
  },
  svg: {
    marginLeft: "-6px",
    height: "16px",
    color: stylesConfig.colors.primary,
  },
  text: {
    color: stylesConfig.colors.primary,
    font: stylesConfig.typography.paragraph.pDescription,
    fontWeight: "600",
  },
};

export default styles;
