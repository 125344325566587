import api from "../../lib/http";

const getDashboardMetrics = async function () {
  const result = await api(
    "get",
    `dashboard/metrics/home`,
    null,
    true,
    true,
    true
  );

  return result.data;
};

const service = {
  getDashboardMetrics,
};

export default service;
