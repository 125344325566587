import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import styles from "../Dropzone.style";
import stylesConfig from "theme/config";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import Paragraph from "components/core/typography/description/Description";

const DropFileZone = ({
  onDrop,
  fileNumber,
  dropLabel,
  accept,
  noClick,
  maxSize,
}) => {
  const dropHandler = (acceptedFiles, errors) => {
    onDrop(acceptedFiles, errors);
  };

  const DropLabel = ({ fileNumber }) => (
    <>
      <Paragraph sx={styles.label}>Select File</Paragraph>
      <Box
        sx={{
          ...styles.dropLabel,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: stylesConfig.spacing.standard,
        }}
      >
        <Paragraph>Please upload your PDF here.</Paragraph>
        <PrimaryButton
          theme="outline"
          disabled={fileNumber >= 1}
          style={styles.uploadFileButton}
        >
          Upload PDF
        </PrimaryButton>
      </Box>
    </>
  );

  return (
    <>
      <Dropzone
        onDrop={dropHandler}
        accept={accept}
        noClick={noClick}
        maxSize={maxSize}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <Box {...getRootProps()} sx={{ ...styles.fileDropzone }}>
              <input {...getInputProps()} />
              {dropLabel || <DropLabel fileNumber={fileNumber} />}
            </Box>
          </section>
        )}
      </Dropzone>
    </>
  );
};

DropFileZone.defaultProps = {
  fileNumber: 0,
  onDrop: () => {},
  dropLabel: undefined,
  noClick: false,
};

DropFileZone.propTypes = {
  fileNumber: PropTypes.number,
  accept: PropTypes.string,
  onDrop: PropTypes.func,
  dropLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noClick: PropTypes.bool,
};
export default DropFileZone;
