import reducers from "reducers/form-errors-reducer";
import errorCodes from "lib/error-codes";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "firstName") {
      return reducers.createFormErrorsHandler(
        "firstName",
        "First Name is a required field.",
        errorDispatch
      );
    }
    if (errorData.field === "lastName") {
      return reducers.createFormErrorsHandler(
        "lastName",
        "Last Name is a required field.",
        errorDispatch
      );
    }
    if (errorData.field === "role") {
      return reducers.createFormErrorsHandler(
        "role",
        errorData.message,
        errorDispatch
      );
    }
    if (errorData.field === "email") {
      if (errorData.code === errorCodes.NOT_UNIQUE) {
        return reducers.createFormErrorsHandler(
          "email",
          "An user already exists with this email. Please choose another.",
          errorDispatch
        );
      } else {
        return reducers.createFormErrorsHandler(
          "email",
          errorData.message,
          errorDispatch
        );
      }
    }
    return reducers.createFormErrorsHandler(
      "general",
      "There was an issue when trying to update this patiente.",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
