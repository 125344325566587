import stylesConfig from "theme/config";

const styles = {
  pdfIcon: {
    width: "35px",
    height: "30px",
    marginRight: stylesConfig.spacing.standard,
  },
  container: {
    width: "100%",
    height: 73,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    margin: 1,
    background: stylesConfig.colors.background,
    paddingRight: stylesConfig.spacing.standard,
    paddingLeft: stylesConfig.spacing.standard,
    boxShadow: stylesConfig.shadows.standard,
  },
  removeButton: {
    textDecoration: "underline",
    font: "normal normal normal 14px/19px Poppins",
  },
  remove: {
    margin: 0,
    ".MuiButtonBase-root": {
      background: stylesConfig.colors.white,
      "&:hover": {
        background: stylesConfig.colors.white,
      },
    },
  },
};

export default styles;
