import React, { useReducer, useState } from "react";
import reducers from "reducers/form-errors-reducer";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import styles from "./Delete.style";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import HTTPError from "lib/errors/http-error";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";

const intialErrorState = {
  general: "",
};

const DeleteForm = ({
  id,
  closeModal,
  onComplete,
  onCancel,
  errorHandler,
  service,
  heading,
  description,
  primaryActionText,
  cancelActionText,
}) => {
  const [loading, updateLoading] = useState(false);
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const onCancelHandler = () => {
    closeModal();
    onCancel();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    updateLoading(true);

    try {
      await service(id);
      updateLoading(false);
      await onComplete();
    } catch (err) {
      updateLoading(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };

  return (
    <form style={styles.container} onSubmit={onSubmit}>
      <Box commponent="h3" sx={styles.heading}>
        {heading}
      </Box>
      <Box sx={styles.description}>
        {description}
        {errorState.general && (
          <ErrorHelperText sx={styles.error} text={errorState.general} />
        )}
      </Box>

      <Box sx={styles.actions}>
        <PrimaryButton
          text={cancelActionText}
          size="standard-bold"
          theme="outline"
          loading={loading}
          onClick={onCancelHandler}
          style={styles.cancelButton}
        />

        <PrimaryButton
          loading={loading}
          type="submit"
          theme="primary"
          text={primaryActionText}
          size="standard-bold"
        />
      </Box>
    </form>
  );
};
DeleteForm.defaultProps = {
  description: undefined,
  onClick: () => {},
  onComplete: () => {},
  onCancel: () => {},
  errorHandler: () => {},
  id: undefined,
  primaryActionText: "Remove",
  cancelActionText: "Cancel",
};
DeleteForm.propTypes = {
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
  errorHandler: PropTypes.func,
  description: PropTypes.element,
  primaryActionText: PropTypes.string,
  cancelActionText: PropTypes.string,
};
export default DeleteForm;
