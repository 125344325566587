import React from "react";
import ArrowBlack from "../../../../assets/arrow_sort_black_24.svg";
import ArrowBlue from "../../../../assets/arrow_sort_blue_24.svg";
import Img from "components/core/img/Img";
import styles from "./ArrowDrop.style";

const ArrowDrop = (props) => {
  return (
    <div
      {...props.innerProps}
      className={`${styles.dropdown} ${
        props.propertyName === props.hover || props.active
          ? styles.show
          : styles.hide
      }`}
    >
      <Img
        alt="sort arrow up"
        style={styles.arrowDown}
        //src={ArrowBlack}
        src={props.active && props.direction === "asc" ? ArrowBlue : ArrowBlack}
      />
      <Img
        alt="sort arrow down"
        style={styles.arrowUp}
        src={
          props.active && props.direction === "desc" ? ArrowBlue : ArrowBlack
        }
      />
    </div>
  );
};

export default ArrowDrop;
