import React, { useState, useReducer, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import reducers from "reducers/form-errors-reducer";
import InputField from "components/core/form/Input/Input";
import Button from "components/core/buttons/Primary/Primary";
import ReferenceDataSelect from "components/core/form/ReferenceDataSelect/ReferenceDataSelect";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import HTTPError from "lib/errors/http-error";
import UploadImagesForm from "components/forms/upload-images/UploadImages";
import UploadFileForm from "components/forms/upload-file/UploadFile";
import contentService from "services/content/content-service";
import sessionsService from "services/sessions/sessions-service";
import errorHandler from "./UploadContent.error.handler";
import styles from "./UploadContent.styles";
import stylesConfig from "theme/config";

const intialErrorState = {
  title: null,
  sessionNumber: null,
  sortOrder: null,
  general: null,
};

const UploadContent = ({ closeModal, onComplete }) => {
  const [isSavingContent, updateIsSavingContent] = useState(false);
  const [isFromValid, updateIsFromValid] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [shouldGetSessions, updateShouldGetSessions] = useState(true);
  const [coverImage, updateCoverImage] = useState("");
  const [files, updateFiles] = useState("");
  const [form, updateForm] = useState({
    title: "",
    sessionNumber: 0,
    sortOrder: null,
  });
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const characterLimit = 246;

  const getSessions = async () => {
    if (!shouldGetSessions) return sessions;

    const sessionsResponse = await sessionsService.getAllSessions();
    if (sessionsResponse) {
      setSessions(sessionsResponse);
    }

    updateShouldGetSessions(false);
  };

  useEffect(() => {
    isFormValid(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverImage, files]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const { title, sessionNumber, sortOrder } = form;

    const formData = new FormData();
    formData.append("files", coverImage[0]);
    formData.append("files", files[0]);
    formData.append("title", title);
    formData.append("sessionNumber", sessionNumber);
    formData.append("sortOrder", sortOrder || 0);

    try {
      updateIsSavingContent(true);
      await contentService.createContent(formData);
      updateIsSavingContent(false);
      closeModal();
      await onComplete();
    } catch (err) {
      updateIsSavingContent(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };

  const isFormValid = (form) => {
    const { title, sessionNumber } = form;
    if (title && sessionNumber && coverImage.length > 0 && files.length > 0) {
      updateIsFromValid(true);
    } else {
      updateIsFromValid(false);
    }
  };

  const handleOnFormChange = (e) => {
    let newForm = {
      ...form,
      [e.target.name || e.target.id]: e.target.value,
    };

    isFormValid(newForm);
    updateForm(newForm);
  };

  return (
    <>
      <form onSubmit={onSubmit} style={styles.formFooter}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={styles.gridRow}>
            <UploadImagesForm
              updateCoverImage={updateCoverImage}
              onChange={(e) => handleOnFormChange(e)}
            />
          </Grid>
          <Grid item xs={12} style={styles.gridRow}>
            <InputField
              id="title"
              name="title"
              cy="title-field"
              label="Title"
              required
              sx={styles.textFieldMargin}
              placeholder="Add content title"
              error={errorState.date ? true : false}
              helperText={errorState.date}
              onChange={(e) => handleOnFormChange(e)}
              inputProps={{
                inputProps: {
                  maxLength: characterLimit,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={7} style={styles.gridRow}>
            <ReferenceDataSelect
              disabled={false}
              label="Add to Session"
              subLabel="Select the session you want to add this content to."
              id="sessionNumber"
              placeholder="Select a session"
              service={getSessions}
              helperText={errorState.sessionNumber}
              error={errorState.sessionNumber ? true : false}
              sx={{ backgroundColor: stylesConfig.colors.standardGrey }}
              onChange={(e) => handleOnFormChange(e)}
            />
          </Grid>
          <Grid item xs={12} style={styles.gridRow}>
            <InputField
              id="sortOrder"
              name="sortOrder"
              label="Sort Order"
              type="number"
              theme="inputWhite"
              sx={styles.textFieldMargin}
              placeholder="Enter number from -255 to 255. No decimals allowed"
              error={errorState.date ? true : false}
              helperText={errorState.date}
              onChange={(e) => {
                handleOnFormChange(e);
              }}
              inputProps={{
                inputProps: {
                  min: -255,
                  max: 255,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} style={styles.gridRow}>
            <UploadFileForm saveFiles={updateFiles} />
          </Grid>
        </Grid>

        {errorState.general && <ErrorHelperText text={errorState.general} />}

        <Box style={styles.footer}>
          <Button
            type="submit"
            text="Save"
            variant="contained"
            style={styles.button}
            loading={isSavingContent}
            disabled={!isFromValid}
          />
        </Box>
      </form>
    </>
  );
};

export default UploadContent;
