import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Img from "components/core/img/Img";
import style from "./BasicImageList.style";
import Remove from "components/core/buttons/Remove/Remove";

const BasicImageList = ({ items, styles, onClick, errorStyle }) => {
  return (
    <>
      {items.map((item, index) => (
        <Box key={index} sx={style.container}>
          <Img
            src={`${item.src}`}
            srcSet={`${item.src}`}
            alt={item.alt}
            loading="lazy"
            style={{ ...style.img, ...styles }}
            errorStyle={errorStyle}
          />
          <Box sx={style.removeContainer}>
            <Remove onClick={() => onClick(index)} sx={style.remove} />
          </Box>
        </Box>
      ))}
    </>
  );
};

BasicImageList.defaultProps = {
  items: [],
  styles: {},
  onClick: () => {},
  errorStyle: {},
};
BasicImageList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  styles: PropTypes.shape({}),
  onClick: PropTypes.func,
  errorStyle: PropTypes.shape({}),
};
export default BasicImageList;
