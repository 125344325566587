import stylesConfig from "theme/config";

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: stylesConfig.colors.white,
    boxShadow: stylesConfig.shadows.standard,
    borderRadius: "8px",
    paddingTop: 2,
    paddingBottom: 2,
    width: {
      xs: "calc(100% - 20px)",
      md: "744px",
    },
  },
  header: {
    display: "flex",
    marginBottom: stylesConfig.spacing.small,
    paddingLeft: 2,
    paddingRight: 2,
    alignItems: "center",
  },
  modalCloseButton: {
    marginLeft: "auto",
    color: stylesConfig.colors.primary,
    fontSize: "30px",
  },
  divider: {
    width: "100%",
    height: "2px",
    backgroundColor: "#DEE2E4",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
  },
  tableFilter: {
    minHeight: 89,
    background: stylesConfig.colors.white,
    marginBottom: stylesConfig.spacing.large,
    borderRadius: 10,
    display: "flex",
    padding: stylesConfig.spacing.standard,
  },
  filterButtonContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  filterButton: {
    color: stylesConfig.colors.black,
    fontWeight: 300,
  },
  filterInputLable: {
    color: stylesConfig.colors.primary,
  },
  fitlerDropbox: {
    ":before": {
      borderBottom: `2px solid ${stylesConfig.colors.primary}`,
    },
    ":after": {
      borderBottom: `2px solid ${stylesConfig.colors.primary}`,
    },
  },
};

export default styles;
