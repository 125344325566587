import stylesConfig from "theme/config";

const styles = {
  formFooter: {
    width: "100%",
    padding: stylesConfig.spacing.standard,
  },
  textFieldMargin: {
    marginBottom: "1rem",
  },
  footer: {
    paddingTop: stylesConfig.spacing.large,
    justifyContent: "flex-end",
    display: "flex",
  },
  gridRow: {
    display: "flex",
    flexDirection: "column",
  },
  gridReportedHeight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gridSeparator: {
    backgroundColor: stylesConfig.colors.dividerGrey,
    padding: "0.01rem",
    marginLeft: stylesConfig.spacing.standard,
    marginTop: stylesConfig.spacing.small,
    marginBottom: stylesConfig.spacing.small,
  },
  label: {
    color: stylesConfig.colors.black,
    font: "normal normal bold 16px/19px Poppins",
    margin: 0,
    marginRight: stylesConfig.spacing.small,
  },
  subLabel: {
    color: stylesConfig.colors.darkGrey,
    font: "normal normal normal 16px/19px Poppins",
    margin: 0,
    marginTop: stylesConfig.spacing.small,
  },
  noMargin: {
    margin: 0,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: stylesConfig.spacing.standard,
  },
  inputwhite: {
    backgroundColor: stylesConfig.colors.white,
    bordercolor: "red",
  },
  header: {
    display: "flex",
    marginBottom: stylesConfig.spacing.small,
    paddingLeft: 2,
    paddingRight: 2,
    alignItems: "center",
  },
  closeButton: {
    marginLeft: "auto",
    color: stylesConfig.colors.primary,
    fontSize: "30px",
  },
  divider: {
    width: "100%",
    height: "2px",
    backgroundColor: "#DEE2E4",
  },
  verticalSeparator: {
    width: 2,
    height: "50%",
    backgroundColor: "#DEE2E4",
    marginRight: stylesConfig.spacing.small,
    marginLeft: stylesConfig.spacing.small,
    marginBottom: 12,
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: stylesConfig.colors.white,
    boxShadow: stylesConfig.shadows.standard,
    borderRadius: "8px",
    paddingTop: 2,
    paddingBottom: 2,
    width: {
      xs: "calc(100% - 20px)",
      md: "744px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: stylesConfig.spacing.standard,
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    paddingTop: stylesConfig.spacing.standard,
  },
  marginRight: {
    marginRight: stylesConfig.spacing.veryLarge,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
  },
};

export default styles;
