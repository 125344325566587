import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Paragraph from "components/core/typography/description/Description";
import stylesConfig from "theme/config";

const TextInputField = ({
  id,
  cy,
  label,
  subLabel,
  variant,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  type,
  defaultValue,
  disabled,
  autoComplete,
  value,
  multiline,
  placeholder,
  theme,
}) => {
  const useStyles = makeStyles(() => ({
    inputWhite: {
      "& .MuiOutlinedInput-root": {
        backgroundColor: stylesConfig.colors.white,
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div style={{ display: "flex" }}>
        <Paragraph
          text={label}
          sx={{
            font: "normal normal bold 16px/19px Poppins",
            color: error ? stylesConfig.colors.red : stylesConfig.colors.black,
            marginBottom: "0.7rem",
          }}
        />
        {subLabel && (
          <Paragraph
            text={subLabel}
            sx={{
              font: "normal normal normal 16px/19px Poppins",
              color: error
                ? stylesConfig.colors.red
                : stylesConfig.colors.black,
            }}
          />
        )}
      </div>
      <TextField
        id={id}
        className={classes[theme]}
        defaultValue={defaultValue}
        helperText={helperText}
        error={error}
        autoComplete={autoComplete}
        data-cy={cy}
        value={value}
        placeholder={placeholder}
        required={required}
        InputProps={{
          ...inputProps,
        }}
        variant={variant}
        onChange={onChange}
        sx={{
          marginBottom: "3rem",
          ...sx,
        }}
        type={type}
        disabled={disabled}
        multiline={multiline}
      />
    </>
  );
};

TextInputField.defaultProps = {
  variant: "outlined",
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: undefined,
  type: "text",
  defaultValue: undefined,
  disabled: false,
  autoComplete: undefined,
  value: undefined,
  multiline: false,
  placeholder: undefined,
  theme: undefined,
};

TextInputField.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  theme: PropTypes.string,
};
export default TextInputField;
