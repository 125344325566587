import stylesConfig from "theme/config";

const styles = {
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
  },
  table: {
    tableContainer: {
      background: stylesConfig.colors.background,
    },
  },
  filterButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  filterSearchContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    background: stylesConfig.colors.white,
    paddingLeft: stylesConfig.spacing.standard,
    paddingRight: stylesConfig.spacing.standard,
    marginLeft: stylesConfig.spacing.veryVeryLarge,
    borderRadius: 10,
  },
  filterButton: {
    color: stylesConfig.colors.black,
    fontWeight: 300,
  },
  filterInputLable: {
    color: stylesConfig.colors.primary,
  },
  fitlerDropbox: {
    ":before": {
      borderBottom: `2px solid ${stylesConfig.colors.primary}`,
    },
    ":after": {
      borderBottom: `2px solid ${stylesConfig.colors.primary}`,
    },
  },
  filterButtonUnderLine: {
    background: stylesConfig.colors.primary,
    height: 2,
    width: "100%",
  },
  bold: {
    fontWeight: 600,
  },
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 89,
    paddingLeft: stylesConfig.spacing.standard,
    paddingRight: "7rem",
    background: stylesConfig.colors.white,
    borderRadius: 10,
  },
  tableFilter: {
    // background: stylesConfig.colors.white,
    marginBottom: stylesConfig.spacing.large,
    borderRadius: 10,
    display: "flex",
    // padding: stylesConfig.spacing.standard,
  },
};

export default styles;
