import stylesConfig from "theme/config";

const drawerWidth = 352;
const styles = {
  header: {
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingBottom: stylesConfig.spacing.veryVeryLarge,
  },
  appBar: {
    width: { sm: `calc(100% - ${drawerWidth}px)` },
    ml: { sm: `${drawerWidth}px` },
    background: stylesConfig.colors.background,
    boxShadow: "unset",
  },
  drawerContainer: {
    width: { sm: drawerWidth },
    flexShrink: { sm: 0 },
  },
  drawerDesktop: {
    display: { xs: "none", sm: "block" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      background: stylesConfig.colors.white,
      width: drawerWidth,
      border: 0,
    },
  },
  drawerMobile: {
    display: { xs: "block", sm: "none" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      background: stylesConfig.colors.white,
      width: drawerWidth,
      border: 0,
    },
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    bottom: 0,
    textAlign: "left",
    paddingLeft: "3rem",
    paddingBottom: { xs: 0, md: stylesConfig.spacing.small },
    background: stylesConfig.colors.white,
    width: drawerWidth,
  },
  margin: {
    marginTop: { xs: 0, md: stylesConfig.spacing.veryLarge },
  },
  myAccount: {
    fontWeight: 300,
    color: stylesConfig.colors.nero,
  },
  version: {
    font: "normal normal normal 14px/45px Poppins",
    paddingRight: stylesConfig.spacing.small,
  },
  bold: {
    font: "normal normal bold 14px/45px Poppins",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: stylesConfig.colors.white,
    boxShadow: stylesConfig.shadows.standard,
    borderRadius: "8px",
    paddingTop: stylesConfig.spacing.large,
    paddingBottom: stylesConfig.spacing.large,
    width: {
      xs: "calc(100% - 20px)",
      md: "744px",
    },
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: stylesConfig.spacing.large,
    paddingLeft: stylesConfig.spacing.large,
    textAlign: "center",
  },
  modalActions: {
    paddingTop: stylesConfig.spacing.standard,
  },
  modalCheck: {
    color: stylesConfig.colors.success,
    fontSize: "48px",
    marginLeft: "-6px",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 2,
    paddingRight: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  modalChangePasswordHeader: {
    display: "flex",
    marginBottom: stylesConfig.spacing.small,
    paddingLeft: 2,
    paddingRight: 2,
    alignItems: "center",
  },
  divider: {
    width: "100%",
    height: "2px",
    backgroundColor: "#DEE2E4",
  },
  closeButton: {
    marginLeft: "auto",
    color: stylesConfig.colors.primary,
    fontSize: "30px",
  },
  appNameText: {
    font: "normal normal bold 16px/16px Poppins",
    color: stylesConfig.colors.nero,
    margin: 0,
    padding: 0,
  },
};

export default styles;
