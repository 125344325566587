import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import stylesConfig from "theme/config";

const Paragraph = ({ text, cy, children, sx }) => {
  return (
    <Typography
      component="p"
      variant="p"
      data-cy={cy}
      sx={{
        font: "normal normal normal 16px/25px Poppins",
        letterSpacing: "0px",
        color: stylesConfig.colors.black,
        marginBottom: "1rem",
        ...sx,
      }}
    >
      {text}
      {children}
    </Typography>
  );
};

Paragraph.defaultProps = {
  cy: "paragraph",
  sx: undefined,
};

Paragraph.propTypes = {
  text: PropTypes.string,
  cy: PropTypes.string,
  sx: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default Paragraph;
