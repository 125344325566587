import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { Box } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { RegistrationResponseWidget } from "../../../components/registration/registration-response-widget/RegistrationResponseWidget";
import LoginLayout from "components/layouts/login-layout/LoginLayout";
import styles from "./CompleteRegistration.style";
export const RegistrationCompletePage = () => {
  const [signInLink, updateSignInLink] = useState("/login");
  const location = useLocation();

  useEffect(() => {
    const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (qsToken.signInLink) {
      updateSignInLink(qsToken.signInLink);
    }
  }, [updateSignInLink, location.search]);

  return (
    <LoginLayout
      content={
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4rem",
            marginRight: 34,
          }}
          sx={{ minWidth: { lg: "400px" } }}
        >
          <RegistrationResponseWidget
            heading="Registration Complete"
            bodyText="Your password has been set successfully."
            Icon={() => (
              <CheckCircleOutlineOutlinedIcon sx={styles.iconGreen} />
            )}
            href={signInLink}
          />
        </Box>
      }
    />
  );
};
