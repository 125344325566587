import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "email") {
      return reducers.createFormErrorsHandler(
        "email",
        errorData.message,
        errorDispatch
      );
    }

    return reducers.createFormErrorsHandler(
      "general",
      "There was an issue when trying to upade the email.",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
