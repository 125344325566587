import stylesConfig from "theme/config";

const styles = {
  deleteButtonStyle: {
    color: stylesConfig.colors.red,
    padding: "0.5rem",
    backgroundColor: "transparent",
    border: "none",
    textAlign: "left",
    minWidth: "auto",
    width: "auto",
    height: "auto",
    font: "font: normal normal bold 16px/25px Poppins",
    "&:hover": { cursor: "pointer" },
  },
  primaryButtonStyle: {
    color: stylesConfig.colors.primary,
    padding: "0.5rem",
    backgroundColor: "transparent",
    border: "none",
    textAlign: "left",
    minWidth: "auto",
    width: "auto",
    height: "auto",
    font: "font: normal normal bold 16px/25px Poppins",
    "&:hover": { cursor: "pointer" },
  },
  editButtonStyle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem",
    textDecoration: "none",
    padding: "0.5rem",
    backgroundColor: "white",
    "&:hover": { cursor: "pointer" },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: stylesConfig.colors.white,
    boxShadow: stylesConfig.shadows.standard,
    borderRadius: "8px",
    paddingTop: 2,
    paddingBottom: 2,
    overflow: "scroll",
    maxHeight: "calc(100% - 20px)",
    width: {
      xs: "calc(100% - 20px)",
      md: "744px",
    },
  },
  header: {
    display: "flex",
    marginBottom: stylesConfig.spacing.small,
    paddingLeft: 2,
    paddingRight: 2,
    alignItems: "center",
  },
  closeButton: {
    marginLeft: "auto",
    color: stylesConfig.colors.primary,
    fontSize: "30px",
    "&:hover": { cursor: "pointer" },
  },
  divider: {
    width: "100%",
    height: "2px",
    backgroundColor: "#DEE2E4",
  },
  padding: {
    padding: stylesConfig.spacing.standard,
    paddingTop: stylesConfig.spacing.large,
  },
  margin: {
    paddingRight: stylesConfig.spacing.standard,
    paddingLeft: stylesConfig.spacing.standard,
  },
};

export default styles;
