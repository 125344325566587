import api from "../../lib/http";

const completeRegistration = async (token, password) => {
  const result = await api(
    "post",
    "registration/complete-registration",
    { token, password },
    true
  );
  return result.data;
};

const validateToken = async (token) => {
  const result = await api(
    "get",
    `registration/validate-token?token=${token}`,
    null,
    true
  );
  return result.data;
};

const resendRegistrationEmail = async (id) => {
  const result = await api(
    "post",
    "registration/resend-registration-email",
    {
      id,
    },
    true,
    true,
    true
  );
  return result.data;
};

const createPatient = async (payload) => {
  const result = await api(
    "post",
    "registration/create-patient",
    payload,
    false,
    true,
    true
  );

  return result;
};

const createStaff = async (payload) => {
  const result = await api(
    "post",
    "registration/user",
    payload,
    true,
    true,
    true
  );

  return result;
};

const registrationService = {
  completeRegistration,
  validateToken,
  resendRegistrationEmail,
  createPatient,
  createStaff,
};

export default registrationService;
