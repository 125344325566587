import PoppinsBlack from "fonts/Poppins/Poppins-Black.ttf";
import PoppinsBlackItalic from "fonts/Poppins/Poppins-BlackItalic.ttf";
import PoppinsdBold from "fonts/Poppins/Poppins-Bold.ttf";
import PoppinsBoldItalic from "fonts/Poppins/Poppins-BoldItalic.ttf";
import PoppinsLightItalic from "fonts/Poppins/Poppins-LightItalic.ttf";
import PoppinsLight from "fonts/Poppins/Poppins-Light.ttf";
import PoppinsMedium from "fonts/Poppins/Poppins-Medium.ttf";
import PoppinsMediumItalic from "fonts/Poppins/Poppins-MediumItalic.ttf";

const fonts = {
  fontFamily: [
    '"Poppins"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontFace: `
       @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 300;
          src: local(''),
                url(${PoppinsLight}) format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: italic;
          font-weight: 300;
          src: local(''),
                url(${PoppinsLightItalic}) format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          src: local(''),
                url(${PoppinsMedium}) format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          src: local(''),
                url(${PoppinsMedium}) format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: italic;
          font-weight: 500;
          src: local(''),
                url(${PoppinsMediumItalic}) format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          src: local(''),
                url(${PoppinsdBold}) format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: italic;
          font-weight: 600;
          src: local(''),
                url(${PoppinsBoldItalic}) format('opentype');
        }
         @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          src: local(''),
                url(${PoppinsBlack}) format('opentype');
        }
        @font-face {
          font-family: 'Poppins';
          font-style: italic;
          font-weight: 700;
          src: local(''),
                url(${PoppinsBlackItalic}) format('opentype');
        }
        `,
};

export default fonts;
