import api from "../../lib/http";

const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

const createContent = async (payload) => {
  const result = await api(
    "post",
    `/content`,
    payload,
    true,
    true,
    true,
    config
  );

  return result.data;
};

const updateContent = async (payload, content_id, config) => {
  const result = await api(
    "patch",
    `/content/${content_id}`,
    payload,
    true,
    true,
    true,
    config
  );

  return result.data;
};

const getContents = async (
  pageNo = 0,
  pageSize = 10,
  search = {},
  order,
  orderBy
) => {
  let pageQuery = `content?page=${pageNo}&pageSize=${pageSize}&isArchived=${search.isArchived}`;

  if (search.session) {
    pageQuery = `${pageQuery}&sessionNumber=${search.session}`;
  }

  if (search.name) {
    pageQuery = `${pageQuery}&name=${search.name}`;
  }

  if (order && orderBy) {
    pageQuery = `${pageQuery}&property=${orderBy}&orderBy=${order}`;
  }

  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const deleteContentById = async (contentId) => {
  const result = await api(
    "delete",
    `content/${contentId}`,
    null,
    true,
    true,
    true
  );
  return result.data;
};

const archiveContentById = async (contentId) => {
  const result = await api(
    "post",
    `content/${contentId}/archive`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const unArchiveContentById = async (contentId) => {
  const result = await api(
    "post",
    `content/${contentId}/un-archive`,
    {},
    true,
    true,
    true
  );
  return result.data;
};

const getContentById = async (contentId) => {
  const result = await api(
    "get",
    `content/${contentId}`,
    null,
    true,
    true,
    true
  );
  return result.data;
};

const service = {
  createContent,
  getContents,
  deleteContentById,
  archiveContentById,
  unArchiveContentById,
  getContentById,
  updateContent,
};
export default service;
