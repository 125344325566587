import reducers from "reducers/form-errors-reducer";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;
    console.log(errorData);

    if (errorData.field === "email") {
      return reducers.createFormErrorsHandler(
        "email",
        errorData.message,
        errorDispatch
      );
    }

    if (errorData.field === "firstName") {
      return reducers.createFormErrorsHandler(
        "firstName",
        errorData.message,
        errorDispatch
      );
    }

    if (errorData.field === "lastName") {
      return reducers.createFormErrorsHandler(
        "lastName",
        errorData.message,
        errorDispatch
      );
    }

    return reducers.createFormErrorsHandler(
      "general",
      "There was an issue when trying to save the changes.",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
