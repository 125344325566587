import api from "../../lib/http";

const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

const createSession = async (payload) => {
  const result = await api(
    "post",
    `/sessions`,
    payload,
    true,
    true,
    true,
    config
  );

  return result.data;
};

const updateSession = async (sessionTitle, session, config) => {
  const payload = { sessionTitle };
  const result = await api(
    "patch",
    `/sessions/${session}`,
    payload,
    true,
    true,
    true,
    config
  );

  return result.data;
};

const getSessions = async (pageNo = 0, pageSize = 10) => {
  let pageQuery = `sessions?page=${pageNo}&pageSize=${pageSize}`;
  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const getAllSessions = async () => {
  const result = await api("get", "sessions", null, true, true, true);
  const formattedSessions = result.data.sessions.map((session) => ({
    value: session.session,
    label: session.sessionTitle,
    previousValue: session.session === 1 ? null : session.session - 1,
  }));
  return formattedSessions;
};

const getSessionBySesssionNumber = async (sessionNumber) => {
  let pageQuery = `sessions/${sessionNumber}`;
  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const service = {
  createSession,
  updateSession,
  getSessions,
  getSessionBySesssionNumber,
  getAllSessions,
};
export default service;
