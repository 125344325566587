import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import AppointmentsTable from "components/appointments/appointmentsTable/AppointmentsTable";
import AddAppointmentForm from "components/forms/appointment/add-an-appointment/AddAppointment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import Loader from "components/core/loaders/circular/Circular";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import appoitmentsService from "services/appointments/appointments-service";
import sessionsService from "services/sessions/sessions-service";
import AppointmentOptions from "components/data-display/Table/Options/AppointmentsOptions/AppointmentsOptions";
import ViewAppointmentsOptions from "components/data-display/Table/Options/ViewAppointmentsOptions/ViewAppointmentsOptions";

import styles from "./appointments.styles";

const tableFormat = [
  { id: "date", label: "Date", allowSort: true },
  { id: "time", label: "Time", allowSort: false },
  { id: "session", label: "Session", allowSort: false },
  { id: "attendees", label: "Attendees", allowSort: false },
];

const ROWS_PER_PAGE = 10;

const Appointments = () => {
  const [appointments, updateAppointments] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [isModalClosed, updateCloseModal] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [filter, updateFilter] = useState({
    session: "all",
    attenddes: "",
    date: "",
    key: "",
  });

  const [noResultsMessage, setNoResultsMessage] = useState(
    "There are currently no appointments."
  );
  // Pagination state
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);

  // Sorting
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");

  const closeModal = () => updateCloseModal(true);

  const getAppointments = useCallback(
    async (nextPage, sOrder = order, sOrderBy = orderBy, search = filter) => {
      const pageToSearch = nextPage || 0;
      const sessionToSearch = search.session === "all" ? null : search.session;

      const sSearch = {
        ...search,
        session: sessionToSearch,
      };

      const response = await appoitmentsService.getAppointments(
        pageToSearch,
        rowsPerPage,
        sOrder,
        sOrderBy,
        sSearch
      );

      updateLoading(false);

      if (!response || response.message) {
        setNoResultsMessage("No Results from API");
        return;
      }

      setPage(response.page);
      setTotal(response.total);
      setRowsPerPage(ROWS_PER_PAGE);

      const appointmentsArray = response.results.map((appointment) => {
        return {
          id: appointment.id,
          fields: [
            appointment.date,
            appointment.time,
            appointment.sessionTitle || appointment.sessionNumber,
            appointment.attendees,
            {
              Component: () => (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <ViewAppointmentsOptions
                    data={appointment}
                    onComplete={() => {
                      getAppointments(page);
                    }}
                  />
                  <AppointmentOptions
                    data={appointment}
                    onComplete={() => {
                      getAppointments(page);
                    }}
                  />
                </div>
              ),
            },
          ],
        };
      });

      const sessionsResponse = await sessionsService.getAllSessions();
      if (sessionsResponse) {
        setSessions(sessionsResponse);
      }

      updateAppointments(appointmentsArray);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getAppointments(page);
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);

    await getAppointments(page, newOrder, property);
  };

  const handleAddAppointment = () => {
    updateFilter({
      session: "all",
      attenddes: "",
      date: "",
      key: "",
    });

    setPage(0);
    getAppointments(0);
  };

  const handleFilterChange = (e) => {
    const newOrder = "asc";
    const newOrderBy = "date";

    const newFilter = {
      ...filter,
      key: Math.random(),
      [e.target.id]: e.target.value,
    };

    updateFilter(newFilter);

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    getAppointments(0, newOrder, newOrderBy, newFilter);
  };

  let loadingStyles = {};
  if (loading) {
    loadingStyles = styles.loading;
  }

  return (
    <>
      <LoggedIn
        smallHeading="Appointments"
        buttonModal={{
          title: "Add Appointment",
          modalContent: (
            <AddAppointmentForm
              clodeModal={closeModal}
              onComplete={handleAddAppointment}
            />
          ),
          isModalClosed: isModalClosed,
        }}
      >
        <Box sx={{ ...styles.panel, ...loadingStyles }}>
          {loading ? (
            <Loader />
          ) : (
            <Box>
              <Box sx={{ flexGrow: 1 }}>
                <Box style={styles.tableFilter}>
                  <div style={styles.filterButtonContainer}>
                    <>
                      <TextField
                        id="attenddes"
                        sx={{ minWidth: 400 }}
                        label="Search Attendees"
                        variant="standard"
                        value={filter.attenddes}
                        focused
                        onChange={handleFilterChange}
                      />
                      <TextField
                        id="date"
                        type="date"
                        label="Date"
                        placeholder=""
                        variant="standard"
                        value={filter.date}
                        focused
                        onChange={handleFilterChange}
                      />
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            sx={styles.filterInputLable}
                          >
                            Session
                          </InputLabel>
                          <NativeSelect
                            sx={styles.fitlerDropbox}
                            focused
                            value={filter.session}
                            onChange={handleFilterChange}
                            inputProps={{
                              name: "session",
                              id: "session",
                            }}
                          >
                            <option value="all">All</option>
                            {sessions.map((item) => (
                              <option value={item.value}>
                                {item.label || item.value}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    </>

                    <PrimaryButton
                      text="Clear Filter"
                      theme="outline"
                      sx={styles.filterButton}
                      onClick={handleAddAppointment}
                    />
                  </div>
                </Box>
                <AppointmentsTable
                  key={filter.key}
                  page={page}
                  total={total}
                  order={order}
                  orderBy={orderBy}
                  rowsPerPage={rowsPerPage}
                  data={appointments}
                  noResultsMessage={noResultsMessage}
                  tableFormat={tableFormat}
                  getDataHandler={getAppointments}
                  onRequestSort={handleRequestSort}
                  hideOptions
                  hidePageing={false}
                />
              </Box>
            </Box>
          )}
        </Box>
      </LoggedIn>
    </>
  );
};

export default Appointments;
