const styles = {
  arrowDown: {
    transform: "rotate(180deg)",
    color: "red",
  },
  arrowUp: {
    transform: "rotate(0deg)",
  },
};

export default styles;
