import React, { useState, useReducer, useEffect, useCallback } from "react";
import { Grid, Box } from "@mui/material";
import reducers from "reducers/form-errors-reducer";
import InputField from "components/core/form/Input/Input";
import Button from "components/core/buttons/Primary/Primary";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import HTTPError from "lib/errors/http-error";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import Loader from "components/core/loaders/circular/Circular";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import sessionsService from "services/sessions/sessions-service";
import errorHandler from "./EditSession.error.handler";
import styles from "./EditSession.styles";
import utils from "lib/utils";
import PropTypes from "prop-types";

const intialErrorState = {
  session: null,
  sessionTitle: null,
};

const formDefaultValues = {
  session: 0,
  sessionTitle: "",
};

const EditSession = ({ onCancel, actions, closeModal, onComplete, id }) => {
  const [isLoading, updateIsLoading] = useState(false);
  const [isSavingContent, updateIsSavingContent] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState(undefined);
  const [isFromValid, updateIsFromValid] = useState(false);

  const [form, updateForm] = useState({
    session: 0,
    sessionTitle: "",
  });
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const handleCloseModal = () => {
    closeModal();
    onCancel();
  };

  const actionsWithProps = utils.mapPropsToChilden(actions, {
    onClick: handleCloseModal,
    isLoading,
  });

  const characterLimit = 255;

  const getcontentById = useCallback(
    async (id) => {
      updateIsLoading(true);

      try {
        const response = await sessionsService.getSessionBySesssionNumber(id);

        // Save initial values to use on modal close
        formDefaultValues.session = response.session;
        formDefaultValues.sessionTitle = response.sessionTitle;

        const newForm = {
          session: response.session,
          sessionTitle: response.sessionTitle,
        };

        updateForm(newForm);
        updateIsLoading(false);
      } catch (err) {
        updateIsLoading(false);
        setNoResultsMessage(
          "Unable to load session details. Please try again later."
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getcontentById(id);
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isFormValid(form);

    const newForm = { ...form };
    updateForm(newForm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    let config = {};

    try {
      await sessionsService.updateSession(form.sessionTitle, id, config);
      updateIsSavingContent(false);
      closeModal();
      await onComplete();
    } catch (err) {
      updateIsSavingContent(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };

  const isFormValid = (form) => {
    const { session } = form;
    if (session) {
      updateIsFromValid(true);
    } else {
      updateIsFromValid(false);
    }
  };

  const handleOnFormChange = (e) => {
    let newForm = {
      ...form,
      [e.target.name || e.target.id]: e.target.value,
    };

    isFormValid(newForm);
    updateForm(newForm);
  };

  let loadingStyles = {};
  if (isLoading) {
    loadingStyles = styles.loading;
  }

  return (
    <Box sx={styles.panel}>
      <>
        <Box sx={styles.header}>
          <HeadingBold text="Edit Content" />
          {actionsWithProps}
        </Box>
        <div style={styles.divider} />
      </>
      {isLoading ? (
        <Box style={loadingStyles}>
          <Loader />
        </Box>
      ) : noResultsMessage ? (
        <Box style={styles.container}>
          <Paragraph>{noResultsMessage}</Paragraph>
        </Box>
      ) : (
        <form onSubmit={onSubmit} style={styles.formFooter}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={styles.gridRow}>
              <InputField
                id="session"
                name="session"
                label="Session"
                type="number"
                disabled
                value={form.session}
                theme="inputWhite"
                sx={styles.textFieldMargin}
              />
            </Grid>
            <Grid item xs={12} style={styles.gridRow}>
              <InputField
                id="sessionTitle"
                name="sessionTitle"
                cy="title-field"
                label="Session Title"
                defaultValue={form.sessionTitle}
                theme="inputWhite"
                sx={styles.textFieldMargin}
                placeholder="Title"
                error={errorState.sessionTitle ? true : false}
                onChange={(e) => handleOnFormChange(e)}
                inputProps={{
                  inputProps: {
                    maxLength: characterLimit,
                  },
                }}
              />
            </Grid>
          </Grid>

          {errorState.general && <ErrorHelperText text={errorState.general} />}

          <Box style={styles.footer}>
            <Button
              type="submit"
              text="Save"
              variant="contained"
              style={styles.button}
              loading={isSavingContent}
              disabled={!isFromValid}
            />
          </Box>
        </form>
      )}
    </Box>
  );
};

EditSession.defaultProps = {
  actions: undefined,
  onClick: () => {},
  onComplete: () => {},
  onCancel: () => {},
  id: undefined,
};
EditSession.propTypes = {
  actions: PropTypes.element,
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
};

export default EditSession;
