import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Select from "components/core/form/Select/Select";

import styles from "./ReferenceDataSelect.style";
import CircularLoader from "components/core/loaders/circular/Circular";
const getQuestionData = async (service) => {
  return service();
};
const ReferenceDataSelect = ({
  id,
  cy,
  label,
  variant,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  disabled,
  value,
  service,
  autoComplete,
  placeholder,
  multiple,
  theme,
  subLabel,
}) => {
  const [options, updateOptions] = useState([]);
  const [dataLoading, updateDataLoading] = useState(true);

  useEffect(() => {
    const getQuestions = async () => {
      const response = await getQuestionData(service);
      updateOptions(response);
      updateDataLoading(false);
    };

    getQuestions();
    return () => {
      // cancel subscription
      updateOptions([]);
    };
  }, [service]);

  return (
    <>
      {dataLoading ? (
        <CircularLoader contanierSx={styles.loader} />
      ) : (
        <Select
          id={id}
          autoComplete={autoComplete}
          helperText={helperText}
          error={error}
          multiple={multiple}
          data-cy={cy}
          placeholder={placeholder}
          required={required}
          inputProps={{ name: id, ...inputProps }}
          label={label}
          variant={variant}
          disabled={disabled}
          onChange={onChange}
          value={value}
          options={options}
          sx={sx}
          theme={theme}
          subLabel={subLabel}
        />
      )}
    </>
  );
};

ReferenceDataSelect.defaultProps = {
  variant: "outlined",
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: () => {},
  options: [],
  disabled: false,
  value: undefined,
  autoComplete: undefined,
  cy: "reference-data-select",
  multiple: false,
  theme: undefined,
  subLabel: undefined,
};

ReferenceDataSelect.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  service: PropTypes.func.isRequired,
  autoComplete: PropTypes.string,
  theme: PropTypes.string,
};
export default ReferenceDataSelect;
