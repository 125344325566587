import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { Box } from "@mui/material";
import LoginLayout from "components/layouts/login-layout/LoginLayout";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import InternalLink from "components/core/links/Internal/InternalLink";

import styles from "./PasswordSuccessfullyResetPage.styles.js";

export const PasswordSuccessfullyResetPage = () => {
  const [signInLink, updateSignInLink] = useState(undefined);
  const location = useLocation();

  useEffect(() => {
    const qsToken = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (qsToken.signInLink) {
      updateSignInLink(qsToken.signInLink);
    }
  }, [updateSignInLink, location.search]);

  return (
    <LoginLayout
      content={
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4rem",
            marginRight: 34,
          }}
          sx={{ minWidth: { lg: "400px" } }}
        >
          <CheckCircleOutlineOutlinedIcon sx={styles.icon} />
          <HeadingSmall
            text="Password Reset Successfully"
            sx={styles.heading}
          />
          <Paragraph text="Your password has been updated." sx={styles.text} />
          {signInLink ? (
            <InternalLink
              to={signInLink}
              text="Login"
              theme="primaryButton"
              style={styles.retry}
            />
          ) : null}
        </Box>
      }
    />
  );
};
