import React, { useState, useReducer } from "react";
import { Grid, Box, Modal } from "@mui/material";
import moment from "moment";
import reducers from "reducers/form-errors-reducer";
import InputField from "components/core/form/Input/Input";
import Button from "components/core/buttons/Primary/Primary";
import ReferenceDataSelect from "components/core/form/ReferenceDataSelect/ReferenceDataSelect";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import HTTPError from "lib/errors/http-error";
import FormHelperText from "@mui/material/FormHelperText";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import CloseIcon from "@mui/icons-material/Close";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import appointmentsService from "services/appointments/appointments-service";
import sessionsService from "services/sessions/sessions-service";
import userService from "services/user/user-service";
import TIMES from "lib/const/appointmentTimes";
import errorHandler from "./AddAppointment.error.handler";
import styles from "./AddAppointment.styles";
import stylesConfig from "theme/config";

const intialErrorState = {
  date: null,
  time: null,
  sessionNumber: null,
  attendees: null,
  general: null,
};

const AddAppointment = ({ closeModal, onComplete }) => {
  const [isSavingAppointment, updateIsSavingAppointment] = useState(false);
  const [openAlertModal, updateOpenAlertModal] = useState(false);
  const [addAppointmentDisabled, updateAddAppointmentDisabled] = useState(true);
  const [allAttendees, updateAllAttendees] = useState([]);
  const [shouldGetAttendees, updateShouldGetAttendees] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [shouldGetSessions, updateShouldGetSessions] = useState(true);
  const [form, updateForm] = useState({
    date: "",
    time: "",
    sessionNumber: 0,
    attendees: [],
  });
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const characterLimit = 246;

  const getSessions = async () => {
    if (!shouldGetSessions) return sessions;

    const sessionsResponse = await sessionsService.getAllSessions();
    if (sessionsResponse) {
      setSessions(sessionsResponse);
    }

    updateShouldGetSessions(false);
  };

  const getAttendees = async () => {
    if (!shouldGetAttendees) return allAttendees;

    const sessionNumberTosearch = sessions.filter((session) => {
      return session.value === form.sessionNumber;
    });

    try {
      const attendeesResult = await userService.getAttendeesBySessionNumber(
        sessionNumberTosearch[0].previousValue,
        sessionNumberTosearch[0].value
      );

      const attendees = attendeesResult.map((attendee) => {
        return {
          id: attendee.id,
          value: attendee.id,
          label: attendee.name,
        };
      });

      updateShouldGetAttendees(false);
      updateAllAttendees(attendees);
    } catch (err) {
      return [];
    }
  };

  const getTime = () => {
    const currentHour = moment().format("H");
    const currentDay = moment().format("YYYY-MM-DD");

    if (!form.date || form.date > currentDay) {
      return TIMES;
    } else {
      return TIMES.map((time) => {
        return {
          ...time,
          disabled: time.id < currentHour,
        };
      });
    }
  };

  const createAppointment = async (payload, force = false) => {
    try {
      updateIsSavingAppointment(true);
      await appointmentsService.createAppointment(payload, force);
      updateIsSavingAppointment(false);
      await onComplete();
      closeModal();
    } catch (err) {
      updateIsSavingAppointment(false);
      if (err instanceof HTTPError) {
        if (err.data && err.data.code === 137) {
          updateOpenAlertModal(true);
        } else {
          errorHandler(err, errorDispatch);
        }
      } else {
        throw err;
      }
    }
  };

  const handleAddAppointmentCreation = () => {
    const { date, time, sessionNumber, attendees } = form;

    const payload = {
      date: date,
      time: time,
      sessionNumber: sessionNumber,
      attendees: attendees.join(","),
    };

    createAppointment(payload, true);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { date, time, sessionNumber, attendees } = form;

    const payload = {
      date: date,
      time: time,
      sessionNumber: sessionNumber,
      attendees: attendees.join(","),
    };

    createAppointment(payload);
  };

  const isFormValid = (form) => {
    const { date, time, sessionNumber, attendees } = form;

    if (date && time && attendees.length > 0 && sessionNumber) {
      updateAddAppointmentDisabled(false);
    } else {
      updateAddAppointmentDisabled(true);
    }
  };

  const handleOnFormChange = (e) => {
    let newForm = {
      ...form,
      [e.target.name || e.target.id]: e.target.value,
    };

    if (e.target.name === "sessionNumber") {
      updateShouldGetAttendees(true);

      // Reset attendees, user changed the session number so we need to get the new attendees list
      newForm.attendees = [];
    } else {
      updateShouldGetAttendees(false);
    }

    isFormValid(newForm);
    updateForm(newForm);
  };

  return (
    <>
      <form onSubmit={onSubmit} style={styles.formFooter}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={styles.gridRow}>
            <InputField
              id="date"
              name="date"
              cy="date-field"
              type="date"
              label="Date"
              required
              sx={styles.textFieldMargin}
              theme="inputWhite"
              placeholder="dd/mm/yyyy"
              error={errorState.date ? true : false}
              helperText={errorState.date}
              onChange={(e) => handleOnFormChange(e)}
              inputProps={{
                inputProps: {
                  maxLength: characterLimit,
                  min: new Date().toISOString().substring(0, 10),
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={styles.gridRow}>
            <ReferenceDataSelect
              disabled={false}
              label="Time"
              id="time"
              key={form.date}
              placeholder="Select a time"
              service={() => getTime}
              helperText={errorState.time}
              error={errorState.time ? true : false}
              sx={{ backgroundColor: stylesConfig.colors.standardGrey }}
              theme="inputWhite"
              onChange={(e) => handleOnFormChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6} style={styles.gridRow}>
            <ReferenceDataSelect
              disabled={false}
              label="Session Number"
              id="sessionNumber"
              placeholder="Select Session"
              service={getSessions}
              helperText={errorState.sessionNumber}
              error={errorState.sessionNumber ? true : false}
              sx={{ backgroundColor: stylesConfig.colors.standardGrey }}
              theme="inputWhite"
              onChange={(e) => handleOnFormChange(e)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              ...styles.gridRow,
              position: "relative",
            }}
          >
            <ReferenceDataSelect
              disabled={false}
              label="Attendees"
              id="attendees"
              key={form.sessionNumber}
              multiple={true}
              placeholder="Select attendees"
              service={getAttendees}
              helperText={errorState.attendees}
              error={errorState.attendees ? true : false}
              sx={{ backgroundColor: stylesConfig.colors.standardGrey }}
              theme="inputWhite"
              onChange={(e) => handleOnFormChange(e)}
            />
            {form.sessionNumber === 0 ? (
              <FormHelperText sx={styles.helperText}>
                Please choose a session number to load the list of attendees.
              </FormHelperText>
            ) : null}
          </Grid>
        </Grid>

        {errorState.general && <ErrorHelperText text={errorState.general} />}

        <Box style={styles.footer}>
          <Button
            type="submit"
            text="Create Appointment"
            variant="contained"
            style={styles.button}
            loading={isSavingAppointment}
            disabled={addAppointmentDisabled}
          />
        </Box>
      </form>
      <Modal
        open={openAlertModal}
        onClose={() => updateOpenAlertModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal} data-cy="modal-content">
          <Box sx={styles.header}>
            <HeadingBold text="Attention!" />
            <CloseIcon
              onClick={() => updateOpenAlertModal(false)}
              style={styles.closeButton}
            />
          </Box>
          <div style={styles.divider} />
          <Box style={styles.modalContainer}>
            <Box>
              <Paragraph>
                One or more patients already have an appoitment for this date.
                Do you like to still go ahead and book?
              </Paragraph>
            </Box>
            <Box sx={styles.modalActions}>
              <Button
                text="Exit without saving"
                size="standard-bold"
                theme="primary"
                loading={isSavingAppointment}
                sx={styles.marginRight}
                onClick={() => {
                  updateOpenAlertModal(false);
                  closeModal();
                }}
              />

              <Button
                loading={isSavingAppointment}
                theme="primary"
                text="Save and exit"
                size="standard-bold"
                onClick={handleAddAppointmentCreation}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddAppointment;
