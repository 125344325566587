import stylesConfig from "theme/config";

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: { xs: "550px" },
  },
  content: {
    paddingTop: stylesConfig.spacing.veryLarge,
  },
  paragraph: {
    paddingTop: stylesConfig.spacing.small,
    paddingBottom: stylesConfig.spacing.small,
  },
  appName: {
    textAlign: "left",
    font: "normal normal bold 16px/16px Poppins",
    letterSpacing: "0px",
    color: stylesConfig.colors.nero,
    margin: 0,
    padding: 0,
    paddingBottom: stylesConfig.spacing.verySmall,
  },
  subtTitle: {
    textAlign: "left",
    font: "normal normal bold 16px/25px Poppins",
    letterSpacing: "0px",
    color: stylesConfig.colors.nero,
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: stylesConfig.colors.white,
    boxShadow: stylesConfig.shadows.standard,
    borderRadius: "8px",
    paddingTop: stylesConfig.spacing.large,
    paddingBottom: stylesConfig.spacing.large,
    width: {
      xs: "calc(100% - 20px)",
      md: "744px",
    },
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: stylesConfig.spacing.large,
    paddingLeft: stylesConfig.spacing.large,
    textAlign: "center",
  },
  modalActions: {
    paddingTop: stylesConfig.spacing.standard,
  },
  modalCheck: {
    color: stylesConfig.colors.success,
    fontSize: "48px",
    marginLeft: "-6px",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 2,
    paddingRight: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  modalChangePasswordHeader: {
    display: "flex",
    marginBottom: stylesConfig.spacing.small,
    paddingLeft: 2,
    paddingRight: 2,
    alignItems: "center",
  },
  divider: {
    width: "100%",
    height: "2px",
    backgroundColor: "#DEE2E4",
  },
  closeButton: {
    marginLeft: "auto",
    color: stylesConfig.colors.primary,
    fontSize: "30px",
  },
  errorIcon: {
    fontSize: "45px",
    color: stylesConfig.colors.red,
    marginBottom: stylesConfig.spacing.small,
  },
  successIcon: {
    fontSize: "45px",
    color: stylesConfig.colors.success,
    marginBottom: stylesConfig.spacing.small,
  },
};

export default styles;
