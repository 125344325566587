import styleConfig from "theme/config";

const styles = {
  remove: {
    display: "flex",
    alignItems: "center",
    marginRight: styleConfig.spacing.standard,
    marginBottom: styleConfig.spacing.small,
  },
  removeButton: {
    border: `1px solid ${styleConfig.colors.black}`,
    height: "15px",
    width: "15px",
    marginLeft: styleConfig.spacing.small,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  removeIcon: {
    height: "auto",
    width: "12px",
    color: styleConfig.colors.black,
  },
};

export default styles;
