import stylesConfig from "theme/config";

const styles = {
  formFooter: {
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column-reverse", sm: "row" },
    justifyContent: { xs: "", sm: "flex-end" },
    alignItems: "center",
  },
  internalLink: {
    marginRight: { xs: 0, sm: stylesConfig.spacing.standard },
    marginTop: { xs: stylesConfig.spacing.standard, sm: "0" },
  },
  button: {
    marginTop: stylesConfig.spacing.standard,
  },
};

export default styles;
