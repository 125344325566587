import stylesConfig from "theme/config";

const styles = {
  container: {
    width: "100%",
    minHeight: "160px",
    display: "flex",
    backgroundcolor: "#fff",
    flexDirection: "column",
    maxHeight: "calc(100vh - 210px)",
    overflowY: "auto",
  },
  formFields: {
    display: "flex",
  },
  dropWrapper: {
    flex: 9,
    marginRight: stylesConfig.spacing.veryVeryLarge,
  },
  selectWrapper: {
    flex: 3,
  },
  inputContainerStyles: {
    marginBottom: stylesConfig.spacing.large,
  },
  input: {
    width: "100%",
  },
  searchResult: { marginBottom: stylesConfig.spacing.large },
  actions: {
    marginTop: "auto",
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "flex-end",
  },
  files: {
    marginTop: stylesConfig.spacing.standard,
    display: "flex",
    flexWrap: "wrap",
  },
  label: {
    font: "normal normal bold 16px/19px Poppins",
    color: stylesConfig.colors.black,
  },
};

export default styles;
