import React, { useState } from "react";
import PropTypes from "prop-types";
import _without from "lodash/without";
import { Select, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import Paragraph from "components/core/typography/description/Description";
import stylesConfig from "theme/config";
import styles from "./Select.style";

const Selects = ({
  id,
  cy,
  label,
  variant,
  inputProps,
  required,
  sx,
  helperText,
  error,
  onChange,
  options,
  disabled,
  value,
  autoComplete,
  placeholder,
  multiple,
  theme,
  subLabel,
}) => {
  const [setValue, updateSetValue] = useState(multiple ? [] : value);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (multiple) {
      onChange(event);
      updateSetValue(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    } else {
      onChange(event);
      updateSetValue(value);
    }
  };

  const handleDelete = (e, value) => {
    e.preventDefault();
    updateSetValue((current) => {
      const newValue = _without(current, value);

      onChange({
        target: {
          name: id,
          value: newValue,
        },
      });
      return newValue;
    });
  };

  const handleDeletAll = (e, value) => {
    e.preventDefault();
    updateSetValue([]);

    onChange({
      target: {
        name: id,
        value: [],
      },
    });
  };

  let themeStykes = {};

  if (theme === "inputWhite") {
    themeStykes = styles["inputWhite"];
  }

  return (
    <>
      <Paragraph
        text={label}
        sx={{
          font: "normal normal bold 16px/19px Poppins",
          color: error ? stylesConfig.colors.red : stylesConfig.colors.black,
          margin: subLabel ? "0.2" : "unset",
        }}
      />
      {subLabel && (
        <Paragraph
          text={subLabel}
          sx={{
            font: "normal normal normal 16px/19px Poppins",
            color: error
              ? stylesConfig.colors.red
              : stylesConfig.colors.description,
          }}
        />
      )}
      <div style={{ position: "relative" }}>
        <Select
          id={id}
          displayEmpty
          renderValue={(value) => {
            if (multiple) {
              return value.length === 0 ? (
                <Paragraph
                  sx={{
                    paddingTop: "1rem",
                    color: stylesConfig.colors.inactive,
                  }}
                >
                  {placeholder}
                </Paragraph>
              ) : (
                <div style={styles.container}>
                  <Box sx={styles.chips}>
                    {value.map((value) => (
                      <Chip
                        key={value}
                        label={options.map((item) =>
                          item.value === value ? item.label : ""
                        )}
                        clickable
                        deleteIcon={
                          <CloseIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        sx={styles.chip}
                        onDelete={(e) => handleDelete(e, value)}
                      />
                    ))}
                  </Box>
                  <div style={styles.actionsContainer}>
                    <Box style={styles.counterContainer}>
                      <Paragraph sx={styles.counter}>{value.length}</Paragraph>
                    </Box>
                    <Box style={styles.closeContainer}>
                      <CloseIcon
                        onMouseDown={(event) => event.stopPropagation()}
                        onClick={handleDeletAll}
                      />
                    </Box>
                  </div>
                </div>
              );
            } else {
              let displayValue = value;
              if (options) {
                const option = options.find((option) => option.value === value);
                if (option && option.label) {
                  displayValue = option.label;
                }
              }
              return value ? (
                displayValue
              ) : (
                <Paragraph
                  sx={{
                    paddingTop: "1rem",
                    color: stylesConfig.colors.inactive,
                  }}
                >
                  {placeholder}
                </Paragraph>
              );
            }
          }}
          autoComplete={autoComplete}
          helperText={helperText}
          error={error}
          multiple={multiple}
          data-cy={cy}
          required={required}
          inputProps={{ name: id, ...inputProps }}
          variant={variant}
          disabled={disabled}
          onChange={handleChange}
          value={setValue}
          style={themeStykes}
          sx={{ marginBottom: "1rem", width: "100%", ...sx }}
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={`${id}-${option.value}`}
                value={option.value}
                disabled={option.disabled}
              >
                {multiple && (
                  <Checkbox
                    checked={setValue && setValue.indexOf(option.value) > -1}
                  />
                )}
                {option.label || option.value}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </>
  );
};

Selects.defaultProps = {
  variant: "standard",
  required: undefined,
  inputProps: undefined,
  sx: undefined,
  error: false,
  helperText: undefined,
  onChange: () => {},
  options: [],
  disabled: false,
  value: undefined,
  autoComplete: undefined,
  multiple: false,
  theme: undefined,
  subLabel: undefined,
};

Selects.propTypes = {
  id: PropTypes.string,
  cy: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
  theme: PropTypes.string,
};
export default Selects;
