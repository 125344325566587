import reducers from "reducers/form-errors-reducer";
import errorCodes from "lib/error-codes";

const errorHandler = (err, errorDispatch) => {
  if (err.statusCode === 422) {
    const errorData = err.data;

    if (errorData.field === "category") {
      if (errorData.code === errorCodes.HAS_ATTACHED_SUBCATEGORIES) {
        return reducers.createFormErrorsHandler(
          "general",
          "Cannot delete a category that has subcategories attached to it.",
          errorDispatch
        );
      }

      if (errorData.code === errorCodes.HAS_ATTACHED_LOCATION) {
        return reducers.createFormErrorsHandler(
          "general",
          "Cannot delete a category that is attached to a location.",
          errorDispatch
        );
      }
    }

    return reducers.createFormErrorsHandler(
      "general",
      "There was a problem removing this category.",
      errorDispatch
    );
  } else {
    throw err;
  }
};

export default errorHandler;
