import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Grid, Card, CardMedia, CardContent } from "@mui/material";
import login from "assets/login.svg";
import styles from "./LoginLayout.style";

const year = new Date().getFullYear();
const LoginLayout = ({ content }) => {
  return (
    <Grid
      container
      component="main"
      justify="center"
      alignItems="center"
      sx={{
        ...styles.background,
      }}
    >
      <Card
        style={styles.card}
        sx={{
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardMedia
          component="img"
          alt="login background"
          sx={{
            height: { xs: "233px", md: "633px" },
            width: { xs: "100%", md: "40%" },
          }}
          image={login}
        />
        <CardContent sx={styles.content}>{content}</CardContent>
      </Card>

      <Grid item xs={12} sx={styles.footer}>
        <Box component="span" cy="footer">
          NHS {year} © Copyright, All rights reserved{" "}
        </Box>
      </Grid>
    </Grid>
  );
};

LoginLayout.propTypes = {
  content: PropTypes.instanceOf(Object),
};
export default LoginLayout;
