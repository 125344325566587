import api from "../../lib/http";

const createAppointment = async function (newAppointment, force = false) {
  const result = await api(
    "post",
    `appointment/?byPassAlreadyExistingAppointment=${force}`,
    newAppointment,
    true,
    true,
    true
  );

  return result.data;
};

const getAppointments = async (
  pageNo = 0,
  pageSize = 10,
  order,
  orderBy,
  search = {}
) => {
  let pageQuery = `appointment?page=${pageNo}&pageSize=${pageSize}`;

  if (search.session) {
    pageQuery = `${pageQuery}&sessionNumber=${search.session}`;
  }

  if (search.attenddes) {
    pageQuery = `${pageQuery}&name=${search.attenddes}`;
  }

  if (search.date) {
    pageQuery = `${pageQuery}&date=${search.date}`;
  }

  if (order && orderBy) {
    pageQuery = `${pageQuery}&property=${orderBy}&orderBy=${order}`;
  }

  const result = await api("get", pageQuery, null, true, true, true);
  return result.data;
};

const getAppointmentById = async function (id) {
  const result = await api("get", `appointment/${id}`, null, true, true, true);

  return result.data;
};

const deleteAppointmentById = async (id) => {
  const result = await api(
    "delete",
    `appointment/${id}`,
    null,
    true,
    true,
    true
  );
  return result.data;
};

const getTodayAppointments = async function () {
  const result = await api("get", `appointment/today`, null, true, true, true);

  return result.data;
};

const updateAppointmentAttendees = async function (appointmentId, attendees) {
  const result = await api(
    "patch",
    `appointment/${appointmentId}/attendees`,
    { attendees },
    true,
    true,
    true
  );

  return result.data;
};

const service = {
  createAppointment,
  getAppointments,
  deleteAppointmentById,
  getTodayAppointments,
  getAppointmentById,
  updateAppointmentAttendees,
};
export default service;
