import stylesConfig from "theme/config";

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    minHeight: "168px",
    paddingTop: stylesConfig.spacing.standard,
  },
  button: {
    ...stylesConfig.buttons.primary,
    marginTop: "8rem",
  },
  emailField: {
    marginBottom: stylesConfig.spacing.veryLarge,
    paddingBottom: stylesConfig.spacing.veryLarge,
  },
};

export default styles;
