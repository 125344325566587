import stylesConfig from "theme/config";

const styles = {
  container: {
    paddingTop: "6rem",
    backgroundColor: "red",
  },
  heading: {
    marginBottom: stylesConfig.spacing.standard,
  },
  retry: {
    width: "100%",
    marginTop: stylesConfig.spacing.standard,
  },
  icon: {
    fontSize: "45px",
    color: stylesConfig.colors.success,
    marginBottom: stylesConfig.spacing.small,
  },
};

export default styles;
