import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import styles from "./Remove.style";

const Remove = ({ onClick, item, sx }) => {
  return (
    <Box sx={{ ...styles.remove, ...sx }} component="span">
      {item}
      <IconButton sx={styles.removeButton} onClick={() => onClick(item)}>
        <CloseOutlinedIcon sx={styles.removeIcon} />
      </IconButton>
    </Box>
  );
};

Remove.defultProps = {
  onClick: () => {},
  item: undefined,
  sx: {},
};

Remove.propTypes = {
  onClick: PropTypes.func,
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sx: PropTypes.shape({}),
};
export default Remove;
