import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import styles from "./ForgottenPasswordPage.styles";
import Button from "components/core/buttons/Primary/Primary";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import { useTimer } from "react-timer-hook";
import authenticationService from "services/authentication/authentication-service";
const NUMBER_OF_SECONDS_TO_WAIT = 60;

const ResendPasswordWidget = ({ email }) => {
  const [disableSendAgain, updateDisableSendAgain] = useState(false);
  const [loading, updateLoading] = useState(false);
  const { restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => updateDisableSendAgain(false),
  });

  const resendEmail = async () => {
    updateLoading(true);
    await authenticationService.resetPassword(email);
    const counter = new Date();
    counter.setSeconds(counter.getSeconds() + NUMBER_OF_SECONDS_TO_WAIT);
    updateDisableSendAgain(true);
    updateLoading(false);
    restart(counter);
  };

  return (
    <>
      <Box sx={styles.resendPasswordWidget}>
        <Box sx={styles.sendAngainBox}>
          <Paragraph text={"Didn’t receive email from us"} />
          <Button
            disabled={disableSendAgain}
            loading={loading}
            text={"Send Again?"}
            sx={styles.sendAgain}
            onClick={resendEmail}
            naked
          />
        </Box>
      </Box>
      {disableSendAgain && (
        <Box sx={styles.emailSentConfirmation}>Email Sent Successfully</Box>
      )}
    </>
  );
};

ResendPasswordWidget.propTypes = {
  email: PropTypes.string.isRequired,
};
export default ResendPasswordWidget;
