module.exports = [
  {
    id: 0,
    value: "00:00 - 00:30",
    label: "00:00 - 00:30",
  },
  {
    id: 1,
    value: "00:30 - 01:00",
    label: "00:30 - 01:00",
  },
  {
    id: 2,
    value: "01:00 - 01:30",
    label: "01:00 - 01:30",
  },
  {
    id: 3,
    value: "01:30 - 02:00",
    label: "01:30 - 02:00",
  },
  {
    id: 4,
    value: "02:00 - 02:30",
    label: "02:00 - 02:30",
  },
  {
    id: 5,
    value: "02:30 - 03:00",
    label: "02:30 - 03:00",
  },
  {
    id: 6,
    value: "03:00 - 03:30",
    label: "03:00 - 03:30",
  },
  {
    id: 7,
    value: "03:30 - 04:00",
    label: "03:30 - 04:00",
  },
  {
    id: 8,
    value: "04:00 - 04:30",
    label: "04:00 - 04:30",
  },
  {
    id: 9,
    value: "04:30 - 05:00",
    label: "04:30 - 05:00",
  },
  {
    id: 10,
    value: "05:00 - 05:30",
    label: "05:00 - 05:30",
  },
  {
    id: 11,
    value: "05:30 - 06:00",
    label: "05:30 - 06:00",
  },
  {
    id: 12,
    value: "06:00 - 06:30",
    label: "06:00 - 06:30",
  },
  {
    id: 13,
    value: "06:30 - 07:00",
    label: "06:30 - 07:00",
  },
  {
    id: 14,
    value: "07:00 - 07:30",
    label: "07:00 - 07:30",
  },
  {
    id: 15,
    value: "07:30 - 08:00",
    label: "07:30 - 08:00",
  },
  {
    id: 16,
    value: "08:00 - 08:30",
    label: "08:00 - 08:30",
  },
  {
    id: 17,
    value: "08:30 - 09:00",
    label: "08:30 - 09:00",
  },
  {
    id: 18,
    value: "09:00 - 09:30",
    label: "09:00 - 09:30",
  },
  {
    id: 19,
    value: "09:30 - 10:00",
    label: "09:30 - 10:00",
  },
  {
    id: 20,
    value: "10:00 - 10:30",
    label: "10:00 - 10:30",
  },
  {
    id: 21,
    value: "10:30 - 11:00",
    label: "10:30 - 11:00",
  },
  {
    id: 22,
    value: "11:00 - 11:30",
    label: "11:00 - 11:30",
  },
  {
    id: 23,
    value: "11:30 - 12:00",
    label: "11:30 - 12:00",
  },
  {
    id: 24,
    value: "12:00 - 12:30",
    label: "12:00 - 12:30",
  },
  {
    id: 25,
    value: "12:30 - 13:00",
    label: "12:30 - 13:00",
  },
  {
    id: 26,
    value: "13:00 - 13:30",
    label: "13:00 - 13:30",
  },
  {
    id: 27,
    value: "13:30 - 14:00",
    label: "13:30 - 14:00",
  },
  {
    id: 28,
    value: "14:00 - 14:30",
    label: "14:00 - 14:30",
  },
  {
    id: 29,
    value: "14:30 - 15:00",
    label: "14:30 - 15:00",
  },
  {
    id: 30,
    value: "15:00 - 15:30",
    label: "15:00 - 15:30",
  },
  {
    id: 31,
    value: "15:30 - 16:00",
    label: "15:30 - 16:00",
  },
  {
    id: 32,
    value: "16:00 - 16:30",
    label: "16:00 - 16:30",
  },
  {
    id: 33,
    value: "16:30 - 17:00",
    label: "16:30 - 17:00",
  },
  {
    id: 34,
    value: "17:00 - 17:30",
    label: "17:00 - 17:30",
  },
  {
    id: 35,
    value: "17:30 - 18:00",
    label: "17:30 - 18:00",
  },
  {
    id: 36,
    value: "18:00 - 18:30",
    label: "18:00 - 18:30",
  },
  {
    id: 37,
    value: "18:30 - 19:00",
    label: "18:30 - 19:00",
  },
  {
    id: 38,
    value: "19:00 - 19:30",
    label: "19:00 - 19:30",
  },
  {
    id: 39,
    value: "19:30 - 20:00",
    label: "19:30 - 20:00",
  },
  {
    id: 40,
    value: "20:00 - 20:30",
    label: "20:00 - 20:30",
  },
  {
    id: 41,
    value: "20:30 - 21:00",
    label: "20:30 - 21:00",
  },
  {
    id: 42,
    value: "21:00 - 21:30",
    label: "21:00 - 21:30",
  },
  {
    id: 43,
    value: "21:30 - 22:00",
    label: "21:30 - 22:00",
  },
  {
    id: 44,
    value: "22:00 - 22:30",
    label: "22:00 - 22:30",
  },
  {
    id: 45,
    value: "22:30 - 23:00",
    label: "22:30 - 23:00",
  },
  {
    id: 46,
    value: "23:00 - 23:30",
    label: "23:00 - 23:30",
  },
  {
    id: 47,
    value: "23:30 - 00:00",
    label: "23:30 - 00:00",
  },
];
