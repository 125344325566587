import stylesConfig from "theme/config";

const styles = {
  cellHeader: {
    color: stylesConfig.colors.black,
    font: "normal normal bold 16px/25px Poppins",
    margin: 0,
    padding: 0,
  },
  tableContainer: {
    backgroundColor: stylesConfig.colors.white,
    padding: stylesConfig.spacing.standard,
    borderRadius: 6,
  },
};

export default styles;
