const styles = {
  continer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  imgError: {
    position: "absolute",
    height: "100%",
    width: "100%",
    p: {
      padding: "10px",
    },
  },
};

export default styles;
