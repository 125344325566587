import stylesConfig from "theme/config";

const styles = {
  heading: {
    font: "normal normal bold 24px/35px Poppins",
    letterSpacing: "0px",
    color: stylesConfig.colors.nero,
  },
};

export default styles;
