import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import qs from "qs";
import authenticationService from "services/authentication/authentication-service";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import NewPasswordForm from "components/forms/auth/NewPassword/NewPasswordForm";
import LoginLayout from "components/layouts/login-layout/LoginLayout";

import styles from "./NewPasswordPage.styles.js";

export const NewPasswordPage = () => {
  const [token, setToken] = useState("false");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const validateAndSetToken = async () => {
      // Redirect to invalid token page if no token in query params.
      const parsedQs = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      if (!parsedQs.token) {
        history.push("/invalid-password-reset-token");
        // we return a clean up function to stop further state changes
        return () => {};
      }

      // Redirect to invalid token page if the token is not valid
      const { valid } = await authenticationService.validatePasswordResetToken(
        parsedQs.token
      );

      if (!valid) {
        history.push("/invalid-password-reset-token");
        return () => {};
      }

      // All appears OK - Set the token!
      setToken(parsedQs.token);
    };

    validateAndSetToken();
  }, [setToken, location.search, history]);

  const newPasswordSet = async (password) => {
    try {
      const newPasswordResponse = await authenticationService.newPassword(
        token,
        password
      );

      return history.push(
        `/password-reset-success${
          newPasswordResponse && newPasswordResponse.signInLink
            ? `?signInLink=${newPasswordResponse.signInLink}`
            : ""
        }`
      );
    } catch (passwordError) {
      return passwordError;
    }
  };

  return (
    <LoginLayout
      content={
        <Box style={styles.container}>
          <HeadingSmall text="Forget Password" sx={styles.heading} />
          <Paragraph>
            To change your password please enter and confirm the new one below.
          </Paragraph>
          <Paragraph>It will need:</Paragraph>
          <Paragraph>
            <li>At least 8 characters</li>
            <li>One capital letter</li>
            <li>One special character </li>
            <li>One number</li>
          </Paragraph>
          <NewPasswordForm onComplete={newPasswordSet} />
        </Box>
      }
    />
  );
};
