import React, { useState, useReducer } from "react";
import { Grid, Box } from "@mui/material";
import reducers from "reducers/form-errors-reducer";
import InputField from "components/core/form/Input/Input";
import Button from "components/core/buttons/Primary/Primary";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import registrationService from "../../../../services/registration/registration-service";
import errorHandler from "./AddPatientForm.error.handler";
import styles from "./AddPatient.styles";

const intialErrorState = {
  firstName: null,
  lastName: null,
  dob: null,
  chiNumber: null,
  guardianName: null,
  relationship: null,
  contactNumber: null,
  email: null,
  general: null,
};

const AddPatientForm = ({ clodeModal, onComplete }) => {
  const [isLoading, updateIsLoading] = useState(false);
  const [addPatientDisabled, updateAddPatientDisabled] = useState(true);
  const [form, updateForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    dateOfBirth: "",
    chiNumber: "",
    guardianName: "",
    relationshipToPatient: "",
  });
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const characterLimit = 246;

  const onSubmit = async (e) => {
    e.preventDefault();

    updateIsLoading(true);
    const payload = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      contactNumber: e.target.contactNumber.value,
      dateOfBirth: e.target.dateOfBirth.value,
      chiNumber: e.target.chiNumber.value,
      guardianName: e.target.guardianName.value,
      relationshipToPatient: e.target.relationshipToPatient.value,
    };

    const result = await registrationService.createPatient(payload);

    if (!result.ok) {
      updateIsLoading(false);
      errorHandler(result, errorDispatch);
    } else {
      updateIsLoading(false);
      await onComplete();
      clodeModal();
    }
  };

  const isFormValid = (form) => {
    const {
      firstName,
      lastName,
      email,
      contactNumber,
      dateOfBirth,
      guardianName,
      relationshipToPatient,
    } = form;

    if (
      firstName &&
      lastName &&
      email &&
      contactNumber &&
      dateOfBirth &&
      guardianName &&
      relationshipToPatient
    ) {
      updateAddPatientDisabled(false);
    } else {
      updateAddPatientDisabled(true);
    }
  };

  const handleOnFormChange = (e) => {
    let newForm = {
      ...form,
      [e.target.id]: e.target.value,
    };

    isFormValid(newForm);

    updateForm(newForm);
  };

  return (
    <form
      onSubmit={onSubmit}
      style={styles.formFooter}
      onChange={handleOnFormChange}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} style={styles.gridRow}>
          <InputField
            id="firstName"
            cy="firstName-field"
            label="First Name"
            required
            sx={styles.textFieldMargin}
            placeholder="Enter patients first name"
            error={errorState.firstName ? true : false}
            helperText={errorState.firstName}
            inputProps={{
              inputProps: {
                maxLength: characterLimit,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={styles.gridRow}>
          <InputField
            id="lastName"
            cy="lastName-field"
            label="Last Name"
            required
            sx={styles.textFieldMargin}
            placeholder="Enter patients last name"
            error={errorState.lastName ? true : false}
            helperText={errorState.lastName}
            inputProps={{
              inputProps: {
                maxLength: characterLimit,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={styles.gridRow}>
          <InputField
            id="dateOfBirth"
            cy="dob-field"
            type="date"
            label="DoB"
            subLabel="(Date of Birth)"
            required
            sx={styles.textFieldMargin}
            placeholder="dd/mm/yyyy"
            error={errorState.dateOfBirth ? true : false}
            helperText={errorState.dateOfBirth}
            inputProps={{
              inputProps: {
                maxLength: characterLimit,
                max: new Date().toISOString().substring(0, 10),
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={styles.gridRow}>
          <InputField
            id="chiNumber"
            cy="chi-field"
            label="CHI Number"
            required
            type="number"
            sx={styles.textFieldMargin}
            placeholder="Enter patients  CHI number"
            error={errorState.chiNumber ? true : false}
            helperText={errorState.chiNumber}
            inputProps={{
              inputProps: {
                max: 99999999999,
              },
            }}
          />
        </Grid>

        <Grid item xs={12} style={styles.gridSeparator} />

        <Grid item xs={12} md={6} style={styles.gridRow}>
          <InputField
            id="guardianName"
            cy="guardian-field"
            label="Guardian Name"
            required
            sx={styles.textFieldMargin}
            placeholder="Enter name of patients guardian"
            error={errorState.guardianName ? true : false}
            helperText={errorState.guardianName}
            inputProps={{
              inputProps: {
                maxLength: characterLimit,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={styles.gridRow}>
          <InputField
            id="relationshipToPatient"
            cy="relationship-field"
            label="Relationship to Patient"
            required
            sx={styles.textFieldMargin}
            placeholder="e.g. mother"
            error={errorState.relationshipToPatient ? true : false}
            helperText={errorState.relationshipToPatient}
            inputProps={{
              inputProps: {
                maxLength: characterLimit,
              },
            }}
          />
        </Grid>

        <Grid item xs={12} style={styles.gridSeparator} />

        <Grid item xs={12} md={6} style={styles.gridRow}>
          <InputField
            id="contactNumber"
            cy="contact-field"
            label="Contact Number"
            type="number"
            required
            sx={styles.textFieldMargin}
            placeholder="Enter preferred contact number"
            error={errorState.contactNumber ? true : false}
            helperText={errorState.contactNumber}
            inputProps={{
              inputProps: {
                min: 999999,
                max: 999999999999999,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={styles.gridRow} />
        <Grid item xs={12} style={styles.gridRow}>
          <InputField
            id="email"
            cy="email-field"
            label="Email Address"
            type="email"
            sx={styles.textFieldMargin}
            required
            autoComplete="email"
            placeholder="Enter your email address"
            error={errorState.email ? true : false}
            helperText={errorState.email}
            inputProps={{
              inputProps: {
                maxLength: characterLimit,
                autoComplete: "email",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={styles.gridRow} />
      </Grid>

      {errorState.general && <ErrorHelperText text={errorState.general} />}

      <Box style={styles.footer}>
        <Button
          type="submit"
          text="Add Patient"
          variant="contained"
          style={styles.button}
          loading={isLoading}
          disabled={addPatientDisabled}
        />
      </Box>
    </form>
  );
};

export default AddPatientForm;
