import stylesConfig from "theme/config";
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: stylesConfig.spacing.standard,
    paddingLeft: stylesConfig.spacing.standard,
  },
  icon: {
    fontSize: "40px",
    color: stylesConfig.colors.warning,
    marginBottom: stylesConfig.spacing.standard,
  },
  heading: {
    font: stylesConfig.typography.veryBoldHeading.font,
    color: stylesConfig.colors.warning,
    paddingBottom: stylesConfig.spacing.small,
    marginBottom: stylesConfig.spacing.large,
  },
  description: {
    marginBottom: stylesConfig.spacing.standard,
  },
  error: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
  cancelButton: {
    marginRight: stylesConfig.spacing.standard,
  },
};

export default styles;
