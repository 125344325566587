import React from "react";
import PropTypes from "prop-types";
import { Box, Modal } from "@mui/material";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import Button from "components/core/buttons/Primary/Primary";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import styles from "./AlertModal.styles";

const AlertModal = ({ open, onClose, title, description, ctaText, type }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.modal} data-cy="modal-content">
        <Box sx={styles.modalHeader}>
          {type === "success" && (
            <CheckCircleOutlineOutlinedIcon style={styles.modalCheck} />
          )}

          {type === "error" && <HighlightOffIcon style={styles.errorIcon} />}

          <HeadingBold text={title} />
        </Box>
        <Box style={styles.modalContainer}>
          <Paragraph>{description}</Paragraph>
          <Box sx={styles.modalActions}>
            <Button
              theme="primary"
              text={ctaText}
              size="standard-bold"
              onClick={onClose}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

AlertModal.defaultProps = {
  onClose: () => {},
  open: false,
  title: undefined,
  description: undefined,
  ctaText: undefined,
  type: "success",
};

AlertModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
  type: PropTypes.string,
};

export default AlertModal;
