import React from "react";
import PropTypes from "prop-types";
import PopoverModal from "components/core/modal/popover-modal/PopoverModal";
import PatchPatientForm from "components/forms/patient/patch-a-patient/PatchPatient";
import stylesConfig from "theme/config";
import CloseButton from "../../../../components/core/buttons/Close/Close";

const TableOptions = ({ data, onComplete, close }) => {
  return (
    <>
      <PopoverModal
        buttonTheme="primary"
        popover
        hideHeader={true}
        hideCloseButton={false}
        buttonCy="edit-patient-button"
        buttonText="View Patient Details"
        headingText="Patient Details"
      >
        <PatchPatientForm
          id={data.id}
          onComplete={onComplete}
          onCancel={close}
          actions={
            <CloseButton
              style={{
                marginLeft: "auto",
                fontSize: "30px",
                color: stylesConfig.colors.primary,
                backgroundColor: stylesConfig.colors.white,
                borderWidth: "0px",
              }}
            />
          }
        />
      </PopoverModal>
    </>
  );
};

TableOptions.defaultProps = {
  data: undefined,
  onComplete: () => {},
  close: () => {},
};

TableOptions.propTypes = {
  data: PropTypes.shape({}),
  onComplete: PropTypes.func,
  close: PropTypes.func,
};

export default TableOptions;
