import React from "react";
import { Box } from "@mui/material";
import InternalLink from "../Internal/InternalLink";
import PropTypes from "prop-types";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import styles from "./Back.style";
const Back = ({ link, sx, text, large }) => {
  return (
    <InternalLink to={link} cy="link-back" style={{ ...styles.link, ...sx }}>
      <Box component="span" sx={styles.container}>
        <ArrowBackIosRoundedIcon sx={styles.svg} />
        <Box component="span" sx={styles.text}>
          {large ? <HeadingBold>{text}</HeadingBold> : text}
        </Box>
      </Box>
    </InternalLink>
  );
};

Back.defaultProps = {
  sx: {},
  text: "Back",
  large: false,
};

Back.propTypes = {
  large: PropTypes.bool,
  text: PropTypes.string,
  link: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};
export default Back;
