import React from "react";
import { RegistrationResponseWidget } from "../../../components/registration/registration-response-widget/RegistrationResponseWidget";
import { Box } from "@mui/material";
import LoginLayout from "components/layouts/login-layout/LoginLayout";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import styles from "./CompleteRegistration.style";
export const InvalidRegistrationTokenPage = () => (
  <LoginLayout
    content={
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "4rem",
          marginRight: 34,
        }}
        sx={{ minWidth: { lg: "400px" } }}
      >
        <RegistrationResponseWidget
          heading="Registration Complete"
          bodyText="The registration token is either invalid, or has been used before."
          Icon={() => <CheckCircleOutlineOutlinedIcon sx={styles.icon} />}
          href={"/login"}
        />
      </Box>
    }
  />
);
