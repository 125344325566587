const typography = {
  h1: {
    font: "normal normal bold 57px/72px Poppins",
    letterSpacing: "0px",
  },
  h5: {
    font: "normal normal bold 27px/34px Poppins",
    letterSpacing: "0px",
  },
  h6: {
    font: "normal normal normal 18px/23px Poppins;",
    letterSpacing: "0px",
  },
  h7: {
    font: "normal normal normal 18px/46px Poppins;",
    letterSpacing: "0px",
  },
  veryBoldHeading: {
    font: "normal normal 900 30px/38px Poppins;",
  },
  boldHeading: {
    font: "normal normal bold 26px/45px Poppins;",
  },
  navlink: {
    font: "normal normal normal 16px/45px Poppins",
    letterSpacing: "0px",
  },
  newLink: {
    font: "normal normal normal 14px/45px Poppins",
    letterSpacing: "0px",
  },
  p: {
    font: "normal normal normal 15px/19px Poppins",
    letterSpacing: "0px",
  },
  pDescription: {
    font: "normal normal normal 16px/19px Poppins",
    letterSpacing: "0px",
  },
  label: {
    font: "normal normal normal 16px/19px Poppins",
    letterSpacing: "0px",
  },

  buttons: {
    standard: {
      font: "normal normal normal 14px/45px Poppins",
      fontWeight: 400,
    },
    "standard-bold": {
      font: "normal normal bold 16px/25px Poppins",
    },
    popoverOption: {
      font: " normal normal normal 14px/17px Poppins",
    },
  },
};

export default typography;
