import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import qs from "qs";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import LoginForm from "components/forms/auth/Login/LoginForm.jsx";
import LoginLayout from "components/layouts/login-layout/LoginLayout";
import HeadingMedium from "components/core/typography/headings/medium/HeadingMedium";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import userService from "services/user/user-service";
import AlertModal from "components/core/modal/alert-modal/AlertModal";

import styles from "./LoginPage.styles.js";

const MODAL_DATA = {
  success: {
    title: "Your Email Address Has Been Updated",
    description:
      "Thank you for confirming your email address. You can now use it to log in.",
    icon: CheckCircleOutlineOutlinedIcon,
    type: "success",
  },
  error: {
    title: "Your Email Address Has Not Been Updated",
    description:
      "Your email address cannot be updated because the link is expired",
    icon: CheckCircleOutlineOutlinedIcon,
    type: "error",
  },
};
export const LoginPage = () => {
  const [modalType, updateModalType] = React.useState("success");
  const [isAlertOpen, updateIsAlertOpen] = React.useState(false);
  const location = useLocation();

  const updateEmail = async (token) => {
    try {
      await userService.changeEmailByRequest(token);
      updateModalType("success");
      updateIsAlertOpen(true);
    } catch (err) {
      updateModalType("error");
      updateIsAlertOpen(true);
    }
  };

  useEffect(() => {
    const get = async (token) => await updateEmail(token);
    const parsedQs = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (parsedQs.token) {
      get(parsedQs.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handeleCloseModal = () => {
    updateIsAlertOpen(!isAlertOpen);
    window.location = "/login";
  };

  return (
    <LoginLayout
      content={
        <Box style={styles.content}>
          <Paragraph sx={styles.appName}>NHS Highlands</Paragraph>
          <HeadingMedium text="P.E.A.C.H" />
          <Paragraph sx={styles.subtTitle}>Admin Portal</Paragraph>
          <HeadingSmall text="Login" sx={styles.heading} cy="heading" />
          <Paragraph sx={styles.paragraph}>
            Login using the confirmed email address and your password. It is 6
            characters or more & a special character.
          </Paragraph>
          <LoginForm />

          <AlertModal
            open={isAlertOpen}
            onClose={handeleCloseModal}
            title={MODAL_DATA[modalType].title}
            description={MODAL_DATA[modalType].description}
            ctaText="Close"
            type={MODAL_DATA[modalType].type}
          />
        </Box>
      }
    />
  );
};
