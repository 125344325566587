import Cookie from "universal-cookie";
const cookies = new Cookie();
/**
 *
 * mediaApi function()
 * wrapper around the api sauce create object
 * need to check on each call whether the auth cookie is set,
 * if it is, we want to send the token up with each request
 * if it isn't, our AuthContext context will bounce the user
 * out of the app.
 *
 * method: get, post, put, delete
 * endpoint: the api we are looking to hit eg. "/auth/verify"
 * data: object containing our request body data
 *
 * @param {string} method
 * @param {string} endpoint
 * @param {obj} data
 */

const mediaApi = (endpoint) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/${endpoint}`, {
    headers: { Authorization: `Bearer ${cookies.get("rf-token") || ""}` },
  })
    .then((response) => response.blob())
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
};

export default mediaApi;
