import stylesConfig from "theme/config";

const styles = {
  header: {
    display: "flex",
    marginBottom: stylesConfig.spacing.small,
    paddingLeft: 2,
    paddingRight: 2,
    alignItems: "center",
  },
  divider: {
    width: "100%",
    height: "2px",
    backgroundColor: "#DEE2E4",
  },
};

export default styles;
