import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import styles from "./Modal.style";
import utils from "lib/utils";

const BasicModal = ({
  buttonCy,
  buttonText,
  buttonIcon,
  style,
  children,
  headingText,
  closeModal,
  updateModalState,
  buttonStyle,
  buttonTheme,
  buttonSx,
  buttonSize,
  modalStyle,
  buttonChildren,
  hideCloseButton,
  nakedButton,
  modalElementStyles,
  hideHeader,
  onClose,
  closeMenu,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleClose(false);
    updateModalState(false);
  }, [updateModalState, closeModal]);

  const childrenWithProps = utils.mapPropsToChilden(children, {
    closeModal: handleClose,
    closeMenu,
  });

  return (
    <Box sx={style}>
      <PrimaryButton
        onClick={handleOpen}
        cy={buttonCy}
        text={buttonText}
        style={buttonStyle}
        theme={buttonTheme}
        buttonIcon={buttonIcon}
        size={buttonSize}
        naked={nakedButton}
        sx={buttonSx}
      >
        {buttonChildren}
      </PrimaryButton>
      <Modal
        sx={modalElementStyles}
        open={open}
        onClose={() => {
          handleClose();
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styles.modal, ...modalStyle }} data-cy="modal-content">
          {!hideHeader && (
            <>
              <Box sx={styles.header}>
                {headingText && <HeadingBold text={headingText} />}
                {!hideCloseButton && (
                  <CloseIcon
                    onClick={() => {
                      handleClose();
                      onClose();
                    }}
                    style={styles.closeButton}
                  />
                )}
              </Box>
              <div style={styles.divider} />
            </>
          )}

          {childrenWithProps}
        </Box>
      </Modal>
    </Box>
  );
};

BasicModal.defaultProps = {
  onClose: () => {},
  closeModal: undefined,
  updateModalState: () => {},
  buttonStyle: undefined,
  modalStyle: undefined,
  buttonTheme: undefined,
  buttonSize: undefined,
  hideCloseButton: true,
  nakedButton: false,
  modalElementStyles: undefined,
  hideHeader: false,
  closeMenu: () => {},
};

BasicModal.propTypes = {
  closeModal: PropTypes.bool,
  updateModalState: PropTypes.func,
  buttonStyle: PropTypes.shape({}),
  modalStyle: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonTheme: PropTypes.oneOf(["primary", "secondary"]),
  buttonSize: PropTypes.oneOf(["standard"]),
  hideCloseButton: PropTypes.bool,
  nakedButton: PropTypes.bool,
  modalElementStyles: PropTypes.shape({}),
  hideHeader: PropTypes.bool,
  onClose: PropTypes.func,
  closeMenu: PropTypes.func,
};

export default BasicModal;
