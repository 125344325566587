import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import authenticationService from "services/authentication/authentication-service";
import HeadingSmall from "components/core/typography/headings/small/HeadingSmall";
import LoginLayout from "components/layouts/login-layout/LoginLayout";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import ForgottenPasswordForm from "components/forms/auth/ForgottenPassword/ForgottenPasswordForm";
import Back from "components/core/links/Back/Back";
import styles from "./ForgottenPasswordPage.styles.js";

export const ForgottenPasswordPage = () => {
  const history = useHistory();

  const requestPasswordReset = async (email) => {
    await authenticationService.resetPassword(email);
    return history.push(`/forgotten-password/link-sent?email=${email}`);
  };

  return (
    <LoginLayout
      content={
        <Box>
          <Back link="/login" sx={styles.backLink} />
          <Box style={styles.content}>
            <HeadingSmall text="Forget Password" sx={styles.heading} />
            <Paragraph text="Enter the email address associated your account, and we’ll email you a link to reset your password." />
            <ForgottenPasswordForm onComplete={requestPasswordReset} />
          </Box>
        </Box>
      }
    />
  );
};
