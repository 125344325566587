import React from "react";
import PropTypes from "prop-types";
import PopoverModal from "components/core/modal/popover-modal/PopoverModal";
import EditSessionForm from "components/forms/sessions/edit-session/EditSession";
import stylesConfig from "theme/config";
import CloseButton from "../../../../components/core/buttons/Close/Close";

const TableOptions = ({ data, onComplete, close, setAlertType }) => {
  return (
    <>
      {!data.isArchived ? (
        <PopoverModal
          buttonTheme="primary"
          popover
          hideHeader={true}
          hideCloseButton={true}
          buttonCy="edit-content-button"
          buttonText="Edit Session"
          headingText="Edit Session"
          onClose={close}
        >
          <EditSessionForm
            id={data.id}
            onCancel={close}
            onComplete={() => {
              setAlertType("edited");
              onComplete();
            }}
            actions={
              <CloseButton
                style={{
                  marginLeft: "auto",
                  fontSize: "30px",
                  color: stylesConfig.colors.primary,
                  backgroundColor: stylesConfig.colors.white,
                  borderWidth: "0px",
                }}
              />
            }
          />
        </PopoverModal>
      ) : null}
    </>
  );
};

TableOptions.defaultProps = {
  data: undefined,
  onComplete: () => {},
  close: () => {},
};

TableOptions.propTypes = {
  data: PropTypes.shape({}),
  onComplete: PropTypes.func,
  close: PropTypes.func,
};

export default TableOptions;
