import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Img from "components/core/img/Img";
import style from "./BasicFileList.style";
import Button from "components/core/buttons/Primary/Primary";
import filePDF from "../../../../../assets/file_pdf_64.svg";

const BasicFileList = ({ items, styles, onClick }) => {
  return (
    <>
      {items.map((item, index) => (
        <Box key={index} sx={style.container}>
          <div style={{ display: "flex" }}>
            <Img src={filePDF} alt="pdf image" style={style.pdfIcon} />
            {item.alt}
          </div>
          <Button
            sx={styles.removeButton}
            naked
            text="Remove"
            onClick={() => onClick(index)}
          />
        </Box>
      ))}
    </>
  );
};

BasicFileList.defaultProps = {
  items: [],
  styles: {},
  onClick: () => {},
};
BasicFileList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  styles: PropTypes.shape({}),
  onClick: PropTypes.func,
};
export default BasicFileList;
