import React from "react";
import DataTable from "components/data-display/Table/Table";

const AppointmentsTable = ({
  page,
  total,
  rowsPerPage,
  data,
  noResultsMessage,
  tableFormat,
  getDataHandler,
  hidePageing,
  style,
  order,
  orderBy,
  onRequestSort,
}) => {
  return (
    <>
      <>
        <DataTable
          sx={{ ...style }}
          data={data}
          page={page}
          total={total}
          rowsPerPage={rowsPerPage}
          getDataHandler={getDataHandler}
          noDataComponent={
            noResultsMessage || "There are currently no appointments"
          }
          hideOptions
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          hidePageing={hidePageing}
          tableFormat={tableFormat}
        />
      </>
    </>
  );
};

export default AppointmentsTable;
