import React, { useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import { Box, Modal } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InternalLink from "components/core/links/Internal/InternalLink";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import SidebarLink from "components/core/links/SidebarLink/SidebarLink";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Button from "components/core/buttons/Primary/Primary";
import SuperAdminLinks from "components/core/navigation/user-links/super-admin/SuperAdminLinks";
import AccountIcon from "../../../../assets/account_black_24.svg";
import AdminLinks from "components/core/navigation/user-links/admin/AdminLinks";
import CloseIcon from "@mui/icons-material/Close";
import Img from "components/core/img/Img";
import BasicModal from "components/core/modal/basic-modal/Modal";
import { userTypes } from "lib/permissions";
import HeadingBold from "components/core/typography/headings/bold/HeadingBold";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import styles from "./Sidebar.style";
import stylesConfig from "theme/config";
import HeadingMedium from "components/core/typography/headings/medium/HeadingMedium";
import MyAccountForm from "components/forms/my-account/MyAccount";
import ChangeEmail from "components/forms/my-account/change-email/ChangeEmail";
const package_json = require("../../../../../package.json");

const MODAL_DATA = {
  changeEmail: {
    title: "Verification Email Sent",
    description:
      "We have sent a verification email to your new address. It may take a few minutes to appear. Once verified you will be able to use it to log in.",
    cta: "Close",
  },
  updateDetails: {
    title: "Your Details Have Been Updated",
    description: "You have successfull updated your details",
    cta: "Close",
  },
};

function ResponsiveDrawer(props) {
  const globalContext = useContext(GlobalContext);
  const { user } = globalContext;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [modal, updateModal] = React.useState(false);
  const [modalType, updateModalType] = React.useState("changeEmail");
  const [openAlertModal, updateOpenAlertModal] = React.useState(false);
  const [openChangePasswordModal, updateOpenChangePasswordModal] =
    React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onComplete = (modalType = "updateDetails") => {
    updateModalType(modalType);
    updateOpenAlertModal(true);
  };

  const closeModal = () => updateModal(!modal);

  const handleAlertModal = () => {
    if (modalType === "updateDetails") {
      updateOpenAlertModal(false);
    } else if (modalType === "changeEmail") {
    }
  };

  const drawer = (
    <div>
      <Toolbar />
      <Box style={styles.header}>
        <Paragraph sx={styles.appNameText}>NHS Highlands</Paragraph>
        <HeadingMedium text="P.E.A.C.H" />
      </Box>
      <Box style={{ marginBottom: 100 }}>
        {user.type === userTypes.SUPER_ADMIN && <SuperAdminLinks />}
        {user.type === userTypes.ADMIN && <AdminLinks />}
        <div sx={styles.margin} />
        <SidebarLink
          to="/logout"
          text="Logout"
          cy="logout"
          Icon={LogoutOutlinedIcon}
        />
      </Box>

      <Box sx={styles.footer}>
        <Paragraph sx={styles.version}>Version</Paragraph>
        <Paragraph sx={styles.bold}>{package_json.version}</Paragraph>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              backgroundColor: stylesConfig.colors.primary,
              mr: 2,
              display: { sm: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <BasicModal
              nakedButton
              popover
              buttonSx={styles.myAccount}
              hideCloseButton={false}
              buttonCy="bt-my-account"
              buttonText="My Account"
              buttonIcon={<Img alt="account image" src={AccountIcon} />}
              headingText="My Account"
              closeModal={modal}
            >
              <MyAccountForm
                closeModal={closeModal}
                onComplete={onComplete}
                openChangePasswordModal={() =>
                  updateOpenChangePasswordModal(!openChangePasswordModal)
                }
              />
            </BasicModal>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={styles.drawerContainer}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={styles.drawerMobile}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          PaperProps={{ elevation: 4 }}
          sx={styles.drawerDesktop}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Modal
        open={openAlertModal}
        onClose={() => updateOpenAlertModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal} data-cy="modal-content">
          <Box sx={styles.modalHeader}>
            <CheckCircleOutlineOutlinedIcon style={styles.modalCheck} />
            <HeadingBold text={MODAL_DATA[modalType].title} />
          </Box>
          <Box style={styles.modalContainer}>
            <Paragraph>{MODAL_DATA[modalType].description}</Paragraph>
            <Box sx={styles.modalActions}>
              {modalType === "changeEmail" ? (
                <InternalLink
                  to="/logout"
                  text={MODAL_DATA[modalType].cta}
                  theme="primaryButton"
                  style={styles.editButtonStyle}
                />
              ) : (
                <Button
                  theme="primary"
                  text={MODAL_DATA[modalType].cta}
                  size="standard-bold"
                  onClick={handleAlertModal}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openChangePasswordModal}
        onClose={() => updateOpenChangePasswordModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal} data-cy="modal-content">
          <Box sx={styles.modalChangePasswordHeader}>
            <HeadingBold text="Change Email Address" />
            <CloseIcon
              onClick={() =>
                updateOpenChangePasswordModal(!openChangePasswordModal)
              }
              style={styles.closeButton}
            />
          </Box>
          <div style={styles.divider} />
          <Box
            style={{
              ...styles.modalContainer,
              padding: stylesConfig.spacing.standard,
            }}
          >
            <Box>
              <Paragraph>
                Enter your new email address and click “Verify”. This will send
                you an email verification. Once verified you will be able to use
                your new email address to log in.
              </Paragraph>
            </Box>
            <ChangeEmail
              onComplete={onComplete}
              closeModal={() =>
                updateOpenChangePasswordModal(!openChangePasswordModal)
              }
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
