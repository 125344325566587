import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import LoggedIn from "components/layouts/logged-in/LoggedIn";
import PatientsTable from "components/patients/PatientsTable/PatientsTable";
import Options from "components/patients/PatientsTable/Options/TableOptions";
import AddPatientForm from "components/forms/patient/AddPatient/AddPatientForm";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import userService from "services/user/user-service";
import sessionsService from "services/sessions/sessions-service";
import Loader from "components/core/loaders/circular/Circular";
import styles from "./patient-dataBase.style";
import stylesConfig from "theme/config";

const filterButtons = [
  {
    id: "all",
    name: "All",
    active: true,
  },
  {
    id: "intervention",
    name: "Intervention",
    active: false,
  },
  {
    id: "monitoring",
    name: "Monitoring",
    active: false,
  },
];

const tableFormat = [
  { id: "firstName", label: "Patient Name", allowSort: true },
  { id: "chiNumber", label: "CHI Number", allowSort: false },
  { id: "guardian", label: "Guardian", allowSort: false },
  { id: "contactNumber", label: "Contact Number", allowSort: false },
  { id: "contactEmail", label: "Contact Email", allowSort: false },
  { id: "stage", label: "Stage", allowSort: false },
  { id: "attendance%", label: "Attendance %", allowSort: false },
];

const ROWS_PER_PAGE = 10;

const PatientDatabase = () => {
  const [patients, updatePatients] = useState([]);
  const [loading, updateLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [isModalClosed, updateCloseModal] = useState(false);
  const [filter, updateFilter] = useState({
    stage: "all",
    firstName: "",
    state: "",
    buttons: filterButtons,
  });
  const [noResultsMessage, setNoResultsMessage] = useState(
    "There are currently no patients."
  );
  // Pagination state
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  // Sorting
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");

  const closeModal = () => updateCloseModal(!isModalClosed);

  const getPatients = useCallback(
    async (nextPage, sOrder = order, sOrderBy = orderBy, search = filter) => {
      const pageToSearch = nextPage || 0;
      const stageToSearch = search.stage === "all" ? null : search.stage;

      const sSearch = {
        ...search,
        name: search.firstName,
        stage: stageToSearch,
      };

      const response = await userService.getUsers(
        pageToSearch,
        rowsPerPage,
        sSearch,
        sOrder,
        sOrderBy
      );

      updateLoading(false);

      if (!response || response.message) {
        setNoResultsMessage("No Results from API");
        return;
      }

      setPage(pageToSearch);
      setTotal(response.total);
      setRowsPerPage(ROWS_PER_PAGE);

      const sessionsResponse = await sessionsService.getAllSessions();
      if (sessionsResponse) {
        setSessions(sessionsResponse);
      }

      const patientsArray = response.results.map((patient) => {
        let stageLabel = patient.stage;
        if (patient.stage && sessionsResponse) {
          stageLabel =
            sessionsResponse.find(
              (session) => session.value.toString() === patient.stage
            ).label || patient.stage;
        }
        let attendance = patient.attendance || 0;
        if (patient.attendance && patient.attendance > 100) {
          attendance = 100;
        }
        return {
          id: patient.id,
          firstName: patient.firstName,
          lastName: patient.lastName,
          fields: [
            `${patient.firstName} ${patient.lastName}`,
            patient.chiNumber,
            patient.guardianName,
            patient.contactNumber,
            patient.email,
            stageLabel,
            attendance,
          ],
        };
      });

      updatePatients(patientsArray);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const get = async () => await getPatients(page);
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);

    await getPatients(page, newOrder, property);
  };

  const handleFilterButtonSelection = async (id) => {
    const clonedState = [...filter.buttons];
    let newState = null;

    const updatedButtons = clonedState.map((item) => {
      const isActive = item.id === id;

      if (isActive) {
        if (item.id === "intervention") {
          newState = '"1","2","3","4","5","6","7","8","9"';
        } else if (item.id === "all") {
          newState = "";
        } else if (item.id === "monitoring") {
          newState = '"Maintenance-1", "Maintenance-2"';
        }
      }

      return {
        ...item,
        active: isActive,
      };
    });

    const newFilter = {
      ...filter,
      state: newState,
      buttons: updatedButtons,
      key: Math.random(),
    };

    updateFilter(newFilter);

    const newOrder = "asc";
    const newOrderBy = "firstName";

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);

    getPatients(0, newOrder, newOrderBy, newFilter);
  };

  const handleAddPatient = () => {
    const newOrder = "asc";
    const newOrderBy = "firstName";

    updateFilter({
      stage: "all",
      firstName: "",
      key: "",
      buttons: filterButtons,
    });

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    getPatients(0, newOrder, newOrderBy);
  };

  const handleFilterChange = (e) => {
    const newOrder = "asc";
    const newOrderBy = "firstName";

    const newFilter = {
      ...filter,
      key: Math.random(),
      [e.target.id]: e.target.value,
    };

    updateFilter(newFilter);

    setPage(0);
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    getPatients(0, newOrder, newOrderBy, newFilter);
  };

  let loadingStyles = {};
  if (loading) {
    loadingStyles = styles.loading;
  }

  return (
    <>
      <LoggedIn
        smallHeading="Patient Database"
        buttonModal={{
          title: "Add Patient",
          modalContent: (
            <AddPatientForm
              clodeModal={closeModal}
              onComplete={handleAddPatient}
            />
          ),
          isModalClosed: isModalClosed,
        }}
      >
        <Box sx={{ ...styles.panel, ...loadingStyles }}>
          {loading ? (
            <Loader />
          ) : (
            <Box>
              <Box sx={{ flexGrow: 1 }}>
                <Box style={styles.tableFilter}>
                  <div style={styles.container}>
                    {filter.buttons.map((item) => {
                      let isFilterSelectedStyles = {};
                      if (item.active) {
                        isFilterSelectedStyles = styles.bold;
                      }

                      return (
                        <div id={item.id} style={styles.filterButtonContainer}>
                          <PrimaryButton
                            sx={{
                              ...styles.filterButton,
                              ...isFilterSelectedStyles,
                            }}
                            text={item.name}
                            naked
                            onClick={() => handleFilterButtonSelection(item.id)}
                          />
                          {item.active && (
                            <div style={styles.filterButtonUnderLine} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div style={styles.filterSearchContainer}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        id="firstName"
                        label="Search"
                        variant="standard"
                        value={filter.firstName}
                        placeholder="start typing"
                        focused
                        onChange={handleFilterChange}
                        sx={{
                          minWidth: 256,
                          paddingRight: stylesConfig.spacing.veryLarge,
                        }}
                      />

                      <Box>
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            sx={styles.filterInputLable}
                          >
                            Stage
                          </InputLabel>
                          <NativeSelect
                            sx={styles.fitlerDropbox}
                            value={filter.stage}
                            onChange={handleFilterChange}
                            inputProps={{
                              name: "stage",
                              id: "stage",
                            }}
                          >
                            <option value="all">All</option>
                            {sessions.map((item) => (
                              <option value={item.value}>
                                {item.label || item.value}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    </div>

                    <PrimaryButton
                      text="Clear Filter"
                      theme="outline"
                      sx={styles.filterButton}
                      onClick={handleAddPatient}
                    />
                  </div>
                </Box>
                <PatientsTable
                  page={page}
                  total={total}
                  rowsPerPage={rowsPerPage}
                  data={patients}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  noResultsMessage={noResultsMessage}
                  tableFormat={tableFormat}
                  getDataHandler={getPatients}
                  optionActions={(data) => (
                    <Options data={data} onComplete={() => getPatients(page)} />
                  )}
                />
              </Box>
            </Box>
          )}
        </Box>
      </LoggedIn>
    </>
  );
};

export default PatientDatabase;
