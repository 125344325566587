import stylesConfig from "theme/config";

const styles = {
  img: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  container: {
    // float: "left",
    position: "relative",
    width: "50%",
    paddingBottom: "50%" /* = width for a 1:1 aspect ratio */,
    margin: "0.5%",
  },
  removeContainer: {
    position: "absolute",
    right: "-6px",
    top: "-6px",
  },
  remove: {
    margin: 0,
    ".MuiButtonBase-root": {
      background: stylesConfig.colors.white,
      "&:hover": {
        background: stylesConfig.colors.white,
      },
    },
  },
};

export default styles;
