import stylesConfig from "theme/config";

const styles = {
  container: {
    position: "relative",
    "&:hover": {
      cursor: "pointer",
      background: stylesConfig.colors.accent,
      color: stylesConfig.colors.primary,
      "&::after": {
        background: stylesConfig.colors.white,
        ...stylesConfig.navigationActive,
      },
      fontWeight: "600",
    },
  },
  link: {
    width: "100%",
    display: "flex",
    color: stylesConfig.colors.nero,
    font: stylesConfig.typography.navlink.font,
    textDecoration: "none",
    paddingLeft: "48px",
    paddingTop: "17px",
    paddingBottom: "17px",
    alignItems: "center",
  },
  activeLink: {
    color: stylesConfig.colors.primary,
    fontWeight: "600",
  },
  icon: {
    marginRight: stylesConfig.spacing.standard,
    minWidth: "24px",
    display: "flex",
  },
  active: {
    backgroundColor: stylesConfig.colors.accent,
    "&::after": {
      ...stylesConfig.navigationActive,
    },
  },
  indicator: {
    backgroundColor: stylesConfig.colors.primary,
    width: "5px",
    height: "100%",
    position: "absolute",
    right: 0,
  },
};

export default styles;
