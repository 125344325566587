import stylesConfig from "theme/config";

const styles = {
  container: {
    paddingTop: stylesConfig.spacing.standard,
  },
  heading: {
    marginBottom: stylesConfig.spacing.standard,
  },
  retry: {
    width: "100%",
    marginTop: stylesConfig.spacing.standard,
  },
  icon: {
    fontSize: "45px",
    color: stylesConfig.colors.red,
    marginBottom: stylesConfig.spacing.small,
  },
  box: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4rem",
  },
  iconGreen: {
    fontSize: "45px",
    color: stylesConfig.colors.success,
    marginBottom: stylesConfig.spacing.small,
  },
};

export default styles;
