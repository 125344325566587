import stylesConfig from "theme/config";

const styles = {
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: { xs: "550px" },
  },
  heading: {
    marginBottom: stylesConfig.spacing.standard,
  },
  content: {
    paddingTop: "8rem",
  },
  editBox: {
    display: "flex",
    paddingTop: stylesConfig.spacing.standard,
  },
  sendAngainBox: {
    display: "flex",
  },
  sendAgain: {
    color: "black",
    margin: 0,
    padding: 0,
    height: 27,
    marginLeft: stylesConfig.spacing.verySmall,
    textDecoration: "underline",
    fontWeight: 600,
    backgroundColor: "transparent",
  },
  margin: {
    paddingTop: stylesConfig.spacing.standard,
  },
  check: {
    color: stylesConfig.colors.success,
    fontSize: "48px",
    marginLeft: "-6px",
  },
  editButtonStyle: {
    ...stylesConfig.buttons.primary,
    width: "100%",
    lineHeight: "30px",
    marginTop: stylesConfig.spacing.veryLarge,
  },
  box: {
    paddingTop: stylesConfig.spacing.veryVeryLarge,
  },
  bold: {
    fontWeight: "600",
  },
};

export default styles;
