import React from "react";
import styles from "./PatientsBiometricsTable.styles";
import DataTable from "components/data-display/Table/Table";

const PatientBiometricsTable = ({ data, noResultsMessage, tableFormat }) => {
  return (
    <>
      <DataTable
        sx={styles.table}
        data={data}
        hidePageing={true}
        hideOptions={true}
        noDataComponent={
          noResultsMessage || "There are currently no data to show"
        }
        tableFormat={tableFormat}
      />
    </>
  );
};

export default PatientBiometricsTable;
