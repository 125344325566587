import stylesConfig from "theme/config";
const styles = {
  button: {
    height: "36px",
    padding: "0 0 0 16px",
    justifyContent: "flex-start",
    border: "0",
    font: stylesConfig.typography.buttons.popoverOption,
    fontWeight: 400,
    backgroundColor: stylesConfig.colors.white,
    color: stylesConfig.colors.nero,
    "&:hover": {
      height: "36px",
      padding: "0 0 0 16px",
      border: "0",
    },
  },
};

export default styles;
