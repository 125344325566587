import stylesConfig from "theme/config";

const config = {
  dropzone: {
    border: "2px dashed #929292",
    borderRadius: "6px",
    height: "136px",
    width: "340px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: stylesConfig.spacing.veryLarge,
    background: stylesConfig.colors.white,
  },
  dropLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cloudIcon: {
    color: stylesConfig.colors.reallyDarkGrey,
    width: "49x",
    height: "auto",
    marginBottom: stylesConfig.spacing.standard,
  },
  heading: {
    color: stylesConfig.colors.reallyDarkGrey,
    fontWeight: "700",
    marginBottom: stylesConfig.spacing.small,
  },
  button: {
    padding: "0px",
    margin: "0px",
    marginLeft: "1px",
    height: "25px",
    minWidth: "16px",
    font: "normal normal normal 16px/25px Poppins",
    color: stylesConfig.colors.primary,
    fontWeight: "unset",
    background: "transparent",
    borderColor: "transparent",
    textDecorationLine: "underline",
    "&:hover": {
      background: "transparent",
      border: `0px solid transparent`,
      color: stylesConfig.colors.primary,
    },
  },
  buttonIcon: {
    marginRight: stylesConfig.spacing.small,
  },
  p: {
    font: "normal normal normal 16px/25px Poppins",
    color: "#929292",
  },
  label: {
    font: "normal normal bold 16px/19px Poppins",
    color: stylesConfig.colors.black,
    margin: 0,
    padding: 0,
  },
  uploadFileButton: {
    color: stylesConfig.colors.black,
    fontWeight: 300,
  },
};

export default config;
