import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import InternalLink from "components/core/links/Internal/InternalLink";
import styles from "./SidebarLink.style";

const SidebarLink = ({ to, text, Icon, IconActive, cy }) => {
  const location = useLocation();
  let active = {};
  let activeLink = {};
  if (location.pathname === to) {
    active = styles.active;
    activeLink = styles.activeLink;
  }

  let SideBarIcon = Icon;

  if (IconActive && location.pathname === to) {
    SideBarIcon = IconActive;
  }

  return (
    <Box sx={{ ...styles.container, ...active }}>
      <InternalLink to={to} style={{ ...styles.link, ...activeLink }} cy={cy}>
        <Box sx={styles.icon}>{SideBarIcon && <SideBarIcon />}</Box>
        {text}
        {location.pathname === to ? <Box sx={styles.indicator} /> : null}
      </InternalLink>
    </Box>
  );
};

SidebarLink.defaultProps = {
  Icon: undefined,
};

SidebarLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  cy: PropTypes.string,
};

export default SidebarLink;
