import React, { useState, useReducer, useContext } from "react";
import { Grid, Box } from "@mui/material";
import { GlobalContext } from "context/GlobalContext";
import reducers from "reducers/form-errors-reducer";
import InputField from "components/core/form/Input/Input";
import Button from "components/core/buttons/Primary/Primary";
import ErrorHelperText from "components/core/form/ErrorHelperText/ErrorHelperText";
import HTTPError from "lib/errors/http-error";
import userService from "services/user/user-service";
import errorHandler from "./ChangeEmail.error.handler";
import styles from "./ChangeEmail.styles";

const intialErrorState = {
  email: null,
  general: null,
};

const ChangeEmail = ({ closeModal, onComplete }) => {
  const { user, updateUser } = useContext(GlobalContext);
  const [isSavingEmail, updateIsSavingEmail] = useState(false);
  const [verifyDisabled, updateVerifyDisabled] = useState(true);
  const [form, updateForm] = useState({
    date: "",
    time: "",
    sessionNumber: 0,
    attendees: [],
  });
  const [errorState, errorDispatch] = useReducer(
    reducers.formErrorsReducer,
    intialErrorState
  );

  const characterLimit = 246;

  const onSubmit = async (e) => {
    e.preventDefault();

    const { email } = form;

    try {
      updateIsSavingEmail(true);
      await userService.verifyEmail(user.id, email);
      updateIsSavingEmail(false);

      updateUser({
        ...user,
        email,
      });

      closeModal();
      onComplete("changeEmail");
    } catch (err) {
      updateIsSavingEmail(false);
      if (err instanceof HTTPError) {
        errorHandler(err, errorDispatch);
      } else {
        throw err;
      }
    }
  };

  const isFormValid = (form) => {
    const { email } = form;

    if (email) {
      updateVerifyDisabled(false);
    } else {
      updateVerifyDisabled(true);
    }
  };

  const handleOnFormChange = (e) => {
    console.log(e.target.id);
    let newForm = {
      ...form,
      [e.target.name || e.target.id]: e.target.value,
    };

    isFormValid(newForm);
    updateForm(newForm);
  };

  return (
    <>
      <form onSubmit={onSubmit} style={styles.formFooter}>
        <Grid container>
          <Grid item xs={12} style={styles.gridRow}>
            <InputField
              id="email"
              cy="email-field"
              label="Enter New Email Address"
              type="email"
              theme="inputWhite"
              required
              autoComplete="email"
              placeholder="Enter New Email Address"
              error={errorState.email ? true : false}
              onChange={(e) => handleOnFormChange(e)}
              helperText={errorState.email}
              inputProps={{
                inputProps: {
                  maxLength: characterLimit,
                  autoComplete: "email",
                },
              }}
            />
          </Grid>
        </Grid>

        {errorState.general && <ErrorHelperText text={errorState.general} />}

        <Box style={styles.footer}>
          <Button
            type="submit"
            text="Verify"
            variant="contained"
            style={styles.button}
            loading={isSavingEmail}
            disabled={verifyDisabled}
          />
        </Box>
      </form>
    </>
  );
};

export default ChangeEmail;
