import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import styles from "./Dropzone.style";
import Img from "components/core/img/Img";
import Paragraph from "components/core/typography/paragraph/Paragraph";
import PrimaryButton from "components/core/buttons/Primary/Primary";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Cloud from "../../../assets/upload.svg";

const DropLabel = () => (
  <Box sx={styles.dropLabel}>
    <Img src={Cloud} alt="cloud" />
    <div style={{ display: "flex", marginTop: "0.5rem" }}>
      <Paragraph sx={styles.p}>Drag and Drop or</Paragraph>
      <PrimaryButton theme="secondary" sx={styles.button}>
        browse
      </PrimaryButton>
    </div>
  </Box>
);

const DragAndDrop = ({ onDrop, fileNumber, dropLabel, accept, noClick }) => {
  const dropHandler = (acceptedFiles, errors) => {
    onDrop(acceptedFiles, errors);
  };

  return (
    <>
      <Dropzone
        onDrop={dropHandler}
        accept={accept}
        noClick={noClick}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <Box {...getRootProps()} sx={styles.dropzone}>
              {fileNumber > 0 ? (
                <Box
                  sx={{
                    marginBottom: 2,
                  }}
                >
                  <PrimaryButton theme="secondary" sx={styles.button}>
                    <FileUploadOutlinedIcon sx={styles.buttonIcon} /> Browse
                  </PrimaryButton>
                </Box>
              ) : null}
              <input {...getInputProps()} />
              {dropLabel}
            </Box>
          </section>
        )}
      </Dropzone>
    </>
  );
};

DragAndDrop.defaultProps = {
  onDrop: () => {},
  dropLabel: <DropLabel />,
  noClick: false,
};

DragAndDrop.propTypes = {
  accept: PropTypes.string,
  onDrop: PropTypes.func,
  dropLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noClick: PropTypes.bool,
};
export default DragAndDrop;
